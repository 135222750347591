import React, { Component } from "react";
import { UncontrolledAlert, Button } from "reactstrap";

class Login extends Component {
  state = {
    username: null,
    password: null,
    email: null,
    alerts: [],
    recoverUser: false,
    recoverPassword: false,
  };

  setRecoverPassword = (e) => {
    e.preventDefault();
    this.setState({ recoverPassword: true });
  };

  setRecoverUser = (e) => {
    e.preventDefault();
    this.setState({ recoverUser: true });
  };

  addAlert = (newAlert) => {
    this.setState((state) => {
      const alerts = [...state.alerts, newAlert];
      return { alerts };
    });
  };

  clearAlerts = () => {
    this.setState({ alerts: [] });
  };

  recoverPassword = (e) => {
    this.clearAlerts();
    e.preventDefault();
    fetch("/user/forgotPass", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return this.addAlert({
            id: this.state.alerts.length,
            color: "danger",
            msg: data.error.message,
          });
        }
        this.addAlert({
          id: this.state.alerts.length,
          color: "success",
          msg: data.msg,
        });
        const myinterval = setInterval(() => {
          this.props.exitModal();
          clearInterval(myinterval);
        }, 1000);
        return;
      })
      .catch((err) => {
        console.error(err);
        this.addAlert({
          id: this.state.alerts.length,
          color: "danger",
          msg: "Something went wrong, pleasy try again",
        });
        return;
      });
  };

  recoverUser = (e) => {
    this.clearAlerts();
    e.preventDefault();
    fetch("/user/recoverUsername", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          console.error(data.error);
          return this.addAlert({
            id: this.state.alerts.length,
            color: "danger",
            msg: data.error.message,
          });
        }
        this.setState({ username: data.username, recoverUser: false });
      })
      .catch((err) => {
        console.error(err);
        this.addAlert({
          id: this.state.alerts.length,
          color: "danger",
          msg: "Something went wrong",
        });
      });
  };

  handleLogin = (e) => {
    e.preventDefault();
    this.clearAlerts();
    fetch("/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          console.error(data.error);
          return this.addAlert({
            id: this.state.alerts.length,
            color: "danger",
            msg: data.error.message,
          });
        }
        this.props.setuser(data.user);
        this.props.exitModal();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    if (this.state.recoverPassword) {
      return (
        <div className="rounded bg-white px-3 py-3">
          <form onSubmit={this.recoverPassword}>
            <img
              className="w-75 rounded-circle d-block mx-auto mb-3"
              alt="Beer forgot"
              src={require("../../images/Inlog_vergeten.png")}
            ></img>
            <h5 className="text-center text-secondary">Recover password</h5>
            <div className="form-group">
              <label className="col-form-label" htmlFor="email">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                onChange={(e) => this.setState({ email: e.target.value })}
                placeholder="email"
              ></input>
            </div>
            {this.state.alerts.map((alert) => {
              return (
                <UncontrolledAlert key={alert.id} color={alert.color}>
                  {alert.msg}
                </UncontrolledAlert>
              );
            })}
            <div className="text-right">
              <Button
                className="mr-3"
                color="secondary"
                onClick={this.props.exitModal}
              >
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      );
    } else if (this.state.recoverUser) {
      return (
        <div className="rounded bg-white px-3 py-3">
          <form onSubmit={this.recoverUser}>
            <img
              className="w-75 rounded-circle d-block mx-auto mb-3"
              alt="Beer forgot"
              src={require("../../images/Inlog_vergeten.png")}
            ></img>
            <h5 className="text-center text-secondary">Recover username</h5>
            <div className="form-group">
              <label className="col-form-label" htmlFor="email">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                onChange={(e) => this.setState({ email: e.target.value })}
                placeholder="email"
              ></input>
            </div>
            {this.state.alerts.map((alert) => {
              return (
                <UncontrolledAlert key={alert.id} color={alert.color}>
                  {alert.msg}
                </UncontrolledAlert>
              );
            })}
            <div className="text-right">
              <Button
                className="mr-3"
                color="secondary"
                onClick={this.props.exitModal}
              >
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      );
    } else {
      return (
        <div className="rounded bg-white px-3 py-3">
          <form onSubmit={this.handleLogin}>
            <img
              className="w-75 rounded-circle d-block mx-auto mb-3"
              alt="Beer bouncer"
              src={require("../../images/Inlog_inloggen.png")}
            ></img>
            <div className="text-center">
              <a
                href="/#"
                className="text-primary"
                onClick={this.props.toggler}
              >
                Create an account
              </a>
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="username">
                Username
              </label>
              <input
                className="form-control"
                type="text"
                id="username"
                value={this.state.username}
                onChange={(e) => this.setState({ username: e.target.value })}
                placeholder="username"
              ></input>
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="password">
                Password
              </label>
              <input
                className="form-control"
                type="password"
                id="password"
                onChange={(e) => this.setState({ password: e.target.value })}
                placeholder="password"
              ></input>
            </div>
            <div className="text-secondary mb-3">
              Forgot{" "}
              <a href="/#" onClick={this.setRecoverUser}>
                username
              </a>{" "}
              or{" "}
              <a href="/#" onClick={this.setRecoverPassword}>
                password
              </a>
            </div>
            {this.state.alerts.map((alert) => {
              return (
                <UncontrolledAlert key={alert.id} color={alert.color}>
                  {alert.msg}
                </UncontrolledAlert>
              );
            })}
            <div className="text-right">
              <Button
                className="mr-3"
                color="secondary"
                onClick={this.props.exitModal}
              >
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Login
              </Button>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default Login;
