import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { selfScore, opponentScore, opponentName } from './ladterHelpFunctions'

class LadterTop extends Component {
    state = {
        pendingMatch: null,
        msg: null,
    }

    acceptResult = (e) => {
        e.preventDefault();
        this.submitPending(true);
    }

    rejectResult = (e) => {
        e.preventDefault();
        this.submitPending(false);
    }

    submitPending = (response) => {
        fetch('/ladter/accept', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                matchID: this.state.pendingMatch._id,
                response: response,
            })
        })
        .then(res => res.json())
        .then(data => {
            this.setState({
                msg: data
            })
            if (data.type === "success") {
                this.setState({ pendingMatch: null });
                this.props.update()
                window.setTimeout(() => { this.setState({ msg: null }) }, 1000)
            }
        })
        .catch(err => {
            console.error(err)
            this.setState({
                msg: {
                    text: err.statusText,
                    type: "danger"
                }
            })
        })
    }

    fetchPending = () => {
        fetch('/ladter/pending', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                if (res.status === 204) {
                    console.log(`no pending match`)
                    return // no pending match
                } else if (res.status !== 200) {
                    throw new Error(res)
                }
                return res.json()
            })
            .then(data => this.setState({ pendingMatch: data }))
            .catch(err => {
                console.error(err)
                this.setState({
                    msg: {
                        text: err.message,
                        type: "danger"
                    }
                })
            })
    }

    componentDidMount() {
        if (this.props.user) {
            this.fetchPending();
        }
    }

    componentDidUpdate(prev) {
        if (prev.user !== this.props.user && this.props.user) {
            this.fetchPending()
        }
    }

    render() {
        if (this.props.user) {
            return (
                <div className="row d-flex justify-content-center">
                    <div className="col-9">
                        <div className="float-right"><Link to="/Ladter/info"><span className="pos-abs fa fa-info fa-5x px-3 bg-custom-blue rounded-bottom text-custom-gold text-decoration-none stretched-link" aria-label="Info" /></Link></div>
                    </div>
                    <div className="col-8">
                        {this.state.pendingMatch ?
                            <div className="w-100 w-sm-75 bg-custom-gold-light border-dashed-notop">
                                <h6 className="text-custom-blue pt-2 px-1">You have a pending result</h6>
                                <div className="d-flex w-100 justify-content-between text-secondary">
                                    <span className="col-5 text-custom-blue text-truncate">{opponentName(this.state.pendingMatch, this.props.user._id)}</span>
                                    <span className="col-5 text-custom-blue text-truncate">You</span>
                                </div>
                                <div className="d-flex w-100 justify-content-center pb-2">
                                    <span className="col-5 px-0 text-custom-blue">{opponentScore(this.state.pendingMatch, this.props.user._id)}</span>
                                    <span className="col-2 px-0 text-custom-blue">-</span>
                                    <span className="col-5 px-0 text-custom-blue">{selfScore(this.state.pendingMatch, this.props.user._id)}</span>
                                </div>
                                <div className="d-flex justify-content-around justify-content-sm-end pb-2 px-3">
                                    <span className="btn btn-danger mr-sm-3" onClick={this.rejectResult}>Reject</span>
                                    <span className="btn btn-success" onClick={this.acceptResult}>Accept</span>
                                </div>
                            </div> : <span className="hidden" />}
                        {this.state.msg ?
                            <div className={`alert alert-${this.state.msg.type}`}>
                                {this.state.msg.text}
                            </div> : <span className="hidden" />}
                    </div>
                </div>
            )
        } else {
            return <div className="row d-flex justify-content-center">
                <div className="col-9">
                    <div className="float-right"><Link to="/Ladter/info"><span className="pos-abs fa fa-info fa-5x px-3 bg-custom-blue rounded-bottom text-custom-gold text-decoration-none stretched-link" aria-label="Info" /></Link></div>
                </div>
                <div className="col-8">
                    <div className={`alert alert-info`}>
                        If you want to participate in the ladter competition, read the info and join on the bottom tier!
                        </div>
                </div>
            </div>
        }
    }
};


export default LadterTop;