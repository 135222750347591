import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchUser from "../User/SearchUser";

function formatDate(d) {
  return (
    d.getUTCFullYear() +
    "-" +
    ("0" + (d.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getUTCDate()).slice(-2)
  );
}

class Result extends Component {
  state = {
    item: { _id: null, points: 0, date: formatDate(new Date()) },
  };

  componentDidMount() {
    if (!this.props.new_result) {
      var d = new Date(this.props.result.date);
      var d_string = formatDate(d);
      this.setState({
        item: {
          _id: this.props.result._id,
          joker: this.props.result.joker,
          points: this.props.result.points,
          date: d_string,
        },
      });
    }
  }

  handleClick = (e) => {
    e.persist();
    this.setState((prevState) => ({
      item: { ...prevState.item, [e.target.name]: e.target.checked },
    }));
  };

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      item: { ...prevState.item, [e.target.name]: e.target.value },
    }));
  };

  updateScore = (e) => {
    e.preventDefault();
    fetch("/adtvent/score", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...this.state, playerID: this.props.playerID }),
    })
      .then((res) => {
        if (res.status === 204) {
          // this.setState({
          //   item: { _id: null, points: 0, date: formatDate(new Date()) },
          // });
          this.props.update();
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteScore = (e) => {
    e.preventDefault();
    fetch("/adtvent/score", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...this.state, playerID: this.props.playerID }),
    })
      .then((res) => {
        if (res.status === 204) {
          this.props.update();
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <form className="form-group row px-3 justify-content-end">
        <div className="col-12 col-sm-3">
          <span className="form-text px-2">
            {this.props.new_result ? "Add new result" : ""}
          </span>
        </div>
        <div className="px-0 col-12 col-sm-5">
          <label className="sr-only">Update Date</label>
          <input
            type="date"
            name="date"
            className="form-control"
            value={this.state.item.date}
            onChange={this.handleChange}
          ></input>
        </div>
        <div className="px-0 col-12 col-sm-2">
          <div className="input-group">
            <label className="sr-only">Update Score</label>
            <input
              type="number"
              name="points"
              className="form-control"
              value={this.state.item.points}
              onChange={this.handleChange}
            ></input>
            <label className="sr-only">Update Joker</label>
            <input
              type="checkbox"
              name="joker"
              className="form-control"
              checked={this.state.item.joker}
              onClick={this.handleClick}
            ></input>
          </div>
        </div>
        <div className="px-0 col-12 col-sm-2">
          {this.props.new_result ? (
            <div className="input-group-append justify-content-end">
              <button
                className="input-group-text btn btn-success"
                onClick={this.updateScore}
              >
                <span aria-hidden="true">+</span>
              </button>
            </div>
          ) : (
            <div className="input-group-append justify-content-end">
              <button
                className="input-group-text btn btn-danger"
                onClick={this.deleteScore}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <button
                className="input-group-text btn btn-primary"
                onClick={this.updateScore}
              >
                <span className="fa fa-level-up" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

class Player extends Component {
  state = {
    updateUsername: { name: "" },
    userEdit: false,
    jokers: 0,
  };

  toggleUserEdit = (e) => {
    e.preventDefault();
    this.setState({ userEdit: !this.state.userEdit });
  };

  updateState = (obj) => {
    this.setState({ updateUsername: obj });
  };

  updateUsername = (e) => {
    e.preventDefault();
    fetch("/adtvent/playername", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...this.state.updateUsername,
        playerID: this.props.player._id,
      }),
    })
      .then((res) => {
        if (res.status === 204) {
          this.props.update();
          this.setState({ userEdit: false });
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getJokers = () => {
    fetch("/adtvent/jokers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id: this.props.player._id }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ jokers: data.jokers });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  componentDidMount() {
    this.getJokers();
  }

  render() {
    return (
      <div className="py-1 border-bottom">
        <form>
          {this.state.userEdit ? (
            <div className="input-group justify-content-center">
              <label className="sr-only" htmlFor="inlineAddPlayerForm">
                Update Player
              </label>
              <SearchUser
                className="rounded-left"
                id="updatePlayer"
                placeholder="Player name"
                setState={this.updateState}
                submit={this.updateUsername}
                state={this.state.updateUsername}
              />

              <div className="input-group-append">
                <button
                  className="input-group-text btn btn-secondary"
                  onClick={this.toggleUserEdit}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="input-group-append">
                <button
                  className="input-group-text btn btn-outline-success"
                  onClick={this.updateUsername}
                >
                  Set
                </button>
              </div>
            </div>
          ) : (
            <div className="col-12 col-sm-10 pl-0 d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <h2 className="float-left text-truncate">
                  {this.props.player.name}
                </h2>
                <span
                  className="float-right pl-1 fa fa-pencil text-secondary"
                  onClick={this.toggleUserEdit}
                />
              </div>
              <span className="d-inline-block">{`Jokers: ${this.state.jokers}`}</span>
            </div>
          )}
        </form>
        <div>
          {this.props.player.results
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((result) => (
              <Result
                key={result._id}
                result={result}
                playerID={this.props.player._id}
                update={this.props.update}
              />
            ))}
          <Result
            new_result={true}
            playerID={this.props.player._id}
            update={this.props.update}
          />
        </div>
      </div>
    );
  }
}

class NewPlayer extends Component {
  state = {
    inputField: {},
  };

  updateState = (obj) => {
    this.setState({ inputField: obj });
  };

  submitPlayer = (e) => {
    e.preventDefault();
    fetch("/adtvent/newplayer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...this.state.inputField }),
    })
      .then((res) => {
        if (res.status === 204) {
          this.props.update();
          this.setState({ inputField: {} });
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <form className="form-inline col-11 col-sm-6 px-0 mb-2 py-1">
        <div className="input-group w-100 justify-content-center">
          <label className="sr-only" htmlFor="inlineAddPlayerForm">
            New Player
          </label>
          <SearchUser
            className="rounded-left"
            id="newPlayer"
            placeholder="New Player"
            setState={this.updateState}
            submit={this.submitPlayer}
            state={this.state.inputField}
          />
          <div className="input-group-append">
            <button
              className="input-group-text btn btn-outline-success"
              onClick={this.submitPlayer}
            >
              Add
            </button>
          </div>
        </div>
        {this.state.errorMsg ? (
          <div className="px-2 text-danger">{this.state.errorMsg}</div>
        ) : (
          <span aria-hidden="true" />
        )}
      </form>
    );
  }
}

class Adtmin extends Component {
  state = {
    data: null,
    loading: true,
  };

  fetchScores = () => {
    fetch("/adtvent/scores", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        data.forEach((element) => {
          element.total = element.results.reduce(
            (acc, cur) => cur.points + acc,
            0
          );
        });
        this.setState({
          data: data,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.fetchScores();
  }

  render() {
    if (this.state.loading) {
      return <div>loading</div>;
    }
    if (!this.state.data) {
      return (
        <div className="">
          <NewPlayer update={this.fetchScores} />
        </div>
      );
    }
    return (
      <div className="">
        <NewPlayer update={this.fetchScores} />
        {this.state.data
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          .map((player) => (
            <Player
              player={player}
              key={player._id}
              update={this.fetchScores}
            />
          ))}
        <Link to="/adtvent">Back</Link>
      </div>
    );
  }
}

export default Adtmin;
