import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
import { win, selfScore, opponentScore, opponentName } from './ladterHelpFunctions';

class ResultDot extends Component {
    state = {
        tooltipOpen: false,
    }

    toggle = () => {
        this.setState({tooltipOpen: !this.state.tooltipOpen})
    }

    render() {
        this.win = win(this.props.match, this.props.cardID)
        this.selfScore = selfScore(this.props.match, this.props.cardID)
        this.opponentScore = opponentScore(this.props.match, this.props.cardID)
        this.opponentName = opponentName(this.props.match, this.props.cardID)
        return (
            <div className="d-inline">
                {this.win ? <span className="fa fa-circle text-success mr-1" id={'a' + this.props.match._id + this.props.cardID}/> : <span className="fa fa-circle text-danger mr-1" id={'a' + this.props.match._id + this.props.cardID}/>}
                <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={'a' + this.props.match._id + this.props.cardID} toggle={this.toggle}>
                    {this.win ? 
                    `${this.selfScore} - ${this.opponentScore} vs ${this.opponentName}` :
                    `${this.opponentScore} - ${this.selfScore} vs ${this.opponentName}`}
                </Tooltip>
            </div>
        )
    }
};

export default ResultDot;