import React, { Component } from 'react';

class TourDeBiereCard extends Component {
    render () {
        return (
            <div className="col-8 col-sm-4 col-lg-3 px-1 py-1" order={this.props.i}>
                <div className="card px-2 py-2">
                    <img className="card-img-top rounded-circle w-100" src={this.props.item.img} alt={this.props.item.alt}></img>
                    <div className="card-body px-1">
                        <h5 className="card-title mb-1">{this.props.item.edition}
                        <small><small><br/>{this.props.item.date}</small></small></h5>
                        <div>
                            <div className="d-inline-block">
                                <div className="d-flex mb-2">
                                    <img className="mr-1" src={require('../../images/tourdebiere/spaGoudIcon.png')} alt="General standings winner" style={{height:'25px'}}/>
                                    <h5 className="mb-0">{this.props.item.generalWinner}</h5>
                                </div>
                                <div className="d-flex mb-2">
                                    <img className="mr-1" src={require('../../images/tourdebiere/spaGoudIconGreen.png')} alt="General standings winner" style={{height:'25px'}}/>
                                    <h5 className="mb-0">{this.props.item.sprintWinner}</h5>
                                </div>
                                <div className="d-flex mb-2">
                                    <img className="mr-1" src={require('../../images/tourdebiere/spaGoudIconMountain.png')} alt="General standings winner" style={{height:'25px'}}/>
                                    <h5 className="mb-0">{this.props.item.mountainWinner}</h5>
                                </div>
                            </div>
                        </div>
                        <p className="card-text border-top text-muted px-1"><small>{this.props.item.description}</small></p>
                    </div>
                </div>
            </div>
        );
    };
};

class TourDeBiereCarousel extends Component {
    render() {
        let fluidClassName = "row mx-auto d-flex mb-2 flex mb-2-nowrap"
        if (this.props.items.length < 3) {
            fluidClassName += " justify-content-sm-center"
        } else if (this.props.items.length < 4) {
            fluidClassName += " justify-content-lg-center"
        }
        
        return (
            <div id="FameCarousel" className="carousel slide carousel-multi-item xscroll" data-ride="carousel">
                <div className={fluidClassName} role="listbox">
                    {this.props.items.map((item, i) => {
                        return <TourDeBiereCard item={item} i={i} key={i} setAnimating={this.setAnimating} />
                    })}
                </div>
            </div>
        )};
};

export default TourDeBiereCarousel;