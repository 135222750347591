import React, { Component } from "react";
import "./adtvent.css";

function dayCoordinates(day) {
  return [(day % 5) * 25, Math.floor(day / 5) * 25];
}

class HoverElements extends Component {
  state = {
    jokerSelected: false,
    infoSelected: false,
    joker: null,
    info: "Something went wrong, no info available.",
  };

  toggleJokerText = (e) => {
    e.preventDefault();
    this.setState({
      jokerSelected: !this.state.jokerSelected,
      infoSelected: false,
    });
  };

  toggleInfoText = (e) => {
    e.preventDefault();
    this.setState({
      infoSelected: !this.state.infoSelected,
      jokerSelected: false,
    });
  };

  componentDidMount() {
    fetch("/adtvent/taskinfo/" + this.props.day, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ ...this.state, ...data });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    return (
      <div
        className="position-absolute w-100 h-100 d-flex align-content-between flex-wrap text-right text-light pr-1"
        style={{ zIndex: 25 }}
      >
        <div className="d-block w-100">
          <div className="d-block" onClick={this.toggleInfoText}>
            <div className="position-relative csstooltip">
              <span className="fa fa-info-circle" />
              <span
                className={`csstooltiptext-left px-1 py-1 ${
                  this.state.infoSelected ? "visible" : ""
                }`}
              >
                {this.state.info}
              </span>
            </div>
          </div>
          {this.state.joker ? (
            <div className="d-block" onClick={this.toggleJokerText}>
              <div className="position-relative csstooltip">
                <span className="fa fa-star" />
                <span
                  className={`csstooltiptext-left px-1 py-1 ${
                    this.state.jokerSelected ? "visible" : ""
                  }`}
                >
                  {this.state.joker}
                </span>
              </div>
            </div>
          ) : (
            <span />
          )}
          <div className="d-block" onClick={this.props.toggleExpand}>
            <div className="position-relative csstooltip">
              <span className="fa fa-chevron-left" />
              <span className="csstooltiptext-left px-1 py-1">Back</span>
            </div>
          </div>
        </div>
        <div className="d-block w-100" onClick={this.props.close}>
          <div className="position-relative csstooltip">
            <span className="fa fa-times" />
            <span className="csstooltiptext-left px-1 py-1">Reclose day</span>
          </div>
        </div>
      </div>
    );
  }
}

class ExpandedDayCard extends Component {
  state = {
    scale: 0.2,
    url: null,
  };

  get_image = (day) => {
    fetch("/adtvent/task/" + day + "/big", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.body;
      })
      .then((body) => {
        const reader = body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => {
        this.setState({ url: url });
        setTimeout(() => {
          this.setState({ scale: 1 });
        }, 1);
      })
      .catch((err) => console.error(err));
  };

  componentDidMount() {
    this.get_image(this.props.day);
  }

  render() {
    let [x, y] = dayCoordinates(this.props.place);
    return (
      <div
        style={{
          position: "absolute",
          transitionDuration: "1s",
          transformOrigin: x + "% " + y + "%",
          left: "-" + x * 4 + "%",
          top: "-" + y * 4 + "%",
          height: "500%",
          width: "500%",
          transform: `scale(${this.state.scale})`,
          zIndex: 2,
        }}
      >
        <HoverElements
          day={this.props.day}
          toggleExpand={this.props.toggleExpand}
          close={this.props.close}
        />
        <img
          className="w-100"
          alt={"task day" + this.props.day}
          src={this.state.url}
        />
      </div>
    );
  }
}

export default ExpandedDayCard;
