import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Event extends Component {
    render () {
        let outerDivClass = "px-2 py-2"
        outerDivClass += ` order-${this.props.data.order}`
        
        return (
            <div className={outerDivClass}>
                <div className="card px-1 py-1">
                    <img className="card-img-top bg-secondary rounded-circle" alt={this.props.data.alt} src={this.props.data.src}></img>
                    <div className="card-body">
                        <h5 className="card-title"><Link className="stretched-link text-dark text-decoration-none" to={this.props.data.routerLink}>{this.props.data.title}</Link></h5>
                        <span className="card-subtitle"><small>{this.props.data.subtitle}</small></span>
                        <p className="card-text">
                            <span className="fa fa-calendar fa-lg mr-2"></span>
                            <small>{this.props.data.date}</small>
                        </p>
                    </div>
                </div>
            </div>
        )};

};

export default Event;