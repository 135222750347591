const data = [
  {
    title: "Kingsday 2020",
    subtitle: "Corona influenced 4 day tour celebrating kingsday 2020",
    header: `During the 2020 lockdown most sports came to a complete stop, nothing to do yourself and nothing to watch. 
    But with the very popular “borrel” meetings online, the Spa Goud management could not resist the hype and made a quarantine zoom event as well. 
    This first ever Spa Goud Tour de Biere combined the joy of drinking with friends and competing as participants race over the set-out stages. 
    Whilst this was a 4-day event to seek out the most determined competitors, some joined just a few days for fun.`,
    date: "24-27 April 2020",
    link: "kingsday2020",
    standings: {
      general: [
        {
          name: "Beukers",
          time: 6578,
        },
        {
          name: "Niels",
          time: 6984,
        },
        {
          name: "Carmen",
          time: 6991,
        },
        {
          name: "Chris",
          time: 10050,
        },
        {
          name: "Roland",
          time: 11108,
        },
      ],
      sprint: [
        {
          name: "Chris",
          points: 197,
        },
        {
          name: "Beukers",
          points: 155,
        },
        {
          name: "Niels",
          points: 25,
        },
        {
          name: "Carmen",
          points: 18,
        },
        {
          name: "Roland",
          points: 12,
        },
        {
          name: "Misha",
          points: 4,
        },
      ],
      mountain: [
        {
          name: "Niels",
          points: 116,
        },
        {
          name: "Beukers",
          points: 105,
        },
        {
          name: "Carmen",
          points: 85,
        },
        {
          name: "Chris",
          points: 52,
        },
        {
          name: "Roland",
          points: 46,
        },
        {
          name: "Misha",
          points: 18,
        },
      ],
    },
    etappes: [
      {
        title: "Proloog",
        subtitle: "Time trial",
        date: "24 April 2020",
        text: `This short time trail has a parcourse that most of you will recognize. 
        The sportive benchmark is used to kick off the Tour de Biere and will allow all participant to choose their most preferred starting time. 
        You do need to arrange your own video recording and when you are finished, you have to post your result in the well-known WhatsApp group. 
        Officials will then review this for fraud and post the calibrated time on this site.`,
        profile: require("../../images/tourdebiere/profielProloog.svg"),
      },
      {
        title: "Etappe 1",
        subtitle: "Flat stage",
        date: "25 April 2020",
        text: `Although this stage is relatively flat, the first mountain classification points can be earned on top of the small 'dubbel' hill. 
        However, the ones that want to score high in the sprint might want to save some energy here, because the next timing will be for this classification. 
        The number of points to be earned for both of these intermediate recordings are given on the info page linked on top.`,
        profile: require("../../images/tourdebiere/profielEtappe1.svg"),
      },
      {
        title: "Etappe 2",
        subtitle: "Mountain stage",
        date: "26 April 2020",
        text: `The tour moves into the serious terrain and the peloton will have to endure two major cols. 
        There is also a sneaky sprint located in between these demanding features, but after the 'quadrupel' top, it is easy cruising up to the finish line two beers further.`,
        profile: require("../../images/tourdebiere/profielEtappe2.svg"),
      },
      {
        title: "Etappe 3",
        subtitle: "The 'Koningsrit'",
        date: "27 April 2020",
        text: `Many riders will not be looking forward to this stage. 
        With an enormous amount of alcohol percentages to be consumed this stage is rightfully referred to as the “Koningsrit”. 
        In order to also reproduce the normal Kingsday day-drinking mentality, the starting time is set at noon. 
        This might be a long day for some, but with all the points up for grabs in this stage, this is where the rankings will be decided.`,
        profile: require("../../images/tourdebiere/profielEtappe3.svg"),
      },
    ],
  },
];

export default data;
