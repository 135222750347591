import React, { Component } from "react";
import { Link } from "react-router-dom";
import Alerts from "../Alerts/Alerts";
import { getStandartAvatar, bindAvatar } from "../User/bindAvatar";
import Record from "./AdtminRecord";

class AdtminPlayerPage extends Component {
  state = {
    loading: true,
    alerts: [],
    player: null,
  };

  avatar = (player) => {
    if (player.userID) {
      return bindAvatar(player.userID.avatar);
    }
    return getStandartAvatar(player.name);
  };

  addAlert = (alert) => {
    this.setState({ alerts: [...this.state.alerts, alert] });
    console.log(this.state);
  };

  clearAlerts = () => {
    this.setState({ alerts: [] });
  };

  getPlayer = () => {
    fetch("/spies/player/" + this.props.match.params.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        data.avatar = this.avatar(data);
        this.setState({
          player: data,
          loading: false,
        });
      })
      .catch((err) => {
        this.addAlert({ type: "danger", msg: err.errorMsg });
        this.setState({ loading: false });
        console.log(err);
      });
  };

  componentDidMount() {
    this.getPlayer();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 pt-3">
            <div>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>
                <small>Loading players</small>
              </p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <div className="row mb-2 justify-content-center align-items-center">
          <div className="col-8 col-sm-4 col-lg-2">
            <img
              className="w-100 rounded-circle"
              alt="player avatar"
              src={this.state.player.avatar}
            />
          </div>
          <h3 className="col-4 text-left">Update results of {this.state.player.name}</h3>
        </div>
        <div className="row justify-content-center">
          <h3>All records</h3>
          <Alerts alerts={this.state.alerts} />
          {this.state.player.results
            .filter((record) => record)
            .map((record, i) => (
              <Record
                record={{
                  ...record,
                  user: { _id: this.state.player.userID._id },
                  name: this.state.player.name,
                  playerID: this.state.player._id,
                }}
                key={i}
                addAlert={this.addAlert}
                clearAlerts={this.clearAlerts}
              />
            ))}
        </div>
        <Link to="/spiesranking/adtmin">Back to adtmin overview</Link>
      </div>
    );
  }
}

export default AdtminPlayerPage;
