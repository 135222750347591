import React, { Component } from "react";
import SearchUser from "../User/SearchUser";
import {
  BarChart,
  TimePerBeerChart,
  RankPerBeerChart,
  TimeDifPerBeerChart,
} from "./Charts";
import { ChartCarousel } from "./ChartCarousel";
import { standartAvatars, bindAvatar } from "../User/bindAvatar";
import openSocket from "socket.io-client";

class Settings extends Component {
  state = {
    inputField: { name: "" },
    form: false,
    errorMsg: null,
  };

  toggleForm = (e) => {
    e.preventDefault();
    this.setState({
      inputField: { name: "" },
      form: !this.state.form,
      errorMsg: null,
    });
  };

  submitPlayer = (e) => {
    e.preventDefault();
    // form validation
    if (
      this.props.players
        .map((player) => player.name)
        .includes(this.state.inputField.name)
    ) {
      this.setState({ errorMsg: "Playername already exists" });
      return;
    }
    this.setState({ form: false });
    fetch("/adtjekratje/addPlayer", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionID: this.props.sessionID,
        newPlayer: this.state.inputField,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        this.props.updateSession();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateState = (obj) => {
    this.setState({ inputField: obj });
  };

  render() {
    if (this.state.form) {
      return (
        <form className="form-inline col-11 col-sm-6 px-0 mb-2">
          <div className="input-group w-100 justify-content-center">
            <label className="sr-only" htmlFor="inlineAddPlayerForm">
              New Player
            </label>
            <SearchUser
              className="rounded-left"
              id="newPlayer"
              placeholder="New Player"
              setState={this.updateState}
              submit={this.submitPlayer}
              state={this.state.inputField}
            />
            <div className="input-group-append">
              <button
                className="input-group-text btn btn-secondary"
                onClick={this.toggleForm}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="input-group-append">
              <button
                className="input-group-text btn btn-outline-success"
                onClick={this.submitPlayer}
              >
                Add
              </button>
            </div>
          </div>
          {this.state.errorMsg ? (
            <div className="px-2 text-danger">{this.state.errorMsg}</div>
          ) : (
            <span aria-hidden="true" />
          )}
        </form>
      );
    } else {
      return (
        <div className="btn-group mb-2 col-12 justify-content-center">
          <button
            type="button"
            className="col-10 col-sm-5 btn btn-outline-success"
            onClick={this.toggleForm}
          >
            Add player
          </button>
          <button
            type="button"
            className="col-2 col-sm-1 btn btn-secondary "
            onClick={this.props.toggle}
          >
            <span className="fa fa-cog" />
          </button>
        </div>
      );
    }
  }
}

class Clock extends Component {
  state = {
    timeString: "",
    dtf: new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }),
  };

  updateClock = () => {
    clearInterval(this.clockInverval);
    let s = (Date.now() - new Date(this.props.createdAt)) / 1000;
    let sec = ("0" + Math.round(s % 60)).slice(-2);
    let min = ("0" + Math.floor((s % 3600) / 60)).slice(-2);
    let hrs = ("0" + Math.floor(s / 3600)).slice(-2);
    this.setState({
      timeString: `${hrs}:${min}:${sec}`,
    });
    this.clockInverval = setInterval(this.updateClock, 1000);
  };

  componentDidMount() {
    this.updateClock();
  }

  render() {
    return (
      <div className="border-bottom">
        <h1 className="display-6">
          {`${this.props.sessionName} - `}
          <p
            className="d-inline"
            style={{ fontFamily: '"Lucida Console", Monaco, monospace' }}
          >
            {this.state.timeString}
          </p>
        </h1>
      </div>
    );
  }
}

class BeerLog extends Component {
  state = {
    confirm: false,
  };

  toggleConfirm = (e) => {
    e.preventDefault();
    this.setState({ confirm: !this.state.confirm });
  };

  dtf = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  playerName = (id) => {
    let playerArray = this.props.players.filter((player) => player._id === id)
    if (playerArray.length === 0) {
      return "Deleted player"
    }
    return playerArray[0].name;
  };

  removeEntry = (e) => {
    e.preventDefault();
    this.setState({ confirm: false });
    fetch("/adtjekratje/removeItem", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionID: this.props.sessionID,
        itemToRemove: this.props.record._id,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        this.props.updateSession();
      })
      .catch((err) => {
        console.error(err);
      });
    // update entire page (refetch session data)
  };

  render() {
    return (
      <tr>
        <td>{this.dtf.format(new Date(this.props.record.time))}</td>
        <td className="text-left">
          {this.playerName(this.props.record.player)}
        </td>
        <td>
          {this.state.confirm ? (
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-secondary px-1 py-0"
                onClick={this.toggleConfirm}
                aria-label="Cancel"
              >
                <span className="fa fa-close" />
              </button>
              <button
                type="button"
                className="btn btn-danger px-1 py-0"
                onClick={this.removeEntry}
                aria-label="Confirm"
              >
                <span className="fa fa-trash" />
              </button>
            </div>
          ) : (
            <button
              className="btn btn-outline-danger px-1 py-0"
              onClick={this.toggleConfirm}
            >
              <span className="fa fa-trash" />
            </button>
          )}
        </td>
      </tr>
    );
  }
}

class PlayerKratje extends Component {
  state = {
    selected: false,
    updateUser: { name: "" },
    btnState: "confirm",
  };

  togglePlayer = (e) => {
    e.preventDefault();
    this.setState({ selected: !this.state.selected, userEdit: false });
  };

  endFlash = () => {
    clearInterval(this.flashTime);
    this.setState({ selected: false, btnState: "confirm" });
    this.props.updateSession();
  };

  logBeer = (e) => {
    e.preventDefault();
    this.setState({ btnState: "wait" });
    fetch("/adtjekratje/addItem", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionID: this.props.sessionID,
        player: this.props.player,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        this.setState({
          btnState: "success",
        });
        // this.props.updateSession();
        this.flashTime = setInterval(() => this.endFlash(), 1000);
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          btnState: "error",
        });
        this.flashTime = setInterval(() => this.endFlash(), 1000);
      });
  };

  buttonState = () => {
    switch (this.state.btnState) {
      case "confirm":
        return (
          <div
            className="pos-abs btn-group w-100 px-2"
            style={{
              top: "43%",
              left: "50%",
              height: "45%",
              transform: "translate(-50%, 0%)",
            }}
          >
            <button
              type="button"
              style={{ width: "40%" }}
              className="btn btn-secondary"
              onClick={this.togglePlayer}
              aria-label="Close"
            >
              <img
                className="text-white"
                src={require("../../images/bottle-cancel.svg")}
                style={{ height: "1.5em" }}
                alt="Cancel beer"
              />
            </button>
            <button
              type="button"
              style={{ width: "60%" }}
              className="btn btn-success"
              onClick={this.logBeer}
            >
              <img
                className="text-white"
                src={require("../../images/bottle-plus.svg")}
                style={{ height: "1.5em" }}
                alt="Confirm beer"
              />
            </button>
          </div>
        );
      case "wait":
        return (
          <div
            className="pos-abs btn-group w-100 px-2 py-3"
            style={{
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="btn btn-secondary w-100">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        );
      case "success":
        return (
          <div
            className="pos-abs btn-group w-100 px-2 py-3"
            style={{
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="btn btn-outline-success w-100">
              <span className="fa fa-check"></span>
            </div>
          </div>
        );
      default:
        return (
          <div
            className="pos-abs btn-group w-100 px-2 py-3"
            style={{
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="btn btn-danger w-100">
              <span className="fa fa-exclamation-triangle"></span>
            </div>
          </div>
        );
    }
  };

  avatar = (player) => {
    if (player.userID) {
      return bindAvatar(player.userID.avatar);
    }
    var hash = 0,
      len = player.name.length;
    for (var i = 0; i < len; i++) {
      hash = (hash << 5) - hash + player.name.charCodeAt(i);
      hash |= 0; // to 32bit integer
    }
    return bindAvatar(standartAvatars[hash % standartAvatars.length]);
  };

  render() {
    if (this.state.selected) {
      return (
        <div className="col-6 col-sm-3 col-md-2 px-1 py-1">
          <div className="card">
            <div className="card-body px-1 py-1">
              <div className="card-title row mb-0 align-items-center">
                <img
                  className="col-6 w-100 rounded-circle"
                  alt="Beer avatar"
                  src={this.avatar(this.props.player)}
                />
                <h5 className="col-6 pl-0 d-flex">
                  <span className="float-left text-truncate">
                    {this.props.player.name}
                  </span>
                </h5>
              </div>
            </div>
            <div
              className="w-100 bg-custom-blue"
              style={{ paddingTop: "100%" }}
            >
              {this.buttonState()}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="col-6 col-sm-3 col-md-2 px-1 py-1">
        <div className="card" onClick={this.togglePlayer}>
          <div className="card-body px-1 py-1">
            <div className="card-title row mb-0 align-items-center">
              <img
                className="col-6 w-100 rounded-circle"
                alt="Beer avatar"
                src={this.avatar(this.props.player)}
              />
              <h5 className="col-6 pl-0 text-left text-truncate">
                {this.props.player.name}
              </h5>
            </div>
          </div>
          <img
            className="card-img-bottom"
            src={require(`../../images/adtjekratje/Kratje_${Math.max(
              24 - this.props.player.records.length,
              0
            )}.png`)}
            alt="Kratje"
          ></img>
        </div>
      </div>
    );
  }
}

class PlayerEdit extends Component {
  state = {
    updateUser: { name: "" },
    removePlayer: false,
  };

  updateState = (obj) => {
    this.setState({ updateUser: obj });
  };

  componentDidMount() {
    this.setState(this.props.player);
  }

  updateUser = (e) => {
    e.preventDefault();
    if (
      this.props.players
        .map((player) => player.name)
        .includes(this.state.updateUser.name)
    ) {
      console.error("Playername already exists");
      this.setState({ errorMsg: "Playername already exists" });
      return;
    }
    fetch("/adtjekratje/changePlayer", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionID: this.props.sessionID,
        player: { ...this.state.updateUser, _id: this.props.player._id },
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        this.props.updateSession();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  toggleRemovePlayer = (e) => {
    e.preventDefault();
    this.setState({ removePlayer: !this.state.removePlayer });
  };

  removePlayer = (e) => {
    e.preventDefault();
    fetch("/adtjekratje/removePlayer", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionID: this.props.sessionID,
        player: this.props.player._id,
      }),
    })
      .then((res) => {
        if (res.status !== 204) {
          throw new Error(res.statusText);
        }
        this.props.updateSession();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    if (this.state.removePlayer) {
      return (
        <div className="form-group row justify-content-center">
          <div className="col-8 col-form-label border border-danger text-danger">
            Are you sure you want to remove {this.props.player.name} from
            the session? This action can not be reversed
          </div>
          <div className="btn-group">
            <button className="btn btn-warning" onClick={this.toggleRemovePlayer}>Cancel</button>
            <button className="btn btn-danger" onClick={this.removePlayer}>Delete</button>
          </div>
        </div>
      );
    }
    return (
      <div className="form-group row justify-content-center">
        <div className="col-12 col-sm-4 col-form-label">
          {this.props.player.name}
        </div>
        <div className="col-12 col-sm-8">
          <div className="input-group justify-content-center">
            <label className="sr-only" htmlFor="inlineAddPlayerForm">
              Change Name
            </label>
            <SearchUser
              className="rounded-left"
              id="updatePlayer"
              placeholder="Change name"
              setState={this.updateState}
              submit={this.updateUser}
              state={this.state.updateUser}
            />
            <div className="input-group-append">
              <button
                className="input-group-text btn btn-outline-success"
                onClick={this.updateUser}
              >
                Set
              </button>
            </div>
            <div className="input-group-append">
              <button
                className="input-group-text btn btn-danger"
                onClick={this.toggleRemovePlayer}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        {this.state.errorMsg ? (
          <div className="col-12 text-danger">{this.state.errorMsg}</div>
        ) : (
          <span aria-hidden="true" />
        )}
      </div>
    );
  }
}

class AdtjeKratjeSession extends Component {
  state = {
    loading: true,
    data: null,
    errMsg: null,
    showLogs: false,
    socket: null,
    settings: false,
  };

  toggleLogs = (e) => {
    e.preventDefault();
    this.setState({ showLogs: !this.state.showLogs });
  };

  toggleSettings = (e) => {
    e.preventDefault();
    this.setState({ settings: !this.state.settings });
  };

  handleClick = (e) => {
    e.persist();
    this.setState((prevState) => ({
      data: { ...prevState.data, [e.target.name]: e.target.checked },
    }));
  };

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      data: { ...prevState.data, [e.target.name]: e.target.value },
    }));
  };

  fetchSession = () => {
    fetch("/adtjekratje/session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionID: this.props.sessionID,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        data.players.forEach((player) => {
          player.records = data.records.filter(
            (record) => String(record.player) === String(player._id)
          );
        });
        this.setState({
          loading: false,
          data: data,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
        this.setState({
          errMsg: {
            msg: err.message,
            type: "danger",
          },
        });
      });
  };

  changeStatus = (e, activate) => {
    e.preventDefault();
    fetch("/adtjekratje/changeStatus", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionID: this.props.sessionID,
        status: activate,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        this.setState({ settings: false });
        this.updateSession();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  changeDetails = (e) => {
    e.preventDefault();
    fetch("/adtjekratje/changeDetails", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionID: this.props.sessionID,
        name: this.state.data.name,
        private: this.state.data.private,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        this.setState({ settings: false });
        this.updateSession();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateSession = () => {
    if (this.state.socket) {
      this.state.socket.emit("update", null);
    }
    this.fetchSession();
  };

  componentDidMount() {
    this.fetchSession();
    // connect to socket
    const socket = openSocket("/kratjeSocket", { path: "/sockets" });
    socket.emit("joinRoom", this.props.sessionID);
    socket.on("update", () => {
      this.fetchSession();
    });
    this.setState({ socket: socket });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="">
          <div>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>
              <small>Loading Session</small>
            </p>
          </div>
        </div>
      );
    }

    if (this.state.settings) {
      return (
        <div className="justify-content-center">
          <h3 className="">Session Settings</h3>
          <div className="mb-3">
            {this.state.data.active ? (
              <button
                className="btn btn-danger"
                onClick={(e) => this.changeStatus(e, false)}
              >
                Stop Session
              </button>
            ) : (
              <button
                className="btn btn-warning"
                onClick={(e) => this.changeStatus(e, true)}
              >
                Continue Session
              </button>
            )}
          </div>
          <form className="px-3 border-bottom">
            <div className="form-group row justify-content-center">
              <label className="col-4 col-form-label" htmlFor="sessionName">
                Session Name
              </label>
              <input
                type="text"
                name="name"
                id="sessionName"
                className="form-control col-8"
                value={this.state.data.name}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group row justify-content-center">
              <label className="col-4 col-form-label" htmlFor="private">
                Private
              </label>
              <input
                type="checkbox"
                name="private"
                id="private"
                className="form-control col-8 text-left"
                checked={this.state.data.private}
                onChange={this.handleClick}
              />
            </div>
            <button
              type="submit"
              className="btn btn-success mb-2"
              onClick={this.changeDetails}
            >
              Save Changes
            </button>
          </form>
          {this.state.data.active ? (
            <form>
              <div className="form-control-plaintext">Players:</div>
              {this.state.data.players.map((player) => (
                <PlayerEdit
                  player={player}
                  players={this.state.data.players}
                  key={String(player._id)}
                  sessionID={this.props.sessionID}
                  updateSession={this.updateSession}
                />
              ))}
            </form>
          ) : (
            <span aria-hidden="true" />
          )}
          <button className="btn btn-link" onClick={this.toggleSettings}>
            Back
          </button>
        </div>
      );
    }

    return (
      <div>
        {this.state.data.active ? (
          <div>
            <Clock
              createdAt={this.state.data.createdAt}
              sessionName={this.state.data.name}
            />
            <div className="row justify-content-center">
              {this.state.data.players
                .sort((a, b) => (a.records.length > b.records.length ? -1 : 1))
                .map((player) => (
                  <PlayerKratje
                    player={player}
                    players={this.state.data.players}
                    key={String(player._id)}
                    sessionID={this.props.sessionID}
                    updateSession={this.updateSession}
                  />
                ))}
            </div>
            <div className="row justify-content-center">
              <Settings
                players={this.state.data.players}
                updateSession={this.updateSession}
                sessionID={this.props.sessionID}
                toggle={this.toggleSettings}
              />
            </div>
            <div className="border-bottom" />
          </div>
        ) : (
          <div className="text-right">
            <button
              type="button"
              className="col-2 col-sm-1 btn btn-secondary "
              onClick={this.toggleSettings}
            >
              <span className="fa fa-cog" />
            </button>
          </div>
        )}
        {window.innerWidth < 576 ? (
          <ChartCarousel data={this.state.data} />
        ) : (
          <div className="row">
            <div className="col-12 col-md-6">
              <div style={{ height: "40vh", minHeight: "300px" }}>
                <BarChart data={this.state.data} />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div style={{ height: "40vh", minHeight: "300px" }}>
                <TimePerBeerChart data={this.state.data} />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div style={{ height: "40vh", minHeight: "300px" }}>
                <RankPerBeerChart data={this.state.data} />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div style={{ height: "40vh", minHeight: "300px" }}>
                <TimeDifPerBeerChart data={this.state.data} />
              </div>
            </div>
          </div>
        )}
        <div className="border-bottom" />
        <button className="btn btn-link text-dark" onClick={this.toggleLogs}>
          Show logs{" "}
          {this.state.showLogs ? (
            <span className="fa fa-chevron-up" />
          ) : (
            <span className="fa fa-chevron-down" />
          )}
        </button>
        {this.state.showLogs ? (
          <div className="row justify-content-center">
            <table className="table col-11 col-sm-9 col-md-7">
              <thead>
                <tr>
                  <th scope="col">Time</th>
                  <th scope="col" className="text-left">
                    Player
                  </th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.records.reverse().map((record, i) => (
                  <BeerLog
                    key={i}
                    record={record}
                    players={this.state.data.players}
                    sessionID={this.props.sessionID}
                    updateSession={this.updateSession}
                  />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <span aria-hidden="true" />
        )}
      </div>
    );
  }
}

export default AdtjeKratjeSession;
