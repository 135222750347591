import React, { Component } from 'react';
import FameCarousel from './FameCarousel';
import TourDeBiereCarousel from './TourDeBiereCarousel';

const ChampionshipWinners = [
    // {
    //     name: "Lucas",
    //     description: `Since the defending champion didn't join the  twenties themed tournament, a power vacuum was created.
    //     The one who stood up and took his place was Arya. 
    //     In the final he coped a lot better with the severe brain freeze conditions and destroyed Niels to claim the title.`,
    //     img: require("../../images/avatars/famous.png"),
    //     alt: "CK winner 2023",
    //     date: 2023
    // },
    // {
    //     name: "Mick",
    //     description: `Since the defending champion didn't join the  twenties themed tournament, a power vacuum was created.
    //     The one who stood up and took his place was Arya. 
    //     In the final he coped a lot better with the severe brain freeze conditions and destroyed Niels to claim the title.`,
    //     img: require("../../images/avatars/famous.png"),
    //     alt: "CK winner 2022",
    //     date: 2022
    // },
    {
        name: "Arya",
        description: `Since the defending champion didn't join the  twenties themed tournament, a power vacuum was created.
        The one who stood up and took his place was Arya. 
        In the final he coped a lot better with the severe brain freeze conditions and destroyed Niels to claim the title.`,
        img: require("../../images/halloffame/Arya.jpg"),
        alt: "CK winner 2020",
        date: 2020
    },
    {
        name: "Pik",
        description: `
        The reigning champion prolonged his title during the minion themed CK championships of 2019. 
        He obliterated his female challenger Sunny in the final, 
        who managed to brave the otherwise solely testosterone filled tournament field of 28.`,
        img: require("../../images/halloffame/Pik2.jpeg"),
        alt: "CK winner 2019",
        date: 2019
    },
    {
        name: "Pik",
        description: `Everyone knew he was fast, but the whole point of the first edition of the
        Adt Championship was to find out if he was the fastest. During the monopoly themed CK, 
        he asserted his dominance and earned the title of first every Adt champion.`,
        img: require("../../images/halloffame/Pik.jpg"),
        alt: "CK winner 2018",
        date: 2018
    },
]

const AdtventWinners = [
    {
        name: "Roland",
        description: `
        This years adtvent saw a record number of competitors start December in good spirits. 
        But soon the usual suspects climbed to the top of the rankings. 
        With the final battle fought within a single household. 
        Roland triumphed and was crowned the first consecutive winner.`,
        img: require("../../images/halloffame/Roland2.png"),
        alt: "Adtvent winner 2021",
        date: 2021
    },
    {
        name: "Roland",
        description: `
        The highly anticipated third adtvent started with a complete graphic rework. 
        A leading group was soon formed with a couple of veterans and one newbie. 
        They easily stayed ahead of two pursuers by completing every challenge except one. 
        Roland finally took the win on the final day where Sjors met his Waterloo in the Christmas tree adt.`,
        img: require("../../images/halloffame/Roland.png"),
        alt: "Adtvent winner 2020",
        date: 2020
    },
    {
        name: "Carmen",
        description: `
        This second edition of the calendar saw many more participants, 
        but only a few knew to persevere the increasingly outrageous challenges. 
        In the last week it had become a two horse race, 
        but on the final day Carmen surpassed her new nemesis Thijs to claim this place in the hall of fame.`,
        img: require("../../images/halloffame/Carmen.jpg"),
        alt: "Adtvent winner 2019",
        date: 2019
    },
    {
        name: "Beukers",
        description: `
        The first edition of this new event created to overcome the boring period usually spend at your parents. 
        Beukers did not execute every daily challenge, but when others came close, he did attain the necessary points to stay ahead. 
        It was almost suspicious how he was prepared for every objective within 24 hours’ notice...`,
        img: require("../../images/halloffame/Koen.jpg"),
        alt: "Adtvent winner 2018",
        date: 2018
    },
]

const TourDeBieres = [
    {
        edition: "Tour de Quarantine",
        date: "April 2020",
        description: `
        Despite the wistful season, we can look back on a memorable first edition of this rancorous event. 
        Only two athletes braved the full four-day schedule and therefore took home most of the points. 
        But the rules were slightly bent to reward a particular mountain goat who surprised everyone in the packed 2nd stage.
        `,
        img: require("../../images/halloffame/Koen2.jpg"),
        generalWinner: "Beukers",
        sprintWinner: "Chris",
        mountainWinner: "Niels",
    }
];

class HallOfFame extends Component {
    render() {
        return (
            <div>
                <h2 className="text-center display-2">Hall of Fame</h2>
                <div className="row justify-content-center">
                    <p className="col-11 col-lg-8">This is where the Spa Goud champions are celebrated. Maybe, one day, you can make it onto this page. Just train hard and participate in the upcomming events!</p>
                </div>
                <h2 className="text-center">Championship</h2>
                <FameCarousel items={ChampionshipWinners} />
                <h2 className="text-center">Adtvent Calendar</h2>
                <FameCarousel items={AdtventWinners} />
                <h2 className="text-center">Tour de Biere</h2>
                <TourDeBiereCarousel items={TourDeBieres} />
            </div>
        )};
};

export default HallOfFame;