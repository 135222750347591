import React, { Component } from "react";
import Timeline from "./Timeline";
import "./roadmap.css";

class BaseRule extends Component {
  render() {
    return (
      <div className="col-auto border rounded bg-light py-1 px-1 my-1 mx-1" style={{maxWidth: "40vw"}}>
        <img
          className=""
          alt={this.props.alt + "icon"}
          src={this.props.src}
          style={{ maxWidth: "100px" }}
        />
        <p
          className="mb-0 mt-1 px-1"
          style={{ maxWidth: "150px", maxHeight: "100px" }}
        >
          {this.props.text}
        </p>
      </div>
    );
  }
}

class BaseRules extends Component {
  render() {
    return (
      <div
        className="container-fluid border rounded px-0"
        style={{ backgroundColor: "#f3f3f3" }}
      >
        <h2 className="h2 pink px-2">Base Rules for everyone</h2>
        <div className="row mx-1 mb-1 justify-content-center">
          <div className="col-auto border rounded py-1 px-1 my-1 mx-1 bg-pink text-light" style={{ maxWidth: "40vw" }}>
            <p style={{ width: "150px" }}>Feeling ill?</p>
            <div className="d-flex align-items-center mb-1">
              <img
                style={{ maxWidth: "35px" }}
                alt="Home"
                src={require("../../images/roadmap/home.svg")}
              />
              <p className="pl-2 mb-0">Stay home</p>
            </div>
            <div className="">
              <div className="d-flex align-items-center mb-1">
                <img
                  style={{ maxWidth: "35px" }}
                  alt="Testing"
                  src={require("../../images/roadmap/testing.svg")}
                />
                <p className="pl-2 mb-0">Get tested</p>
              </div>
            </div>
          </div>
          <BaseRule
            alt="drink from home"
            src={require("../../images/roadmap/drinkFromHome.svg")}
            text="Drink from home, as much as possible"
          />
          <BaseRule
            alt="keep distance"
            src={require("../../images/roadmap/keepDistance.svg")}
            text="Keep 1.5 m distance"
          />
          <BaseRule
            alt="Wash glass"
            src={require("../../images/roadmap/washGlass.svg")}
            text="Wash glass regularly"
          />
          <BaseRule
            alt="drink from home"
            src={require("../../images/roadmap/overcrowding.svg")}
            text="Avoid overcrowding"
          />
          <BaseRule
            alt="Own drinks"
            src={require("../../images/roadmap/ownDrinks.svg")}
            text="Get your own drinks"
          />
        </div>
      </div>
    );
  }
}

class Roadmap extends Component {
  state = {
    expanded: false,
  };

  toggleInfo = (e) => {
    e.preventDefault();
    console.log(`toggle`);
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <div>
        <img
          className="w-100"
          alt="SpaGoud authority header"
          src={require("../../images/SpaGoudAuthority.png")}
          style={{ maxWidth: "500px" }}
        />
        <BaseRules />
        <div className="row justify-content-center">
          <div className="col-11 col-sm-8 col-md-6">
            <h2 className="h2 pink">How this works</h2>
            {this.state.expanded ? (
              <div>
                <p>
                  During these difficult times, we beer lovers need something to
                  look forward to. Luckily December with its annual Adtvent
                  Calendar is getting close, but we might need some warming up.
                  Therefore, the Spa-Goud management arranged this roadmap.
                </p>
                <p>
                  For those who are not yet familiar with the Spa-Goud Adtvent
                  Calendar, this is a 25-day event you can participate in from
                  home. Every day a new drinking related challenge is released,
                  which if completed will earn you points. The one with the most
                  points on Christmas day solidifies its place in history and is
                  added to the hall of fame. For all challenges video evidence
                  should be submitted in the community whatsapp-group, which you
                  can join
                  <a
                    className=""
                    href="https://chat.whatsapp.com/DrqWtmAT8gDGh3DXGWbPjy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    here
                  </a>
                  .
                </p>
                <p>
                  This roadmap provides a somewhat lower key preview to the
                  Adtvent Calendar and is hopefully a fun activity to do during
                  (semi) quarantine. The videos of completed tasks can be
                  submitted in the whatsapp-group and could even earn you some
                  advantage for the December event. Per completed task in the
                  timeline, you earn one Joker. These can be used to score
                  points more easily during certain tasks of the Adtvent
                  Calendar.
                </p>
                <span
                  className="btn btn-link text-muted"
                  onClick={this.toggleInfo}
                >
                  Less
                  <span className="fa fa-chevron-up text-muted mb-0 pl-1" />
                </span>
              </div>
            ) : (
              <div>
                <p>
                  Earn jokers for the Adtvent Calendar by completing the tasks
                  before the dealines in the timeline below. Like usual, you are
                  required to post video evidence of executed tasks in the
                  Spa-Goud whatsapp-group.
                </p>
                <span
                  className="btn btn-link text-muted"
                  onClick={this.toggleInfo}
                >
                  More info
                  <span className="fa fa-chevron-down text-muted mb-0 pl-1" />
                </span>
              </div>
            )}
          </div>
        </div>
        <Timeline />
      </div>
    );
  }
}

export default Roadmap;
