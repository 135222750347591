import React, { Component } from "react";
import { Link } from "react-router-dom";

class AdtminLink extends Component {
  state = {
    admin: false,
  };

  chechAdtmin = () => {
    fetch("/user/admin", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return this.setState({ admin: true });
        } else if (res.status !== 401) {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        if (err.status !== 401) {
          console.log(err);
        }
      });
  };

  componentDidUpdate(prev) {
      if (this.props.user && this.props.user !== prev.user) {
          this.chechAdtmin()
      }
  }

  componentDidMount() {
      if (this.props.user) {
        this.chechAdtmin()
      }
  }

  render() {
    if (this.state.admin) {
      return (
        <div className="mt-3">
          <Link to={this.props.to}>
            {this.props.text ? this.props.text : "To adtmin page"}
          </Link>
        </div>
      );
    }
    return <span className="hidden" aria-hidden="true" />;
  }
}

export default AdtminLink;
