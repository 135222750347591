const tasks = {
  tasks: [
    {
      id: "1A",
      title: "Drink from a trophy",
      deadline: new Date("2020-11-15"),
      icon: require("../../images/roadmap/spaGoudIconPink.svg"),
      text: [
        "To replicate the Spa-Goud logo in your home, post evidence of yourself drinking from a trophy. ",
      ],
      completedBy: [],
    },
    {
      id: "1B",
      title: "Find the beer 1",
      deadline: new Date("2020-11-15"),
      icon: require("../../images/roadmap/searchBeer.svg"),
      text: [
        "Be the first to post evidence of yourself consuming one of the cryptically described beers:",
        "1: The Trammps",
        "2: O.J Simpson barked at the King",
        "3: A very upset beer, because it is lacking it's swingset",
      ],
      completedBy: [],
    },
    {
      id: "1C",
      title: "Support your locals",
      deadline: new Date("2020-11-15"),
      icon: require("../../images/roadmap/beer-shop.svg"),
      text: [
        "Support your favorite bar by taking out something. Most establishments have take-off coffee now, but I would advise an Irish coffee.",
      ],
      completedBy: [],
    },
    {
      id: "2A",
      title: "Zoom game",
      deadline: new Date("2020-11-22"),
      icon: require("../../images/roadmap/videoCall.svg"),
      text: [
        "Play an online drinking game. You could for instance challenge someone for the Spa-Goud ladter...",
      ],
      completedBy: [],
    },
    {
      id: "2B",
      title: "Find the bar",
      deadline: new Date("2020-11-22"),
      icon: require("../../images/roadmap/searchBar.svg"),
      text: [
        "Be the first to post a selfy in front of these cryptically described bars:",
      ].concat(
        new Date() >= new Date("2020-11-16")
          ? [
              "1: Like the building opposite to this establishment, the doors here were always open",
              "2: This one is lacking salt",
              "3: There are no words to describe the ambiance here",
            ]
          : ["Available on 16 November"]
      ),
      completedBy: [],
    },
    {
      id: "2C",
      title: "Beer tower",
      deadline: new Date("2020-11-22"),
      icon: require("../../images/roadmap/beerTower.svg"),
      text: [
        "Build a tower of beer, which is at least your own height. You can only use bottles, cans and cardboard. No glue or tape allowed.",
      ],
      completedBy: [],
    },
    {
      id: "3A",
      title: "Beer tasting",
      deadline: new Date("2020-11-30"),
      icon: require("../../images/roadmap/beer-taps.svg"),
      text: [
        "Treat yourself (and your roommates) to a beer tasting with beers from at least 5 different countries.",
      ],
      completedBy: [],
    },
    {
      id: "3B",
      title: "Find the beer 2",
      deadline: new Date("2020-11-30"),
      icon: require("../../images/roadmap/searchBeer.svg"),
      text: [
        "Be the first to post evidence of yourself consuming one of the cryptically described beers:",
      ].concat(
        new Date() >= new Date("2020-11-23")
          ? [
              "1: Brad",
              "2: Evil limp step leap",
              "3: This sad castle can be found in a new town on the border between Germany and France",
            ]
          : ["Available on 23 November"]
      ),
      completedBy: [],
    },
    {
      id: "3C",
      title: "Trick shot",
      deadline: new Date("2020-11-30"),
      icon: require("../../images/roadmap/catchBeer.svg"),
      text: [
        "The 1.5 meter makes it hard to hand someone a beer. Exaggerate this by taping a beer trick shot. Inspiration can be found on YouTube at \"pass me a beer\".",
      ],
      completedBy: [],
    },
  ],
};

export default tasks;
