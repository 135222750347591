import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class About extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8">
                    <h5>About</h5>
                    <p>
                        This is a non commercial site created by Sven Keukenboer. For any questions and or suggestions contact svenkeukenboer@gmail.com
                    </p>
                    <img className="w-50 rounded-circle mb-3" alt={"Artist beer"} src={require('../../images/avatars/artist.png')}/>
                    <p>
                        The artwork on the site is made by Anneloes Tilman. These should not be used for further distribution.
                    </p>
                    <p>
                        <small className="text-muted">Some components used in the artwork were created by freepik -
                        <a className="text-dark" href="https://www.freepik.com/free-photos-vectors/background"> www.freepik.com</a></small>
                    </p>
                    <Link to={'/'}>Back</Link>
                    </div>
                </div>
            </div>
        )};
};

export default About;