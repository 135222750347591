import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Event from './Event';

class Home extends Component {
    render() {
        return (
            <div className="container">
                <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4">
                    {this.props.events.map((event, i) => {
                        return <Event key={i} data={event} />
                    })}
                </div>
                <small className="pb-2"><Link to={'/about'} className="text-muted" >Site created by Sven Keukenboer</Link></small>
            </div>
        )};
};

export default Home;