import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
import { bindAvatar } from './bindAvatar'


class AchievementBadge extends Component {
    state = {
        tooltipOpen: false,
    }

    toggle = () => {
        this.setState({tooltipOpen: !this.state.tooltipOpen})
    }

    render () {
        return (
            <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                <img className="w-100 px-1 py-1 rounded-circle" src={bindAvatar(this.props.data.srcBind)} alt={`${this.props.data.title} badge`} id={`achievement-${this.props.id}`}/>
                <small className="text-muted">{this.props.data.title}</small>
                <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={`achievement-${this.props.id}`} toggle={this.toggle}>{this.props.data.text}</Tooltip>
            </div>
        );
    };
};

export default AchievementBadge;