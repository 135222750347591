import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Chart } from "chart.js";
import { getStandartAvatar, bindAvatar } from "../User/bindAvatar";

function strDay(day) {
  return ("0" + day).slice(-2);
}

class PlayerPage extends Component {
  state = {
    loading: true,
    data: null,
    minDate: null,
    jokers: "?",
  };

  avatar = (player) => {
    if (player.userID) {
      return bindAvatar(player.userID.avatar);
    }
    return getStandartAvatar(player.name);
  };

  getJokers = () => {
    fetch("/adtvent/jokers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id: this.props.match.params.id }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ jokers: data.jokers });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchScores = () => {
    fetch("/adtvent/scores", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        data.forEach((element) => {
          element.selected = element._id === this.props.match.params.id;
          if (element._id === this.props.match.params.id) {
            element.avatar = this.avatar(element);
          }
          // create point array
          element.history = element.results
            .sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1))
            .reduce((acc, cur, i) => {
              if (acc.length === 0) {
                acc.push({
                  points: cur.points,
                  date: new Date(cur.date),
                });
                return acc;
              } else {
                acc.push({
                  points: acc[i - 1].points + cur.points,
                  date: new Date(cur.date),
                });
                return acc;
              }
            }, []);
          element.total = element.history[element.history.length - 1].points;
        });
        this.setState({
          player: data.find((player) => player.selected),
          data: data,
          loading: false,
        });
        this.pointsChart();
        this.rankChart();
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  };

  floorDate = (date) => {
    if (typeof date === "string") {
      date = new Date(date);
    }
    let year = date.getUTCFullYear();
    let month = date.getUTCMonth() + 1;
    let day = date.getUTCDate();
    return new Date(`${year}-${month}-${strDay(day)}T00:00:00`);
  };

  floorDateZ = (date) => {
    if (typeof date === "string") {
      date = new Date(date);
    }
    let year = date.getUTCFullYear();
    let month = date.getUTCMonth() + 1;
    let day = date.getUTCDate();
    return new Date(`${year}-${month}-${strDay(day)}T00:00:00Z`);
  };

  playerRank = (date, points) => {
    //   find the rank of that number of points on that day
    return (
      this.state.data.filter((player) => {
        let player_points = player.history.reduce((acc, cur) => {
          if (
            this.floorDate(cur.date) <= this.floorDate(date) &&
            cur.points > acc
          ) {
            return cur.points;
          }
          return acc;
        }, 0);
        return player_points > points;
      }).length + 1
    );
  };

  dateRange = (startDate) => {
    let dateRange = [this.floorDateZ(startDate)];
    while (dateRange[dateRange.length - 1] < this.floorDate(new Date())) {
      let curDate = dateRange[dateRange.length - 1];
      dateRange.push(new Date(curDate.valueOf() + 1000 * 3600 * 24));
    }
    return dateRange;
  };

  pointsChart = () => {
    let chartData = [];
    let minDate = this.floorDate(new Date());
    this.state.data
      .sort((player) => (player.selected ? -1 : 1))
      .forEach((player) => {
        minDate =
          this.floorDate(player.results[0].date) < minDate
            ? this.floorDate(player.results[0].date)
            : minDate;
        let playerColor = player.selected ? "#e8b923" : "#3d4f64";
        chartData.push({
          id: player._id,
          label: player.userID ? player.userID.username : player.name,
          fill: false,
          borderColor: playerColor,
          // backgroundColor: "#e755ba",
          pointBackgroundColor: playerColor,
          pointBorderColor: playerColor,
          pointHoverBackgroundColor: playerColor,
          pointHoverBorderColor: playerColor,
          lineTension: 0,
          // steppedLine: true,
          data: player.history.map((res) => {
            return { t: this.floorDate(res.date), y: res.points };
          }),
        });
      });
    this.setState({ minDate: minDate });
    chartData.forEach((player) => {
      // add data point on right most x-axis (today or 1 jan next year)
      let today = this.floorDate(new Date())
      if (player.data[player.data.length - 1].t.getFullYear() < today.getFullYear()) {
        today = new Date(player.data[player.data.length - 1].t.getFullYear(), 11, 25)
      }

      if (player.data[player.data.length - 1].t < this.floorDate(new Date())) {
        player.data.push({
          t: today,
          y: player.data[player.data.length - 1].y,
        });
      }
    });
    let ctx = document.getElementById("adtventPointsChart").getContext("2d");
    new Chart(ctx, {
      type: "line",
      data: {
        labels: [],
        datasets: chartData,
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                reverse: false,
                stepSize: 1,
                min: 0,
              },
              scaleLabel: {
                display: true,
                labelString: "Points",
              },
            },
          ],
          xAxes: [
            {
              type: "time",
              distribution: "linear",
              ticks: {
                min: minDate,
              },
              time: {
                tooltipFormat: "D MMM",
                unit: "day",
              },
            },
          ],
        },
      },
    });
  };

  rankChart = () => {
    let chartData = [];
    let dateArray = this.dateRange(this.state.minDate);
    this.state.data
      .sort((player) => (player.selected ? -1 : 1))
      .forEach((player) => {
        let playerColor = player.selected ? "#e8b923" : "#3d4f64";
        chartData.push({
          id: player._id,
          label: player.userID ? player.userID.username : player.name,
          fill: false,
          borderColor: playerColor,
          // backgroundColor: "#e755ba",
          pointBackgroundColor: playerColor,
          pointBorderColor: playerColor,
          pointHoverBackgroundColor: playerColor,
          pointHoverBorderColor: playerColor,
          lineTension: 0,
          // steppedLine: true,
          data: dateArray
            .filter((date) => date >= this.floorDate(player.results[0].date))
            .map((date) => {
              let points = player.history.reduce((acc, cur) => {
                if (this.floorDate(cur.date) <= date) {
                  return cur.points;
                }
                return acc;
              }, 0);
              return {
                t: this.floorDate(date),
                y: this.playerRank(date, points),
              };
            }),
        });
        if (player.selected) {
          this.setState({
            player: {
              ...this.state.player,
              rank:
                chartData[chartData.length - 1].data[
                  chartData[chartData.length - 1].data.length - 1
                ].y,
            },
          });
        }
      });
    let ctx = document.getElementById("adtventRankingChart").getContext("2d");
    new Chart(ctx, {
      type: "line",
      data: {
        labels: [],
        datasets: chartData,
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                reverse: true,
                stepSize: 1,
                min: 1,
              },
              scaleLabel: {
                display: true,
                labelString: "Ranking",
              },
            },
          ],
          xAxes: [
            {
              type: "time",
              distribution: "linear",
              ticks: {
                min: this.state.minDate,
                max: this.state.minDate.getFullYear() === new Date().getFullYear() ? new Date() : new Date(this.state.minDate.getFullYear(), 11, 25)
              },
              time: {
                tooltipFormat: "D MMM",
                unit: "day",
              },
            },
          ],
        },
      },
    });
  };

  componentDidMount() {
    this.fetchScores();
    this.getJokers();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="col-12 col-lg-6 pt-3">
          <div>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>
              <small>Fetching Scores</small>
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-6 col-sm-4 col-lg-3 col-xl-2">
            <img
              className="w-100 rounded-circle"
              alt="player avatar"
              src={this.state.player.avatar}
            />
          </div>
          <div className="col-6 col-sm-4 col-lg-3 overflow-hidden">
            <div className="mx-auto">
              <span className="h2 px-2 border-bottom">
                {this.state.player.userID ? (
                  <Link
                    to={"/member/" + this.state.player.userID.username}
                    className="text-dark"
                  >
                    {this.state.player.userID.username}
                  </Link>
                ) : (
                  this.state.player.name
                )}
              </span>
            </div>
            <div className="mx-auto" style={{ maxWidth: "100px" }}>
              <div className="d-flex justify-content-between">
                <span className="">Points</span>
                <span>{this.state.player.total}</span>
              </div>
            </div>
            <div className="mx-auto" style={{ maxWidth: "100px" }}>
              <div className="d-flex justify-content-between">
                <span className="">Rank</span>
                <span>{this.state.player.rank}</span>
              </div>
            </div>
            {/* <div className="mx-auto" style={{ maxWidth: "100px" }}>
              <div className="d-flex justify-content-between">
                <span className="">Jokers</span>
                <span>{this.state.jokers}</span>
              </div>
            </div>
            <div className="mx-auto" style={{ maxWidth: "100px" }}>
              <div className="d-flex justify-content-between">
                <span className="">Remaining</span>
                <span>
                  {typeof this.state.jokers == "string"
                    ? 0
                    : this.state.jokers -
                      this.state.player.results.filter((res) => res.joker)
                        .length}
                </span>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row">
          <canvas
            className="col-12 col-lg-6 my-2"
            id="adtventPointsChart"
            aria-label="Points over time chart"
            role="img"
          />
          <canvas
            className="col-12 col-lg-6 my-2"
            id="adtventRankingChart"
            aria-label="Rank over time chart"
            role="img"
          />
        </div>
        <div className="pt-2">
          <Link to="/adtvent">Back to calendar</Link>
        </div>
      </div>
    );
  }
}

export default PlayerPage;
