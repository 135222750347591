import React, { Component } from "react";
import Etappe from "./Etappe";
import data from "./archivedTourData";

class ArchivedTour extends Component {
  state = {};

  hourstring = (seconds) => {
    const sec = Math.round(seconds % 60);
    const hrs = Math.floor(seconds / 3600);
    const min = Math.floor((seconds - hrs * 3600) / 60);
    return `${hrs}:${("00" + min).slice(-2)}:${("00" + sec).slice(-2)}`;
  };

  timestring = (seconds) => {
    const bestTime = this.state.standings.general.reduce(
      (acc, cur) => (cur.time < acc ? cur.time : acc),
      Number.POSITIVE_INFINITY
    );
    const dif = seconds - bestTime;
    const min = Math.floor(dif / 60);
    const sec = Math.round(dif % 60);
    return `+ ${("00" + min).slice(-2)}:${("00" + sec).slice(-2)}`;
  };

  componentDidMount() {
    const tourData = data.filter((tour) => tour.link === this.props.tour);
    this.setState(tourData[0]);
  }

  render() {
    if (Object.keys(this.state).length === 0) {
      return <div>Invalid tour</div>;
    }
    return (
      <div className="container">
        <h1 className="display-3">{this.state.title}</h1>
        <h4 className="pb-3 border-bottom">{this.state.subtitle}</h4>
        <div className="row justify-content-center border-bottom">
          <div className="col-12 col-md-8 text-muted">
            <p>{this.state.header}</p>
          </div>
        </div>
        {this.state.standings ? (
          <div className="row justify-content-center py-3">
            <div className="col-12 col-lg-3">
              <h1 className="text-custom-gold">General Standings</h1>
              {this.state.standings.general.map((element, i) => {
                return (
                  <div
                    className="row justify-content-between px-2"
                    key={"general" + i}
                  >
                    <span>{element.name}</span>
                    {i === 0 ? (
                      <span>{this.hourstring(element.time)}</span>
                    ) : (
                      <span>{this.timestring(element.time)}</span>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="col-1 d-none d-md-flex" />
            <div className="col-12 col-lg-3">
              <h1 className="text-success">Sprint</h1>
              {this.state.standings.sprint
                .sort((a, b) => (a.points > b.points ? -1 : 1))
                .map((element, i) => {
                  return (
                    <div
                      className="row justify-content-between px-2"
                      key={"sprint" + i}
                    >
                      <span>{element.name}</span>
                      <span>{element.points}</span>
                    </div>
                  );
                })}
              <h1 className="text-danger">Mountains</h1>
              {this.state.standings.mountain
                .sort((a, b) => (a.points > b.points ? -1 : 1))
                .map((element, i) => {
                  return (
                    <div
                      className="row justify-content-between px-2"
                      key={"mountain" + i}
                    >
                      <span>{element.name}</span>
                      <span>{element.points}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <span className="invisible">No standings</span>
        )}
        <h1>Etappes</h1>
        {this.state.etappes.map((etappe, i) => (
          <Etappe data={etappe} key={"Etappe" + i} />
        ))}
      </div>
    );
  }
}

export default ArchivedTour;
