import React, { Component } from 'react';

class NormalAlert extends Component {
    state = {
        isOpen: true
    }

    dismiss = (e) => {
        e.preventDefault();
        this.setState({isOpen: false})
    }

    render () {
        if(this.state.isOpen){
            return (
                <div className={`alert alert-${this.props.type} d-flex justify-content-between`}>
                    <p className="mb-0">{this.props.msg}</p>
                    <span className="fa fa-times" onClick={this.dismiss}/>
                </div>
            )
        } else {
            return <div className="hidden"/>
        }
    }
}

class AutoCloseAlert extends Component {
    state = {
        isOpen: true
    }

    dismiss = (e) => {
        e.preventDefault();
        this.setState({isOpen: false})
    }

    componentDidMount () {
        // autoclose
        window.setTimeout(()=>{
            this.setState({isOpen:false})
          }, this.props.autoClose)
    }

    render () {
        if(this.state.isOpen){
            return (
                <div className={`alert alert-${this.props.type} d-flex justify-content-between`}>
                    <p className="mb-0">{this.props.msg}</p>
                    <span className="fa fa-times" onClick={this.dismiss}/>
                </div>
            )
        } else {
            return <div className="hidden"/>
        }
    }
}

class Alert extends Component {
    render () {
        if (this.props.alert.autoClose) {
            return <AutoCloseAlert autoClose={this.props.alert.autoClose} msg={this.props.alert.msg} type={this.props.alert.type}/>
        } else {
            return <NormalAlert msg={this.props.alert.msg} type={this.props.alert.type}/>
        }
    }
};

class Alerts extends Component {
    render () {
        return (
            <div className="col-10 offset-1 mb-0">
                {this.props.alerts.map((alert, i) => {
                    return <Alert alert={alert} key={i}/>
                })}
            </div>
        )
    }
};

export default Alerts;