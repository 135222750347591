import React, { Component } from "react";
import Cookies from "universal-cookie";

import Expanded from "./ExpandedDayCard";
import "./adtvent.css";

const cookies = new Cookies();

function setCookie(daySet) {
  // cookies.set("adtvent", daySet, {maxAge: 60}) // for testing
  cookies.set("adtvent", daySet, {maxAge: 3600 * 24 * 31})  // cookie expires in one month
}

function allow_open(day_num) {
  var day = ("0" + (day_num + 1)).slice(-2)
  var card_date = new Date("2023-12-" + day + "T00:00:00Z");
  var system_date = (new Date().valueOf()) + 3600 * 1000;
  return card_date <= system_date;
}

class FrontImage extends Component {
  render() {
    return (
      <div
        className=""
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          transitionDuration: "1s",
          transformOrigin: "left",
          transform: this.props.isOpen ? "rotateY(92deg)" : "rotateY(0deg)",
        }}
        onClick={this.props.open}
      >
        <img
          className={"w-100 hover-shadow"}
          alt={"Icon Day " + (this.props.day + 1)}
          src={require("../../images/adtvent/adtventDays/day" +
            ("0" + (this.props.day + 1)).slice(-2) +
            ".png")}
        />
      </div>
    );
  }
}

class Inside extends Component {
  state = {
    url: null,
  };

  get_image = (day) => {
    fetch("/adtvent/task/" + day + "/small", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.body;
      })
      .then((body) => {
        const reader = body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => this.setState({ url: url }))
      .catch((err) => console.error(err));
  };

  componentDidMount() {
    if (allow_open(this.props.day)) {
      this.get_image(this.props.day);
    }
  }

  toggleZoom = (e) => {
    e.preventDefault();
    this.setState({ zoom: !this.state.zoom });
  };

  render() {
    return (
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
        onClick={(e) => this.props.toggleExpand(e)}
      >
        <img
          className="w-100"
          alt={"task day" + this.props.day}
          src={this.state.url}
        />
      </div>
    );
  }
}

class DayCard extends Component {
  state = {
    isOpen: false,
    expanded: false,
  };

  open = (e) => {
    e.preventDefault();
    if (allow_open(this.props.day)) {
      let cookie = cookies.get("adtvent")
      if (!cookie) {
        cookie = []
      }
      cookie.push(this.props.day)
      let daySet = [...new Set(cookie)]
      setCookie(daySet)
      this.setState({ isOpen: true });
    }
  };

  toggleExpand = (e) => {
    e.preventDefault();
    if (this.state.isOpen) {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  close = (e) => {
    e.preventDefault();
    if (this.state.isOpen) {
      let cookie = cookies.get("adtvent")
      if (!cookie) {
        cookie = []
      }
      cookie = cookie.filter(d => d !== this.props.day)
      let daySet = [...new Set(cookie)]
      setCookie(daySet)
      this.setState({ isOpen: false, expanded: false });
    }
  }

  componentDidMount() {
    let cookie = cookies.get("adtvent")
    if (!cookie) {
      cookie = []
    }
    if (cookie.includes(this.props.day)) {
      this.setState({ isOpen: true });
    }
  }

  render() {
    return (
      <div className="" style={{ width: "20%" }}>
        <div
          className="w-100"
          style={{ position: "relative", paddingBottom: "100%" }}
        >
          {this.state.isOpen ? (
            <Inside day={this.props.day} toggleExpand={this.toggleExpand} />
          ) : (
            <div />
          )}
          {this.state.expanded ? (
            <Expanded day={this.props.day} place={this.props.place} toggleExpand={this.toggleExpand} close={this.close} />
          ) : (
            <div />
          )}
          <FrontImage
            isOpen={this.state.isOpen}
            open={this.open}
            day={this.props.day}
          />
        </div>
      </div>
    );
  }
}

export default DayCard;
