import React, { Component } from 'react';
import ResultDot from './ResultDot';

class HistoryBar extends Component {
    render () {
        if (this.props.matches.length > 0) {
            var sortedResults = this.props.matches.filter(match => match.accepted).sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1);
            var fiveMostRecent = sortedResults.slice(0,(Math.min(5, sortedResults.length)));
            
            return (
                <div className="justify-content-center mb-1">
                    {fiveMostRecent.map((match, i) => <ResultDot key={i} match={match} cardID={this.props.cardID}/>)}
                </div>
            )
        } else {
            return (
                <div className="justify-content-center mb-1">
                    <small className="text-muted">no results yet</small>
                </div>
            )
        }
    }
};


export default HistoryBar;