import React, { Component } from "react";
import { Link } from "react-router-dom";
import archiveTours from "./archivedTourData";

class TourDeBiere extends Component {
  render() {
    return (
      <div className="container">
        <h1 className="display-3">Tour de Biere</h1>
        <div className="row justify-content-center align-items-center border-bottom pb-2 mb-2">
          <img
            src={require("../../images/tourDeBiere.svg")}
            alt="Tour de Biere logo"
            className="col-8 col-md-4 rounded-circle"
          />
          <div className="col-12 col-md-8 text-muted pt-md-2">
            <p>
              The Tour de Biere is a cycling inspired event. Participants race
              over a set-out stage of different beers with to goal to finish
              first. However, along the way, points can be earned for the sprint
              and mountain classifications. A tour could consist of multiple
              stages, where the points and times are combined in a cumulative
              standing. Check out the scoring system here!
            </p>
            <Link to={"/tourdebiere/info"} className="mb-3">
              <big>Tour de Biere rules</big>
            </Link>
          </div>
        </div>
        <div className="row justify-content-center border-bottom">
          <p className="col-12 col-md-8 text-muted">
            Unfortunately, there currently is no Tour de Biere planned. But you
            can check out the archived tours below to get some stage examples.
          </p>
          <table className="table col-12 col-md-8">
            <thead>
              <tr>
                <th>Archived Tour</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {archiveTours.map((tour, i) => {
                return (
                  <tr key={i}>
                    <td>{tour.title}</td>
                    <td>{tour.date}</td>
                    <td className="align-middle">
                      <Link
                        to={`tourdebiere/archive/${tour.link}`}
                        className="btn btn-success"
                      >
                        Open
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TourDeBiere;
