import React, { Component } from 'react'
import LadterCard from './LadterCard';
import JoinCard from './JoinCard';

class LadterTier extends Component {
    render() {
        // In case of empty ladter
        if (this.props.data.length === 0){
            return (
                <div className="border-bottom">
                    <h1 className="text-left tierCaption">1</h1>
                    <div className="row justify-content-center px-3 px-sm-5">
                        {this.props.lastTier && (!this.props.userEntry) ? <JoinCard user={this.props.user} addCentralAlert={this.props.addCentralAlert} update={this.props.update}/> : <span/>}
                    </div>
                </div>
            )
        }

        let sortedTier = this.props.data.sort((a, b) => (a.lastActive > b.lastActive) ? -1 : 1);

        return (
            <div className="border-bottom">
                <h1 className="text-left tierCaption">{this.props.data[0].tier}</h1>
                <div className="row justify-content-center px-3 px-sm-5">
                    {sortedTier.map((cardData,i) => 
                            <LadterCard addScore={this.props.addScore} user={this.props.user} key={i} lastTier={this.props.lastTier} data={cardData} update={this.props.update}/>
                        )}
                    {this.props.lastTier && (!this.props.ladterPlayer) ? <JoinCard user={this.props.user} addCentralAlert={this.props.addCentralAlert} update={this.props.update}/> : <span/>}
                </div>
            </div>
        )};
};

export default LadterTier;