function colorPalette(i){
    const bootstrap5_colors_700 = [
        "#3d4f64", // custom bluegray
        // "#ac821d", // yellow
        "#e8b923", // custom gold
        "#952632", // red
        "#207132", // green
        "#186e7c", // cyan
        "#451aa2", // indigo
        "#ab561c", // orange
        "#1f8868", // teal
        "#114eaa", // blue
        "#91265a", // pink
        // "#495057", // gray
        "#4b3081", // purple
    ];
    return bootstrap5_colors_700[i % bootstrap5_colors_700.length]
}

export { colorPalette }