import React, { Component } from 'react';
import JoinForm from './JoinForm';

class JoinCard extends Component {
    state = {
        expand: false
    }

    toggle = (e) => {
        e.preventDefault();
        this.setState({expand: !this.state.expand})
    }

    expand = (e) => {
        e.preventDefault();
        this.setState({expand: true})
    }

    dismiss = (e) => {
        e.preventDefault();
        this.setState({expand: false})
    }

    render () {
        return (
            <div className="col-6 col-sm-4 col-md-3 px-2 py-2">
                <div className="card px-1 py-1">
                    <img className="card-img-top w-75 my-3 mx-auto" alt="user-plus-icon" src={require('../../images/user-plus-solid.svg')}></img>
                    <div className="card-body px-1 py-1">
                        <h5 className="card-title"><a className="text-dark text-decoration-none" href="/Ladter">Join</a></h5>
                        {!this.props.user ? <p className="text-muted">Log in to join the ladter competition</p> : <JoinForm addCentralAlert={this.props.addCentralAlert} update={this.props.update}/>}
                    </div>
                </div>
            </div>
        )};

};

export default JoinCard;