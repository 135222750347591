import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TourDeBiereInfo extends Component {
    render () {
        return (
            <div className="container pb-3">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8">
                        <h1>Tour de Biere rules</h1>
                        <p className="text-muted">
                            Participants of the Tour de Biere will compete in 3 different classifications like any great tour. 
                            One to see overall speed over the totality of the race, the others to gauge the specific qualities of sprinting and scaling the stronger challenges. 
                        </p>
                        <h4>General classification</h4>
                        <p className="text-muted">
                            This is just the total time spend on completing the stages. 
                            For the proloog this will be easy, but for the other stages make sure the officials do register you finish time. 
                            Write in the chat when you are done, so nothing gets missed. To prevent the tour officials to spend their entire night watching potential slower coureurs, there will be a maximum time allowed to complete the stage. 
                            This is 150% of the fastest time, with a minimum of half an hour after the first one has finished.
                        </p>
                        <h4>Sprint</h4>
                        <p className="text-muted">
                            For this classification, point can be earned on either the intermediate sprint, or a mass finish sprint. 
                            For the intermediate sprint, the competitors finishing the indicated beer in any of the rankings below will earn the assigned points. 
                            Similar for a mass sprint, but this will only apply when 50% (open for official judgement) of the peloton is also within one beers distance. 
                            Note that this does not apply for uphill finishes (last day).
                        </p>
                        <table className="table table-sm table-striped">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Intermediate</th>
                            <th scope="col">Mass-sprint</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th scope="row">1</th>
                            <td>40</td>
                            <td>150</td>
                            </tr>
                            <tr>
                            <th scope="row">2</th>
                            <td>25</td>
                            <td>90</td>
                            </tr>
                            <tr>
                            <th scope="row">3</th>
                            <td>18</td>
                            <td>50</td>
                            </tr>
                            <tr>
                            <th scope="row">4</th>
                            <td>12</td>
                            <td>30</td>
                            </tr>
                            <tr>
                            <th scope="row">5</th>
                            <td>7</td>
                            <td>20</td>
                            </tr>
                            <tr>
                            <th scope="row">6</th>
                            <td>4</td>
                            <td>12</td>
                            </tr>
                            <tr>
                            <th scope="row">7</th>
                            <td>2</td>
                            <td>7</td>
                            </tr>
                            <tr>
                            <th scope="row">8</th>
                            <td>1</td>
                            <td>4</td>
                            </tr>
                            <tr>
                            <th scope="row">9</th>
                            <td>-</td>
                            <td>2</td>
                            </tr>
                            <tr>
                            <th scope="row">10</th>
                            <td>-</td>
                            <td>1</td>
                            </tr>
                        </tbody>
                        </table>
                        <h4>Mountain</h4>
                        <p className="text-muted">
                            The points in this classification are depended on the toughness of the col. 
                            Competitors who rank according to the table below when finishing the indicated beer earn the assigned points.
                            HC, hors category is the quadrupel at the finish of the third etappe.
                        </p>
                        <table className="table table-sm table-striped">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Dubbel</th>
                            <th scope="col">Tripel</th>
                            <th scope="col">Quadrupel</th>
                            <th scope="col">HC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th scope="row">1</th>
                            <td>25</td>
                            <td>50</td>
                            <td>100</td>
                            <td>150</td>
                            </tr>
                            <tr>
                            <th scope="row">2</th>
                            <td>15</td>
                            <td>30</td>
                            <td>60</td>
                            <td>90</td>
                            </tr>
                            <tr>
                            <th scope="row">3</th>
                            <td>10</td>
                            <td>20</td>
                            <td>35</td>
                            <td>50</td>
                            </tr>
                            <tr>
                            <th scope="row">4</th>
                            <td>8</td>
                            <td>16</td>
                            <td>25</td>
                            <td>30</td>
                            </tr>
                            <tr>
                            <th scope="row">5</th>
                            <td>6</td>
                            <td>12</td>
                            <td>16</td>
                            <td>20</td>
                            </tr>
                            <tr>
                            <th scope="row">6</th>
                            <td>4</td>
                            <td>8</td>
                            <td>10</td>
                            <td>12</td>
                            </tr>
                            <tr>
                            <th scope="row">7</th>
                            <td>2</td>
                            <td>3</td>
                            <td>5</td>
                            <td>7</td>
                            </tr>
                            <tr>
                            <th scope="row">8</th>
                            <td>1</td>
                            <td>1</td>
                            <td>2</td>
                            <td>4</td>
                            </tr>
                            <tr>
                            <th scope="row">9</th>
                            <td>-</td>
                            <td>-</td>
                            <td>1</td>
                            <td>2</td>
                            </tr>
                            <tr>
                            <th scope="row">10</th>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>1</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
                <Link to={'/tourdebiere'}>Back</Link>
            </div>
        )
    }
};

export default TourDeBiereInfo;