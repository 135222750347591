import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getStandartAvatar, bindAvatar } from "../User/bindAvatar";
import "./adtvent.css";

class RankingEntry extends Component {
  render() {
    return (
      <div className="container-fluid">
        <Link
          className="row py-1 text-decoration-none text-dark"
          to={`/adtvent/player/${this.props.player._id}`}
        >
          <div className="col-3 col-sm-2 col-md-3">
            <img
              className="w-100 rounded-circle"
              alt="player avatar"
              src={this.props.player.avatar}
            />
          </div>
          <div className="col-8 col-sm-9 col-md-8 px-1 py-1 border-0 text-left">
            {this.props.player.userID
              ? this.props.player.userID.username
              : this.props.player.name}
          </div>
          <div className="col-1 px-1 py-1 border-0 text-left">
            {this.props.player.total}
          </div>
        </Link>
      </div>
    );
  }
}

class RankingRow extends Component {
  render() {
    return (
      <div className="row border-top">
        <h1 className="col-1 col-sm-2 pl-0 mb-0 text-left adtventScoreRow">
          {this.props.data.rank}
        </h1>
        <div className="col-11 col-sm-10 px-0 table-responsive">
          <table className="table table-hover mb-0">
            <tbody className="">
              {this.props.data.players.map((player) => (
                <RankingEntry key={player._id} player={player} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

class AdtventScores extends Component {
  state = {
    loading: true,
    data: null,
    ranking: null,
  };

  avatar = (player) => {
    if (player.userID) {
      return bindAvatar(player.userID.avatar);
    }
    return getStandartAvatar(player.name);
  };

  componentDidMount() {
    fetch("/adtvent/scores", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        data.forEach((element) => {
          element.avatar = this.avatar(element);
          element.total = element.results.reduce(
            (acc, cur) => cur.points + acc,
            0
          );
        });
        var ranking = data
          .sort((a, b) => (a.total > b.total ? -1 : 1))
          .reduce((acc, cur) => {
            if (acc.length === 0) {
              return [{ players: [cur], rank: 1 }];
            }
            if (cur.total === acc[acc.length - 1].players[0].total) {
              acc[acc.length - 1].players.push(cur);
              return acc;
            }
            acc.push({
              players: [cur],
              rank:
                acc[acc.length - 1].players.length + acc[acc.length - 1].rank,
            });
            return acc;
          }, []);
        this.setState({
          data: data,
          ranking: ranking,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="col-12 col-lg-6 pt-3">
          <div>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>
              <small>Fetching Scores</small>
            </p>
          </div>
        </div>
      );
    } else if (this.state.data) {
      return (
        <div className="col-12 col-lg-6">
          <div>
            <img
              className="w-100"
              alt="Scores top"
              src={require("../../images/adtvent/ScoresTop.png")}
            />
          </div>
          <div className="container-fluid calendar-back">
            {this.state.ranking.map((rank, i) => (
              <RankingRow data={rank} key={i} />
            ))}
          </div>
          <div>
            <img
              className="w-100"
              alt="Scores bottom"
              src={require("../../images/adtvent/CalendarBottom.png")}
            />
          </div>
        </div>
      );
    }
    return <div></div>;
  }
}

export default AdtventScores;
