import React, { Component } from 'react';
import { Button, UncontrolledAlert } from 'reactstrap';

class Register extends Component {
    state ={
        username: null,
        email: null,
        password: null,
        password2: null,
        alerts: [],
    }

    addAlert = (newAlert) => {
        this.setState(state => {
            const alerts = [...state.alerts, newAlert];
            return { alerts };
        });
    }

    clearAlerts = () => {
        this.setState({ alerts: [] });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.clearAlerts();
        if ( this.state.password !== this.state.password2 ) {
            this.addAlert({id: this.state.alerts.length, color: "danger", msg: "Passwords do not match"})
            return;
        }

        if ( this.state.username.length < 4 ) {
            this.addAlert({id: this.state.alerts.length, color: "danger", msg: "Username has minimum length 4"})
            return;
        }

        if ( this.state.password.length < 6 ) {
            this.addAlert({id: this.state.alerts.length, color: "danger", msg: "Password has minimum length 6"})
            return;
        }

        fetch('/user/registration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state)
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.error) {
                return this.addAlert({id: this.state.alerts.length, color: "danger", msg: data.error.message})
            }
            this.props.setuser(data.user)
            this.props.exitModal()
        })
        .catch((err) => {
            console.error(err);
        })
    }

    render () {
        return (
            <div className="rounded bg-white px-3 py-3">
                <form onSubmit={this.handleSubmit}>
                    <img className="w-75 rounded-circle d-block mx-auto mb-3" alt="Beer clipboard" src={require('../../images/Inlog_registreren.png')}></img>
                    <div className="form-group">
                        <label className="col-form-label" htmlFor="username">Username</label>
                        <input className="form-control" type="text" id="username" onChange={e => this.setState({username: e.target.value})} placeholder="username"></input>
                    </div>
                    <div className="form-group">
                        <label className="col-form-label" htmlFor="email">Email</label>
                        <input className="form-control" type="email" id="email" onChange={e => this.setState({email: e.target.value})} placeholder="username@example.com"></input>
                    </div>
                    <div className="form-group">
                        <label className="col-form-label" htmlFor="password">Password</label>
                        <input className="form-control" type="password" id="password" onChange={e => this.setState({password: e.target.value})} placeholder="adtKoning(in)#1"></input>
                    </div>
                    <div className="form-group">
                        <label className="col-form-label" htmlFor="verifyPassword">Confirm password</label>
                        <input className="form-control" type="password" id="verifyPassword" onChange={e => this.setState({password2: e.target.value})} placeholder="adtKoning(in)#1"></input>
                    </div>
                    {this.state.alerts.map((alert) => {
                        return <UncontrolledAlert key={alert.id} color={alert.color} >{alert.msg}</UncontrolledAlert>
                    })}
                    <div className="text-right">
                        <Button className="mr-3" color="secondary" onClick={this.props.exitModal}>Cancel</Button>
                        <Button color="primary" type="submit">Register</Button>
                    </div>
                </form>
            </div>
        );
    }
 };

 export default Register;