import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdtminLink from "../User/AdtminLink";
import { getStandartAvatar, bindAvatar } from "../User/bindAvatar";
import "./spiesranking.css";

const dtf = new Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "short",
  day: "2-digit",
});

class RankingEntry extends Component {
  render() {
    return (
      <tr className="d-flex align-items-center">
        <th className="col-1 border-0 px-0 py-0 rankingRow" scope="row">
          {this.props.player.rank}
        </th>
        <td className="col-2 border-0 text-left">
          {this.props.player.best.time.toFixed(2)}
        </td>
        <td className="col-2 border-0 px-0 py-1">
          <img
            className="w-100 rounded-circle"
            alt="player avatar"
            src={this.props.player.avatar}
          />
        </td>
        <td className="col-4 border-0 text-left text-truncate">
          <Link
            className="text-dark text-decoration-none"
            to={`/spiesranking/player/${this.props.player._id}`}
          >
            {this.props.player.user.username
              ? this.props.player.user.username
              : this.props.player.name}
          </Link>
        </td>
        <td className="col-3 border-0  text-right">
          {dtf.format(new Date(this.props.player.best.date))}
        </td>
      </tr>
    );
  }
}

class SpiesRanking extends Component {
  state = {
    loading: true,
    selection: "all",
  };

  avatar = (player) => {
    if (player.user) {
      return bindAvatar(player.user.avatar);
    }
    return getStandartAvatar(player.name);
  };

  fetchResults = (interval) => {
    fetch("/spies/results/" + interval, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        data.forEach((element, i) => {
          element.avatar = this.avatar(element);
          element.rank = i + 1;
        });
        this.setState({ data: data, loading: false });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.fetchResults("all");
  }

  handleChange = (e) => {
    this.fetchResults(e.target.value);
    this.setState({ selection: e.target.value });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 pt-3">
            <div>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>
                <small>Fetching data</small>
              </p>
            </div>
          </div>
        </div>
      );
    } else if (this.state.data) {
      return (
        <div className="container">
          <h1 className="display-2">Spies Ranking</h1>
          <div className="row justify-content-center">
            <div className="d-flex col-12 col-lg-8 justify-content-between align-items-stretch mb-2">
              <div
                className="btn-group btn-group-toggle"
                onChange={this.handleChange}
              >
                <label
                  className={`btn ${
                    this.state.selection === "all"
                      ? "bg-custom-gold"
                      : "bg-custom-blue text-light"
                  }`}
                >
                  <input type="radio" name="options" id="all" value="all" />{" "}
                  All-Time
                </label>
                <label
                  className={`btn ${
                    this.state.selection === "year"
                      ? "bg-custom-gold"
                      : "bg-custom-blue text-light"
                  }`}
                >
                  <input type="radio" name="options" id="year" value="year" />{" "}
                  Last Year
                </label>
                <label
                  className={`btn ${
                    this.state.selection === "month"
                      ? "bg-custom-gold"
                      : "bg-custom-blue text-light"
                  }`}
                >
                  <input type="radio" name="options" id="month" value="month" />{" "}
                  Last Month
                </label>
              </div>
              <div className="btn-group">
                <Link to="/spiesranking/info">
                  <span className="btn bg-custom-blue text-custom-gold fa fa-info fa-5x text-decoration-none stretched-link h-100" />
                </Link>
              </div>
            </div>
            <table className="table table-striped table-hover col-12 col-lg-8">
              <thead>
                <tr className="d-flex">
                  <th className="col-1" scope="col">
                    #
                  </th>
                  <th className="col-2 text-left" scope="col">
                    Time (sec)
                  </th>
                  <th className="col-2" scope="col"></th>
                  <th className="col-4 text-left" scope="col">
                    Name
                  </th>
                  <th className="col-3" scope="col">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map((player, i) => {
                  return <RankingEntry player={player} key={i} />;
                })}
              </tbody>
            </table>
          </div>

          <AdtminLink to="/spiesranking/adtmin" user={this.props.user} />
        </div>
      );
    }
    return (
      <div>
        <h3>No records yet</h3>
        <AdtminLink to="/spiesranking/adtmin" user={this.props.user} />
      </div>
    );
  }
}

export default SpiesRanking;
