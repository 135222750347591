import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchUser from "../User/SearchUser";
import Alerts from "../Alerts/Alerts";
import { getStandartAvatar, bindAvatar } from "../User/bindAvatar";
import Record from "./AdtminRecord";

function formatDate(d) {
  return (
    d.getUTCFullYear() +
    "-" +
    ("0" + (d.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getUTCDate()).slice(-2)
  );
}

function avatar(player) {
  if (player.user.username) {
    return bindAvatar(player.user.avatar);
  }
  return getStandartAvatar(player.name);
}

class NewResult extends Component {
  state = {
    submitting: false,
    record: { date: formatDate(new Date()), time: 0 },
    player: { name: "" },
  };

  dismiss = (e) => {
    e.preventDefault();
    this.setState({ err: null });
  };

  updateState = (obj) => {
    this.setState({ player: obj });
  };

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      record: { ...prevState.record, [e.target.name]: e.target.value },
    }));
  };

  submitResult = (e) => {
    e.preventDefault();
    this.props.clearAlerts();
    const result = { ...this.state.player, result: this.state.record };
    if (result.name === "") {
      return this.props.addAlert({
        type: "danger",
        msg: "Please insert player name",
      });
    }
    if (result.time === null) {
      return this.props.addAlert({
        type: "danger",
        msg: "Please insert time",
      });
    }
    if (result.result.time <= 0) {
      return this.props.addAlert({
        type: "danger",
        msg: "Inserted time impossible",
      });
    }
    this.setState({ submitting: true, err: null });
    fetch("/spies/result", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(result),
    })
      .then((res) => {
        if (res.status === 201) {
          this.props.addAlert({
            type: "success",
            msg: "Result posted",
            autoClose: 2000,
          });
          this.setState({
            record: { date: formatDate(new Date()), time: 0 },
            player: { name: "" },
            submitting: false,
          });
          let reloadInt = setInterval(() => {
            window.location.reload(false); // no super neat solution
            clearInterval(reloadInt);
          }, 2000);
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        this.props.addAlert({ type: "danger", msg: err.errorMsg });
        this.setState({ submitting: false });
        console.log(err);
      });
  };

  render() {
    if (this.state.submitting) {
      return (
        <div className="col-12 col-lg-6 pt-3">
          <div>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>
              <small>Submitting Result</small>
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="row mb-2 justify-content-center">
        <form className="form-inline justify-content-center">
          <label className="sr-only">Time</label>
          <div>
            <input
              type="number"
              name="time"
              className="form-control mb-2 mr-sm-2"
              value={this.state.record.time}
              onChange={this.handleChange}
            />
          </div>

          <label className="sr-only">Username</label>
          <div className="position-relative">
            <SearchUser
              className="form-control mb-2 mr-sm-2"
              id="newPlayer"
              placeholder="Player"
              setState={this.updateState}
              submit={this.submitResult}
              state={this.state.player}
            />
          </div>

          <label className="sr-only">Date</label>
          <div>
            <input
              type="date"
              name="date"
              className="form-control mb-2 mr-sm-2"
              value={this.state.record.date}
              onChange={this.handleChange}
            />
          </div>

          <div className="btn-group  mb-2" role="group">
            <button
              type="button"
              className="btn btn-outline-success"
              onClick={this.submitResult}
            >
              Add
            </button>
          </div>
        </form>
      </div>
    );
  }
}

class LastFive extends Component {
  state = {
    loading: true,
    data: null,
  };

  fetchLastFive = () => {
    fetch("/spies/last5", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        data.forEach((element, i) => {
          element.avatar = avatar(element);
          element.row = i + 1;
        });
        this.setState({
          data: data,
          loading: false,
        });
      })
      .catch((err) => {
        this.props.addAlert({ type: "danger", msg: err.errorMsg });
        this.setState({ loading: false });
        console.log(err);
      });
  };

  componentDidMount() {
    this.fetchLastFive();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 pt-3">
            <div>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>
                <small>Loading Latest Records</small>
              </p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="row mb-2 justify-content-center">
        <div>
          <h3 className="border-bottom">Last 5 Results</h3>
          <div>
            {this.state.data.map((record) => (
              <Record
                record={record}
                key={record.row}
                addAlert={this.props.addAlert}
                clearAlerts={this.props.clearAlerts}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

class Player extends Component {
  state = { expanded: false };

  deletePlayer = (e) => {
    e.preventDefault();
    fetch("/spies/player", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ playerID: this.props.player._id }),
    })
      .then((res) => {
        if (res.status === 204) {
          this.props.addAlert({
            type: "success",
            msg: "Player removed",
            autoClose: 2000,
          });
          let reloadInt = setInterval(() => {
            window.location.reload(false); // no super neat solution
            clearInterval(reloadInt);
          }, 2000);
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        this.props.addAlert({ type: "danger", msg: err.errorMsg });
        this.setState({ submitting: false });
        console.log(err);
      });
  };

  render() {
    return (
      <tr className="d-flex align-items-center">
        <td className="col-2 border-0">
          <img
            className="w-100 rounded-circle"
            alt="player avatar"
            src={this.props.player.avatar}
          />
        </td>
        <td className="col-4 col-sm-6 border-0 text-left text-truncate">
          <Link
            className="text-dark text-decoration-none"
            to={`/spiesranking/adtmin/player/${this.props.player._id}`}
          >
            {this.props.player.user.username
              ? this.props.player.user.username
              : this.props.player.name}
          </Link>
        </td>
        <td className="col-2 border-0">{this.props.player.records}</td>
        <td className="col-4 col-sm-2 border-0">
          {this.props.player.records === 0 ? (
            <button
              className="btn btn-outline-danger"
              onClick={this.deletePlayer}
            >
              Remove
            </button>
          ) : (
            <span className="hidden" />
          )}
        </td>
      </tr>
    );
  }
}

class AllPlayers extends Component {
  state = {
    loading: false,
    data: [],
  };

  fetchPlayers = () => {
    fetch("/spies/allPlayers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        data.forEach((element, i) => {
          element.avatar = avatar(element);
          element.row = i + 1;
        });
        this.setState({
          data: data,
          loading: false,
        });
      })
      .catch((err) => {
        this.props.addAlert({ type: "danger", msg: err.errorMsg });
        this.setState({ loading: false });
        console.log(err);
      });
  };

  componentDidMount() {
    this.fetchPlayers();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 pt-3">
            <div>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>
                <small>Loading All Players</small>
              </p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="row mb-2 justify-content-center">
        <h3 className="col-12">All Players</h3>
        <table className="table table-striped table-hover col-12 col-lg-8">
          <thead>
            <tr className="d-flex">
              <th className="col-2" scope="col">
                Avatar
              </th>
              <th className="col-4 col-sm-6 text-left" scope="col">
                Name
              </th>
              <th className="col-2 text-truncate" scope="col">
                # Records
              </th>
              <th className="col-4 col-sm-2" scope="col">
                Remove
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.data
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((player) => (
                <Player
                  player={player}
                  key={player._id}
                  addAlert={this.props.addAlert}
                  clearAlerts={this.props.clearAlerts}
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}

class Adtmin extends Component {
  state = {
    alerts: [],
  };

  addAlert = (alert) => {
    this.setState({ alerts: [...this.state.alerts, alert] });
    console.log(this.state);
  };

  clearAlerts = () => {
    this.setState({ alerts: [] });
  };

  render() {
    return (
      <div className="container">
        <h3>Add result</h3>
        <Alerts alerts={this.state.alerts} />
        <NewResult addAlert={this.addAlert} clearAlerts={this.clearAlerts} />
        <LastFive addAlert={this.addAlert} clearAlerts={this.clearAlerts} />
        <AllPlayers addAlert={this.addAlert} clearAlerts={this.clearAlerts} />
        <Link to="/spiesranking">Back to ranking</Link>
      </div>
    );
  }
}

export default Adtmin;
