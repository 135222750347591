import React, { Component } from 'react';

class LadterInfo extends Component {
    render() {
        return (
            <div className="row pb-5 justify-content-center">
                <div className="col-12 col-sm-11 col-md-10 col-lg-8">
                    <h3 className="text-center display-4">Ladter Competition</h3>
                    <big>
                        <p>
                            The ladter competition is a never ending event where you can climb the rankings by challenging the other competitors. 
                        </p>
                    </big>
                    <small className="text-left px-3">
                        <h3 className="text-center">Joining</h3>
                        <p>
                            In order to join, you first have to create a spa-goud account. 
                            Don't worry, the email address you enter here is only used for password recovery and none of you information is given to 3rd parties, we don't even need to know your real name...
                        </p>
                        <p>
                            However, do make sure others know your username, since they have to be able to contact you when they want to challenge you.
                            When you are logged in to your account, you can now add yourself to the ladter on the bottom tier by clicking the join button.
                        </p>
                        <h3 className="text-center">Challenging</h3>
                        <p>
                            Once you joined the ladter, you can challenge anyone in the tier above you.
                            The idea is that if you run into someone at the bar, you can decide to play a match. 
                            But you can of course also contact someone yourself to setup a place and date.
                        </p>
                        <p>
                            The match setup has to be decided between to two of you, so you can do a single adt game, dalton or whatever. 
                            When you post the results however you have to enter a score between 0 and 9 for the both of you, and no ties can be submitted.
                        </p>
                        <h3 className="text-center">Post a result</h3>
                        <p>
                            As soon as your match is finished, you should post the result.
                            Any of the players can login to the site and submit the score by clicking the add score dropdown on the other players ladter card.
                            If the losing party post the score, this result is immediately processed.
                            When the winner submits it, the loser has the verify the result by also logging in to the site and clicking the accept on the popup on top of the ladter page.                            
                        </p>
                        <p>
                            This verification is in place to prevent scores being submitted while no actual match is played.
                            When a match in unverified for 24 hours, the result will expire and nothing changes.
                            During this time however non of the parties involve can submit another score, since their position on the ladter might have changed.
                        </p>
                        <h3 className="text-center">Moving up</h3>
                        <p>
                            The goal of the ladter is to reach tier 1 and stay there as long as possible.
                            To get on top, you have to challenge people one tier above you.
                            If you win, you move to their tier and they drop one.
                            When you lose, you keep your tier, but this can still be useful as explained in next section.
                        </p>
                        <h3 className="text-center">Time-based demotion</h3>
                        <p>
                            A big downside to any ladder tournament is that people might be inactive. 
                            If they do not accept any challenges, others can not move past them.
                            Therefore the Ladter competition works with automatic time-based demotions. 
                        </p>
                        <p>
                            If you are in the top tier, it is expected that you atleast play a match once every 2 weeks.
                            For all subsequent tiers, this limit will be 3, 4, 5 etc. weeks, just the (tier number + 1) in weeks. 
                            The time until automatic demotion is displayed on your card in the ladter.
                            The bottom tier can obviously not degrade further, so people will stay in there indefinitely.
                        </p>
                        <p>
                            Due to this system, it could already payoff to be active.
                            If someone demotes due to the passing of time, the most recently active player on the next tier will move up, but only if his/her last match was more recent than the one demoting.
                            So getting challenged by someone on a lower tier is the perfect opportunity to move to the front of your tier!
                        </p>
                        <h3 className="text-center">Rewards</h3>
                        <p>
                            Besides the satisfaction attained by beating others, you can also earn new avatars by reaching various ladter related milestones.
                            These you can then select so that everyone else can marvel at your accomplishments.
                        </p>
                        <h6 className="text-center">Small print</h6>
                        <p className="text-muted">
                            This ladter competition is a side project of some people with too much time on their hands. 
                            It could therefore have some bugs or shortcomings.
                            If the rules need to be changed to better balance the game, this may be done without consulting any of the participants.
                            When you feel like things are wrong or missing, you could contact Sven Keukenboer, and he might listen to your requests...
                        </p>
                    </small>
                    <a className="text-decoration-none" href="/Ladter">Back</a>
                </div>
            </div>
        )};
};

export default LadterInfo;