import React, { Component } from "react";
import SearchUser from "../User/SearchUser";

function formatDate(d) {
  return (
    d.getUTCFullYear() +
    "-" +
    ("0" + (d.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getUTCDate()).slice(-2)
  );
}

class Record extends Component {
  state = {
    player: { name: "" },
    record: {},
  };

  updateState = (obj) => {
    this.setState({ player: obj });
  };

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      record: { ...prevState.record, [e.target.name]: e.target.value },
    }));
  };

  removeRecord = (e) => {
    e.preventDefault();
    this.props.clearAlerts();
    const result = {
      ...this.state.player,
      result: {
        _id: this.state.record._id,
        time: this.state.record.time,
        date: this.state.record.date,
      },
      playerID: this.props.record.playerID,
    };
    fetch("/spies/result", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(result),
    })
      .then((res) => {
        if (res.status === 204) {
          this.props.addAlert({
            type: "success",
            msg: "Result removed",
            autoClose: 2000,
          });
          let reloadInt = setInterval(() => {
            window.location.reload(false); // no super neat solution
            clearInterval(reloadInt);
          }, 2000);
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        this.props.addAlert({ type: "danger", msg: err.errorMsg });
        this.setState({ submitting: false });
        console.log(err);
      });
  };

  putRecord = (e) => {
    e.preventDefault();
    this.props.clearAlerts();
    const result = {
      ...this.state.player,
      result: {
        _id: this.state.record._id,
        time: this.state.record.time,
        date: this.state.record.date,
      },
      playerID: this.props.record.playerID,
    };
    if (result.name === "") {
      return this.props.addAlert({
        type: "danger",
        msg: "Please insert player name",
      });
    }
    if (result.time === null) {
      return this.props.addAlert({
        type: "danger",
        msg: "Please insert time",
      });
    }
    if (result.result.time <= 0) {
      return this.props.addAlert({
        type: "danger",
        msg: "Inserted time impossible",
      });
    }
    fetch("/spies/result", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(result),
    })
      .then((res) => {
        if (res.status === 204) {
          this.props.addAlert({
            type: "success",
            msg: "Result updated",
            autoClose: 2000,
          });
          let reloadInt = setInterval(() => {
            window.location.reload(false); // no super neat solution
            clearInterval(reloadInt);
          }, 2000);
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        this.props.addAlert({ type: "danger", msg: err.errorMsg });
        this.setState({ submitting: false });
        console.log(err);
      });
  };

  componentDidMount() {
    var d = new Date(this.props.record.date);
    var d_string = formatDate(d);
    this.setState({
      player: { name: this.props.record.name, userID: this.props.record.user._id },
      record: { ...this.props.record, date: d_string },
    });
  }

  render() {
    return (
      <form className="form-inline justify-content-center border-bottom mb-2">
        <label className="sr-only">Time</label>
        <div>
          <input
            type="number"
            name="time"
            className="form-control mb-2 mr-sm-2"
            value={this.state.record.time}
            onChange={this.handleChange}
          />
        </div>

        <label className="sr-only">Username</label>
        <div className="position-relative">
          <SearchUser
            className="form-control mb-2 mr-sm-2"
            id="newPlayer"
            placeholder="Player"
            setState={this.updateState}
            submit={this.submitResult}
            state={this.state.player}
          />
        </div>

        <label className="sr-only">Date</label>
        <div>
          <input
            type="date"
            name="date"
            className="form-control mb-2 mr-sm-2"
            value={this.state.record.date}
            onChange={this.handleChange}
          />
        </div>

        <div className="btn-group  mb-2" role="group">
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={this.removeRecord}
          >
            Remove
          </button>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={this.putRecord}
          >
            Update
          </button>
        </div>
      </form>
    );
  }
}

export default Record;
