import React, { Component } from 'react';
import LadterTier from './LadterTier';
import LadterTop from './LadterTop';
// import dummyData from './dummyLadterData';
import { bindAvatar } from '../User/bindAvatar';
// const placeHolderAvatar = require('../../images/beerBackground.png');

class Ladter extends Component {
    state = {
        data: [],
        errMsg: null,
        loading: true,
        ladterPlayer: null,
    }

    sortInTiers = (data) => {
        data.sort((a, b) => a.tier < b.tier ? -1 : 1)
        return data.reduce((acc, cur) => {
            try {
                if (cur.tier === acc[acc.length - 1][0].tier) {
                    acc[acc.length - 1].push(cur)
                } else {
                    acc.push([cur])
                }
                return acc;
            } catch {
                return [[cur]]
            }
        }, []);
    }

    addScore = (tier, ladterPlayer) => {
        if (ladterPlayer) {
            return Math.abs(ladterPlayer.tier - tier) === 1 ? true : false;
        }
        return false;
    }

    fetchLadter = () => {
        fetch('/ladter/currentStatus', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error(res.statusText)
                }
                return res.json()
            })
            .then(data => {
                data.forEach(element => {
                    element.avatar = bindAvatar(element.avatar);
                });
                let ladterPlayer = data.find(entry => entry.user.username === this.props.user);
                this.setState({
                    data: data,
                    loading: false,
                    ladterPlayer: ladterPlayer,
                })
            })
            .catch(err => {
                console.error(err)
                this.setState({ loading: false })
                this.setState({
                    errMsg: {
                        msg: err.message,
                        type: "danger"
                    }
                })
            })
    }

    componentDidMount() {
        this.fetchLadter();
    }

    componentDidUpdate(prev) {
        if (prev.user !== this.props.user) {
            this.fetchLadter();
        }
    }

    render() {
        // when page is first loaded and data is being fetched
        if (this.state.loading) {
            return (
                <div className="">
                    <div>
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p><small>Fetching Ladter</small></p>
                    </div>
                </div>
            )
        }

        // In case of empty ladter
        if (this.state.data.length === 0) {
            if (this.state.errMsg) {
                return <div>
                    <LadterTop user={this.state.ladterPlayer} update={this.fetchLadter} />
                    <div>{this.state.errMsg.msg}: Failed to load ladter</div>
                </div>
            }
            return (
                <div>
                    <LadterTop user={this.state.ladterPlayer} update={this.fetchLadter} />
                    <LadterTier lastTier={true} user={this.props.user} data={[]} update={this.fetchLadter} />
                </div>
            )
        }

        let tieredData = this.sortInTiers(this.state.data);
        return (
            <div>
                <LadterTop user={this.state.ladterPlayer} update={this.fetchLadter} />
                {tieredData.map((tierData, i) =>
                    <LadterTier addScore={this.addScore(i + 1, this.state.ladterPlayer)} lastTier={i === tieredData.length - 1} user={this.props.user} ladterPlayer={this.state.ladterPlayer} key={i} data={tierData} update={this.fetchLadter} />
                )}
            </div>
        )
    };
};

export default Ladter;