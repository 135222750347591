import React, { Component } from "react";

class SearchUser extends Component {
  state = {
    suggestions: [],
  };

  inputChange = (e) => {
    e.preventDefault();
    this.props.setState({ name: e.target.value });
    if (e.target.value.length > 2) {
      fetch("/user/findUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchText: this.props.state.name,
        }),
      })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error(res.statusText);
          }
          return res.json();
        })
        .then((data) => {
          this.setState({ suggestions: data.users });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.setState({ suggestions: [] });
    }
  };

  suggestionClick = (e, user) => {
    e.preventDefault();
    this.props.setState({ name: user.username, userID: user._id });
    this.setState({ suggestions: [] });
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
        // enter
        if (this.props.submit) {
            this.props.submit(e);
        }
        this.setState({suggestions: []})
    }
    if (e.keyCode === 27) {
        // escape
        this.setState({suggestions: []})
    }
  };

  render() {
    return (
      //   <div className={`${this.props.className} px-0`}>
      <React.Fragment>
        <input
          className={`form-control ${this.props.className}`}
          type="text"
          id={this.props.id}
          placeholder={this.props.placeholder}
          onKeyDown={this.onKeyDown}
          onChange={this.inputChange}
          value={this.props.state.name}
        ></input>
        {this.state.suggestions.length > 0 ? (
          <ul
            className="position-absolute btn-group-vertical px-0 w-100 d-flex justify-content-start border rounded"
            style={{ top: "2.5em" }}
          >
            {this.state.suggestions.map((user) => {
              return (
                <li
                  className="btn btn-light border-0 text-left"
                  key={user._id}
                  style={{ zIndex: 1060 }}
                  onClick={(e) => this.suggestionClick(e, user)}
                >
                  {user.username}
                </li>
              );
            })}
          </ul>
        ) : (
          <span />
        )}
      </React.Fragment>
      // </div>
    );
  }
}

export default SearchUser;
