import React, { Component } from "react";
import {
  BarChart,
  TimePerBeerChart,
  RankPerBeerChart,
  TimeDifPerBeerChart,
} from "./Charts";

export class ChartCarousel extends Component {
  state = {
    position: 0,
  };

  nextChart = (e) => {
    e.preventDefault();
    this.setState({
      position: (this.state.position + 1) % 4,
    });
  };

  prevChart = (e) => {
    e.preventDefault();
    this.setState({
      position: (this.state.position + 3) % 4,
    });
  };

  goTo = (e, pos) => {
    e.preventDefault();
    this.setState({ position: pos });
  };

  switchChart = (pos) => {
    switch (pos) {
      case 0:
        return <BarChart data={this.props.data} />;
      case 1:
        return <TimePerBeerChart data={this.props.data} />;
      case 2:
        return <RankPerBeerChart data={this.props.data} />;
      case 3:
        return <TimeDifPerBeerChart data={this.props.data} />;
      default:
        return <div className="text-danger">Switch error</div>;
    }
  };

  render() {
    return (
      <div className="w-100">
        <div
          className="pos-abs row w-100 justify-content-between"
          style={{ height: "40vh", minHeight: "300px" }}
        >
          <div
            className="col-3 px-0 d-flex align-items-center"
            onClick={this.prevChart}
          >
            <span className="fa fa-chevron-left text-left text-custom-blue" />
          </div>
          <div
            className="col-3 px-0 d-flex align-items-center flex-row-reverse"
            onClick={this.nextChart}
          >
            <span className="fa fa-chevron-right text-right text-custom-blue" />
          </div>
        </div>
        <div style={{ height: "40vh", minHeight: "300px" }}>
          {this.switchChart(this.state.position)}
        </div>
        <div className="row mb-1 justify-content-center">
          {Array(4)
            .fill(1)
            .map((_, i) => {
              if (i === this.state.position) {
                return (
                  <span
                    key={i}
                    className="col-1 mx-1 pt-2 indicator-gold"
                    onClick={e => this.goTo(e, i)}
                  />
                );
              }
              return (
                <span
                  key={i}
                  className="col-1 mx-1 pt-2 indicator-blue"
                  onClick={e => this.goTo(e, i)}
                />
              );
            })}
        </div>
      </div>
    );
  }
}
