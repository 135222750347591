import React, { Component } from 'react';

class FameCard extends Component {
    render () {
        return (
            <div className="col-8 col-sm-4 col-lg-3 px-1 py-1" order={this.props.i}>
                <div className="card px-2 py-2">
                    <img className="card-img-top rounded-circle w-100" src={this.props.item.img} alt={this.props.item.alt}></img>
                    <div className="card-body px-1">
                        <h5 className="card-title">{this.props.item.name}</h5>
                        <p className="card-subtitle mb-2">
                        <img className="mr-1 align-middle" src={require('../../images/spaGoudIconGold.svg')} alt="Spa Goud icon" style={{height:'20px'}}></img>
                            {/* <span className="fa fa-medal fa-lg mr-2"></span> */}
                            <small>{this.props.item.date}</small>
                        </p>
                        <p className="card-text border-top text-muted px-1"><small>{this.props.item.description}</small></p>
                    </div>
                </div>
            </div>
        )};

};

export default FameCard;