import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Chart } from "chart.js";
import { getStandartAvatar, bindAvatar } from "../User/bindAvatar";

const dtf = new Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "short",
  day: "2-digit",
});

function strDay(day) {
  return ("0" + day).slice(-2);
}

class PlayerPage extends Component {
  state = {
    loading: true,
    data: null,
  };

  avatar = (player) => {
    if (player.userID) {
      return bindAvatar(player.userID.avatar);
    }
    return getStandartAvatar(player.name);
  };

  fetchPlayer = () => {
    fetch("/spies/player/" + this.props.match.params.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        data.avatar = this.avatar(data);
        data.results = data.results.filter((rec) => rec);
        data.best = data.results.reduce(
          (acc, cur) => (cur.time < acc.time ? cur : acc),
          {
            time: Number.POSITIVE_INFINITY,
          }
        );
        this.setState({
          data: data,
          loading: false,
        });
        this.pbChart();
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  };

  floorDate = (date) => {
    if (typeof date === "string") {
      date = new Date(date);
    }
    let year = date.getUTCFullYear();
    let month = date.getUTCMonth() + 1;
    let day = date.getUTCDate();
    return new Date(`${year}-${strDay(month)}-${strDay(day)}T00:00:00`);
  };

  pbChart = () => {
    let player = this.state.data;
    player.results = player.results.sort((a, b) =>
      new Date(a.date) < new Date(b.date) ? -1 : 1
    );
    player.pb = [];
    player.results.forEach((res) => {
      if (player.pb.length === 0) {
        player.pb.push(res);
      } else if (player.pb[player.pb.length - 1].time > res.time) {
        player.pb.push(res);
      }
    });
    let minDate = this.floorDate(player.results[0].date);
    let chartData = [
      {
        id: player._id + "_scatter",
        label: "Recorded",
        fill: false,
        borderColor: "#3d4f64",
        // backgroundColor: "#e755ba",
        pointBackgroundColor: "#3d4f64",
        pointBorderColor: "#3d4f64",
        pointHoverBackgroundColor: "#3d4f64",
        pointHoverBorderColor: "#3d4f64",
        lineTension: 0,
        showLine: false,
        data: player.results.map((res) => {
          return { t: this.floorDate(res.date), y: res.time };
        }),
      },
      {
        id: player._id + "_line",
        label: "Personal Best",
        fill: false,
        borderColor: "#e8b923",
        // backgroundColor: "#e755ba",
        pointBackgroundColor: "#e8b923",
        pointBorderColor: "#e8b923",
        pointHoverBackgroundColor: "#e8b923",
        pointHoverBorderColor: "#e8b923",
        lineTension: 0,
        steppedLine: true,
        data: player.pb.map((res) => {
          return { t: this.floorDate(res.date), y: res.time };
        }),
      },
    ];
    if (
      chartData[1].data[chartData[1].data.length - 1].t <
      this.floorDate(new Date())
    ) {
      chartData[1].data.push({
        t: this.floorDate(new Date()),
        y: player.best.time,
      });
    }
    this.setState({ minDate: minDate });
    let ctx = document.getElementById("personalBestChart").getContext("2d");
    new Chart(ctx, {
      type: "line",
      data: {
        labels: [],
        datasets: chartData,
      },
      options: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Progression",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                reverse: false,
                stepSize: 0.1,
                // min: 0,
              },
              scaleLabel: {
                display: true,
                labelString: "Time",
              },
            },
          ],
          xAxes: [
            {
              type: "time",
              distribution: "linear",
              ticks: {
                min: minDate,
              },
              time: {
                tooltipFormat: "D MMM YY",
                displayFormats: { week: "D MMM YY" },
                unit: "week",
              },
            },
          ],
        },
      },
    });
  };

  componentDidMount() {
    this.fetchPlayer();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 pt-3">
            <div>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>
                <small>Fetching Records</small>
              </p>
            </div>
          </div>
        </div>
      );
    }
    if (!this.state.data) {
      return <div>Player not found</div>;
    }
    return (
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-8 col-sm-6 col-lg-3 col-xl-2">
            <img
              className="w-100 rounded-circle"
              alt="player avatar"
              src={this.state.data.avatar}
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-3 overflow-hidden">
            <div className="mx-auto border-bottom">
              <span className="h2 px-2">
                {this.state.data.userID ? (
                  <Link
                    to={"/member/" + this.state.data.userID.username}
                    className="text-dark"
                  >
                    {this.state.data.userID.username}
                  </Link>
                ) : (
                  this.state.data.name
                )}
              </span>
            </div>
            <div className="mx-auto" style={{ maxWidth: "300px" }}>
              <div className="d-flex justify-content-between">
                <span className="text-truncate">Records</span>
                <span>{this.state.data.results.length}</span>
              </div>
            </div>
            <div className="mx-auto" style={{ maxWidth: "300px" }}>
              <div className="d-flex justify-content-between">
                <span className="text-truncate">Personal Best</span>
                <span>{this.state.data.best.time.toFixed(2)} sec</span>
              </div>
            </div>
            <div className="mx-auto" style={{ maxWidth: "300px" }}>
              <div className="d-flex justify-content-between">
                <span className="text-truncate">Date</span>
                <span>{dtf.format(new Date(this.state.data.best.date))}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <canvas
            className="col-12 col-lg-6 my-2"
            id="personalBestChart"
            aria-label="Personal best over time chart"
            role="img"
          />
          {/* <canvas
            className="col-12 col-lg-6 my-2"
            id="adtventRankingChart"
            aria-label="Rank over time chart"
            role="img"
          /> */}
        </div>
        <div className="pt-2">
          <Link to="/spiesranking">Back to ranking</Link>
        </div>
      </div>
    );
  }
}

export default PlayerPage;
