import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchUser from "../User/SearchUser";

class NewSessionForm extends Component {
  state = {
    sessionName: null,
    players: [
      {
        name: "",
        id: null,
      },
    ],
    inputError: null,
    serverError: null,
  };

  updateState = (player, pos) => {
    let players = this.state.players;
    players[pos] = player;
    this.setState({ players: players });
  };

  getState = (pos) => {
    let players = this.state.players;
    return players[pos];
  };

  addPlayer = (e) => {
    e.preventDefault();
    this.setState({ players: [...this.state.players, { name: "" }] });
  };

  removePlayer = (e, i) => {
    e.preventDefault();
    let playerArray = this.state.players;
    playerArray.pop(i);
    this.setState({ players: playerArray });
  };

  postNewSession = async () => {
    return await fetch("/adtjekratje/newSession", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => data.sessionID)
      .catch((err) => {
        console.error(err);
        this.setState({ serverError: true });
      });
  };

  createNewSession = async (e) => {
    e.preventDefault();
    // validate form
    if (
      this.props.existingSessions
        .map((session) => session.name)
        .includes(this.state.sessionName)
    ) {
      return this.setState({
        inputError: "Name already used",
      });
    }
    if (!this.state.sessionName) {
      return this.setState({
        inputError: "Must specify session name",
      });
    }
    if (
      this.state.players.map((player) => player.name.length < 3).some((i) => i)
    ) {
      return this.setState({
        inputError: "Each player name should be of length > 2",
      });
    }
    if (
      new Set(this.state.players.map((player) => player.name)).size !==
      this.state.players.length
    ) {
      return this.setState({
        inputError: "Each player should have a unique name",
      });
    }
    const sessionID = await this.postNewSession();
    // link to session page
    this.props.history.push(`adtjekratje/session/${sessionID}`);
  };

  render() {
    return (
      <form>
        <div className="form-group row">
          <h4 className="col-12">Create New Session</h4>
        </div>
        <div className="form-group row">
          <label htmlFor="sessionname" className="col-sm-4 col-form-label">
            Session Name
          </label>
          <div className="col-sm-8">
            <input
              type="string"
              className="form-control"
              id="sessionname"
              placeholder="Session Name"
              onChange={(e) => this.setState({ sessionName: e.target.value })}
            />
          </div>
        </div>
        {this.state.players.map((player, i) => {
          return (
            <div className="form-group row" key={i}>
              <label htmlFor={`player${i}`} className="col-sm-4 col-form-label">
                {i === 0 ? "Your name" : `player ${i + 1}`}
              </label>
              <div className="col-sm-8 input-group-prepend">
                <SearchUser
                  placeholder={
                    i === 0
                      ? this.props.user
                        ? this.props.user
                        : "Your name"
                      : `player ${i + 1}`
                  }
                  state={this.getState(i)}
                  setState={(obj) => this.updateState(obj, i)}
                />
                {i > 0 ? (
                  <div
                    className="input-group-text btn border-danger text-danger bg-white"
                    onClick={(e) => this.removePlayer(e, i)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </div>
                ) : (
                  <span aria-hidden="true"></span>
                )}
              </div>
            </div>
          );
        })}
        <div className="form-group row justify-content-start">
          <div className="col-sm-8 offset-sm-4">
            <span
              className="d-block btn btn-outline-success"
              onClick={this.addPlayer}
            >
              Add another player
            </span>
          </div>
        </div>
        <div className="form-group row justify-content-start">
          <div className="col-sm-8 offset-sm-4">
            <input
              type="checkbox"
              className="form-check-input"
              id="privateSession"
              onChange={(e) =>
                this.setState({ privateSession: e.target.value })
              }
            />
            <label className="form-check-label" htmlFor="privateSession">
              Private Session
            </label>
          </div>
        </div>
        {this.state.serverError ? (
          <div className="form-group row">
            <div className="col-12 text-danger">
              Failed to create session, please retry
            </div>
          </div>
        ) : (
          <span aria-hidden="true"></span>
        )}
        {this.state.inputError ? (
          <div className="form-group row">
            <div className="col-12 text-danger">{this.state.inputError}</div>
          </div>
        ) : (
          <span aria-hidden="true"></span>
        )}
        <div className="form-group row">
          <div className="col-12 text-right">
            <span
              className="btn btn-outline-secondary"
              onClick={this.props.back}
            >
              Back
            </span>
            <span
              className="btn btn-success ml-2"
              onClick={this.createNewSession}
            >
              Create and Start Session
            </span>
          </div>
        </div>
      </form>
    );
  }
}

class SessionsTable extends Component {
  render() {
    const dtf = new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const dtf2 = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      // hour: "2-digit",
      // minute: "2-digit",
    });

    if (this.props.sessions.length > 0) {
      return (
        <table className="table mb-0">
          <thead>
            <tr>
              <th scope="col">
                {this.props.archived ? "Archived Sessions" : "Current Sessions"}
              </th>
              <th scope="col">Created</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.props.sessions.map((lobby, i) => {
              return (
                <tr key={i}>
                  <td className="align-middle">{lobby.name}</td>
                  <td className="align-middle">
                    {this.props.archived
                      ? dtf2.format(new Date(lobby.createdAt))
                      : dtf.format(new Date(lobby.createdAt))}
                  </td>
                  <td className="align-middle">
                    <Link
                      to={`adtjekratje/session/${lobby._id}`}
                      className="btn btn-success"
                    >
                      Open
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return this.props.archived ? (
      <span aria-hidden="true" />
    ) : (
      <div>No open sessions</div>
    );
  }
}

class AdtjeKratjeLobby extends Component {
  state = {
    loading: true,
    createNew: false,
    sessions: [],
  };

  toggleCreateNew = (e) => {
    e.preventDefault();
    this.setState({ createNew: !this.state.createNew });
  };

  getSessions = () => {
    fetch("/adtjekratje/sessions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ loading: false, sessions: data });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  };

  getArchivedSessions = () => {
    fetch("/adtjekratje/archivedSessions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ archivedSessions: data });
      })
      .catch((err) => console.error(err));
  };

  componentDidMount() {
    this.getSessions();
    if (this.props.user) {
      this.getArchivedSessions();
    }
  }

  componentDidUpdate(prev) {
    if (prev.user !== this.props.user) {
      this.getArchivedSessions();
    }
  }

  render() {
    return (
      <div className="container">
        <h1 className="display-3">Adtje Kratje</h1>
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="row justify-content-center">
              <div className="col-8 col-sm-6 col-lg-8 mb-3">
                <img
                  className="rounded-circle"
                  style={{ width: "100%" }}
                  src={require("../../images/adtjekratje/Adtjekratje_Evenement.png")}
                  alt="Adtje Kratje"
                />
              </div>
            </div>
          </div>
          {this.state.loading ? (
            <div className="col-12 col-lg-8">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>
                <small>Fetching Sessions</small>
              </p>
            </div>
          ) : this.state.createNew ? (
            <div className="col-12 col-lg-8">
              <NewSessionForm
                existingSessions={this.state.sessions}
                back={this.toggleCreateNew}
                user={this.props.user}
                history={this.props.history}
              />
            </div>
          ) : (
            <div className="col-12 col-lg-8">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8 text-muted">
                  <SessionsTable sessions={this.state.sessions} />
                  <div className="d-flex mx-2 mb-2">
                    <span
                      className="btn btn-outline-primary w-100"
                      onClick={this.toggleCreateNew}
                    >
                      Create new
                    </span>
                  </div>
                  {this.props.user && this.state.archivedSessions ? (
                    <SessionsTable
                      sessions={this.state.archivedSessions}
                      archived={true}
                    />
                  ) : (
                    <span aria-hidden="true" />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row justify-content-center py-3">
          <p className="col-12 col-md-4 text-muted pt-md-3">
            This adtje kratje event is basically a over-engineered beer logger.
            You can create a session by adding players and starting the timer.
            Once you finish a beer, you log it by clicking your card. It will
            than give you all kinds of graphs showing your drinking pace,
            ranking etcetera. These graphs update automatically for all users in
            the session, so everyone can log using their own phone and check the
            stats elsewhere.
          </p>
          <div className="col-12 col-md-8">
            <img
              src={require("../../images/adtjekratje/adtjekratjeexample.png")}
              alt="Example graphs"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AdtjeKratjeLobby;
