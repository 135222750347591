import React, { Component } from 'react';
import {
    NavbarText,
    NavLink,
    Modal,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { unsaveRoutes } from './unsaveRoutes';
import LogRes from '../User/LogRes';

class NavBarUser extends Component {
    state = {
          modal: false
        };

    checkLogin = (e) => {
        e.preventDefault();
        fetch('/user/login', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then((res) => {
                if( res.status === 200) {
                    res.json()
                        .then(data => {
                            this.props.setuser(data.user)
                            return;
                        })
                }
            })
            .catch((err) => console.log(err));
        this.toggleLogin()
    }
    
    toggleLogin = (e) => {
        this.setState({
          modal: !this.state.modal
        });
    }

    logout = (e) => {
        e.preventDefault();
        fetch('/user/logout', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then((res) => {
                if( res.status === 200){
                    this.props.setuser(null)
                    this.setState({modal: false})
                    if (unsaveRoutes.includes(this.props.history.location.pathname)) {
                        this.props.history.push("/")
                        window.location.reload()
                    }
                }
            })
        // error handling? 
    }

    componentDidMount() {
        // check if user has session cookie available
        fetch('/user/login', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then((res) => {
                if( res.status === 200) {
                    res.json()
                        .then(data => {
                            this.props.setuser(data.user)
                            return;
                        })
                }
            })
            .catch((err) => console.log(err));
    }

    render() {
        if (this.props.user == null) {
            return (
                <div className="col-4 d-flex px-0">
                    <div className="ml-auto">
                        <NavLink navbar="true" className="mr-2 text-truncate navbar-text" onClick={this.checkLogin}>
                            Login / Register
                        </NavLink>
                    </div>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-sm w-75 d-flex justify-content-center">
                        <LogRes exitModal={this.toggleLogin} setuser={this.props.setuser}/>
                    </Modal>
                </div>
            );
        } else {
            return (
                <div className="col-4 d-flex px-0">
                    <NavbarText navbar="true" className="mr-2 ml-auto">
                        {this.props.user}
                    </NavbarText>
                    <UncontrolledDropdown navbar="true" inNavbar className="d-md-none">
                        <DropdownToggle nav className="navbar-text">
                            <span className="navbar-toggler-icon uncontrolled"></span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <Link className="text-decoration-none" to="/user/account">
                                <DropdownItem>
                                    Account
                                </DropdownItem>
                            </Link>
                            <DropdownItem onClick={this.logout}>
                                Logout
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <Link className="text-decoration-none d-none d-md-inline-block px-2 navbar-text" to="/user/account">Account</Link>
                    <Link className="text-decoration-none d-none d-md-inline-block px-2 navbar-text" onClick={this.logout} to="#">Logout</Link>
                </div>
            );
        }
    }
}

export default NavBarUser;