import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class Etappe extends Component {
    state = {
        profileModal: false,
    }

    toggleModal = (e) => {
        e.preventDefault();
        this.setState({profileModal: !this.state.profileModal});
    }

    render () {
        return (
            <div>
                <h4 className="border-top pt-1 pt-1">{this.props.data.title}</h4>
                <h6>{`${this.props.data.subtitle} - ${this.props.data.date}`}</h6>
                <div className="">
                    <img className="w-100 d-sm-none" onClick={this.toggleModal} alt={`Profiel ${this.props.data.title}`} src={this.props.data.profile} />
                    <img className="w-100 d-none d-sm-block" alt={`Profiel ${this.props.data.title}`} src={this.props.data.profile} />
                </div>
                <div className="row justify-content-center">
                    <p className="col-12 col-md-8 text-muted">{this.props.data.text}</p>
                </div>
                <Modal isOpen={this.state.profileModal} toggle={this.toggleModal} className="bg-light mt-3">
                <ModalHeader className="text-center" toggle={this.toggleModal}>{this.props.data.title}</ModalHeader>
                    <ModalBody>
                        <div className="xscroll">
                            <img className="tour-de-biere-profiel" alt={`Profiel ${this.props.data.title}`} src={this.props.data.profile} />
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
};

export default Etappe;