import React, { Component } from 'react';

class JoinCard extends Component {
    state = {
        confirm: false,
        msg: null,
    }

    submit = (e) => {
        e.preventDefault();
        try {
            fetch('/ladter/join', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        res.json()
                            .then(data => {
                                this.setState({msg: {
                                    text: data.msg,
                                    type: "success"
                                }})
                                window.setTimeout(() => {this.props.update()}, 1000)
                            })
                    } else {
                        res.json()
                            .then(data => {
                                this.setState({msg: {
                                    text: data.errorMsg,
                                    type: "danger"
                                }})
                            })
                        }
                    })
        } catch (err) {
            this.setState({msg: {
                text: err.msg,
                type: "danger"
            }})
        }
    }

    confirm = (e) => {
        e.preventDefault();
        this.setState({confirm: true})
    }

    cancel = (e) => {
        e.preventDefault();
        this.setState({confirm: false})
    }

    render () {
        if (!this.state.msg) {
            return (
                <div className="">
                    <p className="border-top text-muted"><small>By joining the ladter, you agree to storing any results linked to your username and email</small></p>
                    {this.state.confirm ? 
                    <div className="text-right">
                        <button className="btn btn-secondary mr-2" onClick={this.cancel}>cancel</button>
                        <button className="btn btn-primary" onClick={this.submit}>confirm</button>
                    </div> : 
                    <button className="btn btn-primary" onClick={this.confirm}>Join</button>}
                </div>
            );
        } else {
            return (
                <div className={`alert alert-${this.state.msg.type}`}>{this.state.msg.text}</div>
            )
        }
    }
};

export default JoinCard;