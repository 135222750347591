const standartAvatars = [
    "basic",
    "bottle",
    "weizen",
    "fatBeer",
    "dasBoot",
    "wineWhite",
    "wineRed",
    "cavaHe",
    "cavaShe",
    "cavas",
    "shot",
]

const bindAvatar = (inputString) => {
    switch (inputString){
        case "basic":
            return require('../../images/avatars/basic.png');
        case "bottle":
            return require('../../images/avatars/bottle.png');
        case "weizen":
            return require('../../images/avatars/weizen.png');
        case "fatBeer":
            return require('../../images/avatars/fatBeer.png');
        case "dasBoot":
            return require('../../images/avatars/dasBoot.png');
        case "wineWhite":
            return require('../../images/avatars/wineWhite.png');
        case "wineRed":
            return require('../../images/avatars/wineRed.png');
        case "cavaHe":
            return require('../../images/avatars/cavaHe.png');
        case "cavaShe":
            return require('../../images/avatars/cavaShe.png');
        case "cavas":
            return require('../../images/avatars/cavas.png');
        case "shot":
            return require('../../images/avatars/shot.png');
        case "beginner":
            return require('../../images/avatars/baby.png');
        case "corona":
            return require('../../images/avatars/corona.png');
        case "ladterPlayer":
            return require('../../images/avatars/ladter.png');
        case "ladterRookie":
            return require('../../images/avatars/ladterRookie.png');
        case "ladterPro":
            return require('../../images/avatars/underDraft.png');
        // case "ladterVet":
        //     return require('../../images/avatars/);
        case "ladterVictor":
            return require('../../images/avatars/ninja.png');
        case "ladterConqueror":
            return require('../../images/avatars/military.png');
        case "ladterLegend":
            return require('../../images/avatars/onDeadBeers.png');
        case "ladterHeavyweight":
            return require('../../images/avatars/beerTankLadter.png');
        case "ladterChampion":
            return require('../../images/avatars/ladterChampion.png');
        case "cyclist":
            return require('../../images/avatars/cyclist.png');
        case "goldJersey":
            return require('../../images/avatars/tourDeBiereWinner.png');
        case "tourSprinter":
            return require('../../images/avatars/tourDeBiereSprint.png');
        case "tourClimber":
            return require('../../images/avatars/tourDeBiereMountain.png');
        case "kratjePlayer":
            return require('../../images/adtjekratje/Adtjekratje_deelname.png');
        case "kratjeWinner":
            return require('../../images/adtjekratje/Adtjekratje_Uitgespeeld.png');
        case "adtventRookie":
            return require('../../images/avatars/christmasElf.png');
        case "adtventEnthusiast":
            return require('../../images/avatars/santa.png');
        case "adtventLegend":
            return require('../../images/avatars/beerTankChristmas.png');
        case "adtmin":
            return require('../../images/avatars/adtmin.png');
        case "rick":
            return require('../../images/avatars/rickSanchezBeer.png');
        case "mexican":
            return require('../../images/avatars/mexican.png');
        case "artist":
            return require('../../images/avatars/artist.png');
        case "hallOfFamer":
            return require('../../images/avatars/famous.png');
        default:
            return require('../../images/avatars/basic.png');
    }
}

const getStandartAvatar = (playerName) => {
    var hash = 0,
      len = playerName.length;
    for (var i = 0; i < len; i++) {
      hash = (hash << 5) - hash + playerName.charCodeAt(i);
      hash |= 0; // to 32bit integer
    }
    return bindAvatar(standartAvatars[hash % standartAvatars.length]);
  };

module.exports = {
    standartAvatars: standartAvatars,
    getStandartAvatar: getStandartAvatar,
    bindAvatar: bindAvatar,
}