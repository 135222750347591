import React, { Component } from 'react';
import { standartAvatars, bindAvatar } from './bindAvatar'

class AvatarModal extends Component {
    state = {
        standartAvatars: null,
        unlockedAvatars: null,
    }

    imgClick = (e) => {
        e.preventDefault();
        let newStandarts = this.state.standartAvatars.map(item => {
            item.selected = item.key === Number(e.target.id);
            if(item.key === Number(e.target.id)) {
                this.props.setAvatar(item.string)
            }
            return item;
        });
        let newUnlocks = this.state.unlockedAvatars ? this.state.unlockedAvatars.map(item => {
            item.selected = item.key === Number(e.target.id);
            if(item.key === Number(e.target.id)) {
                this.props.setAvatar(item.string)
            }
            return item;
        }) : null;
        this.setState({
            standartAvatars: newStandarts,
            unlockedAvatars: newUnlocks,
        });
    }

    componentDidMount () {
        let newStandarts = standartAvatars.map((avatarString, i) => {
            return {
                key: i,
                selected: this.props.currentAvatar ? this.props.currentAvatar === avatarString : false,
                string: avatarString,
                src: bindAvatar(avatarString),
            }
        })
        let newUnlocks = this.props.unlocks.map((item, i) => {
            return {
                key: 100+i,
                selected: this.props.currentAvatar ? this.props.currentAvatar === item.srcBind : false,
                string: item.srcBind,
                src: bindAvatar(item.srcBind),
            }
        })
        this.setState({
            standartAvatars: newStandarts,
            unlockedAvatars: newUnlocks,
        })
    }

    render () {
        return (
            <div className="container">
                <div className="row justify-content-center border-bottom">
                    {this.state.standartAvatars ? this.state.standartAvatars.map(item => {
                        return (
                            <div className={`col-6 col-sm-4 px-3 py-3`} key={item.key}>
                                <img className={`w-100 rounded-circle ${item.selected ? "border border-warning shadow" : ""}`} id={item.key} src={item.src} alt={`User avatar ${item.string}`} onClick={this.imgClick} />
                            </div>
                        )
                    }) : <span className="invisible">Empty</span>}
                </div>
                <small className="text-muted text-center">Unlocked avatars</small>
                <div className="row justify-content-center">
                    {this.state.unlockedAvatars ? this.state.unlockedAvatars.map(item => {
                        return (
                            <div className={`col-6 col-sm-4 px-3 py-3`} key={item.key}>
                                <img className={`w-100 rounded-circle ${item.selected ? "border border-warning shadow" : ""}`} id={item.key} src={item.src} alt={`User avatar ${item.string}`} onClick={this.imgClick} />
                            </div>
                        )
                    }) : <span className="invisible">Empty</span>}
                </div>
            </div>
        )
    }
};

export default AvatarModal;