import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./adtvent.css";
import AdtminLink from "../User/AdtminLink";
import Scores from "./Scores";
import Calendar from "./Calendar";

class Adtvent extends Component {
  state = {
    url: null,
  };

  render() {
    return (
      <div
        className="chistmas-background"
        style={{
          backgroundColor: "#90040c",
          marginLeft: "-1rem",
          marginRight: "-1rem",
          marginTop: "-1rem",
        }}
      >
        <img
          className="w-100 d-sm-none"
          alt="Backgroud Top"
          src={require("../../images/adtvent/pageTopper_small.png")}
        />
        <img
          className="w-100 d-none d-sm-block"
          alt="Backgroud Top"
          src={require("../../images/adtvent/pageTopper_big.png")}
        />
        <div className="d-flex">
          <Link to="/Adtvent/info">
            <span
              className="pos-abs fa fa-info fa-5x px-3 bg-custom-blue rounded-right text-custom-gold text-decoration-none stretched-link"
              aria-label="Info"
            />
          </Link>
        </div>
        <div className="px-2 py-1">
          <div className="px-sm-3 px-lg-5">
            <div className="row justify-content-center mx-0">
              <Calendar />
              <Scores />
              <AdtminLink to="/adtvent/adtmin" user={this.props.user} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// <a href='https://www.freepik.com/vectors/background'>Background vector created by s.salvador - www.freepik.com</a>
export default Adtvent;
