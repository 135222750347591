import React, { Component } from 'react';
import { rivals } from './ladterHelpFunctions'

class H2Hscroll extends Component {
    render () {
        if (this.props.matches.length > 0) {
            let rivalObjArray = rivals(this.props.matches, this.props.ownID)
            if (this.props.rivalType) {
                return (
                    <div className="col-6 recordsScroll px-1">
                        {rivalObjArray.filter(entry => entry.score > 0).sort((a, b) => (a.score > b.score) ? -1 : 1).map((entry, i) => {
                            return (
                                <div className="d-flex justify-content-between align-items-center" key={i}>
                                    <small className="">{entry.rival.user.username}</small>
                                    <span className="badge badge-success my-1">+{entry.score}</span>
                                </div>
                            )
                        })}
                    </div>
                )
            } else {
                return (
                    <div className="col-6 recordsScroll px-1">
                        {rivalObjArray.filter(entry => entry.score < 0).sort((a, b) => (a.score > b.score) ? 1 : -1).map((entry, i) => {
                            return (
                                <div className="d-flex justify-content-between align-items-center" key={i}>
                                    <small className="">{entry.rival.user.username}</small>
                                    <span className="badge badge-danger my-1">{entry.score}</span>
                                </div>
                            )
                        })}
                    </div>
                )
            }
        }
        return (
            <small className="col-6 px-1">No rivals</small>
        )
    }
};


export default H2Hscroll;