import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactCardFlip from 'react-card-flip';
import ScoreFrom from './ScoreForm';
import HistoryBar from './HistoryBar';
import H2Hscroll from './H2Hscroll';
import { bindAvatar } from '../User/bindAvatar';
import { win, selfScore, opponentScore, opponentName } from './ladterHelpFunctions';

class LadterCard extends Component {
    state = {
        expand: false,
        isFlipped: false,
        timeString: null,
    }

    toggle = (e) => {
        e.preventDefault();
        this.setState({expand: !this.state.expand})
    }

    expand = (e) => {
        e.preventDefault();
        this.setState({expand: true})
    }

    dismiss = (e) => {
        e.preventDefault();
        this.setState({expand: false})
    }

    flip = (e) => {
        e.preventDefault();
        this.setState({ isFlipped: !this.state.isFlipped });
      }

    tierDemotionDuration = (tier) => {
        // return (tier + 1) * 60 * 1000; // demotion test function
        return (tier + 1) * 7 * 24 * 3600 * 1000;
    }

    setDemotionTime = () => {
        // function to set timestring
        var demoTime = (new Date(this.props.data.lastActive)).valueOf() + this.tierDemotionDuration(this.props.data.tier) - Date.now().valueOf(); // time to demotion in ms
        if (demoTime < 0) {
            this.setState({timeString: "critical"})
        } else if ((demoTime / (24*3600*1000)) > 2){
            this.setState({timeString: `${Math.floor(demoTime / (24*3600*1000))} days`})
        } else if ((demoTime / (24*3600*1000)) > 1){
            this.setState({timeString: 'tomorrow'})
        } else {
            const pad = (number, stringLength) => {
                var s = String(number);
                while (s.length < (stringLength || 2)) {s = "0" + s;}
                return s;
            }
            var hrs = pad(Math.floor(demoTime / (3600*1000)), 2);
            var min = pad(Math.floor((demoTime % (3600*1000)) / (60*1000)), 2);
            var sec = pad(Math.floor((demoTime % (60*1000)) / 1000), 2);
            this.setState({timeString: `${hrs}:${min}:${sec}`})
        }
    }

    componentDidMount () {
        this.setDemotionTime();
    }

    componentDidUpdate () {
        // reset timer
        clearInterval(this.myInterval)
        if (this.state.timeString !== "critical") {
            var demoTime = (new Date(this.props.data.lastActive)).valueOf() + this.tierDemotionDuration(this.props.data.tier) - Date.now().valueOf(); // time to demotion in ms
            if (demoTime < 0) {
                this.setState({timeString: "critical"})
                this.props.update()
            } else if ((demoTime / (24*3600*1000)) < 1)  {
                this.myInterval = setInterval(() => {
                    const pad = (number, stringLength) => {
                        var s = String(number);
                        while (s.length < (stringLength || 2)) {s = "0" + s;}
                        return s;
                    }
                    var nextTime = demoTime - 1000;
                    var hrs = pad(Math.floor(nextTime / (3600*1000)), 2);
                    var min = pad(Math.floor((nextTime % (3600*1000)) / (60*1000)), 2);
                    var sec = pad(Math.floor((nextTime % (60*1000)) / 1000), 2);
                    this.setState({timeString: `${hrs}:${min}:${sec}`})
                }, 1000);
            }
        }
    }

    render () {
        let cardClasses = "card px-1 py-1";
        cardClasses += this.props.user === this.props.data.user.username ? " border-custom-gold" : "";
        const dtf = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'short', day: '2-digit' }) ;
        return (
            <div className="col-6 col-sm-4 col-md-3 px-2 py-2">
                <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal">
                    <div className={cardClasses}>
                        <h1>{this.state.counter}</h1>
                        <span className="pos-abs px-2 py-2 stretched-link" onClick={this.flip}><span className="fa fa-chevron-right fa-lg text-muted text-decoration-none"/></span>
                        <img className="card-img-top rounded-circle w-100" alt="Placeholder Avatar" src={bindAvatar(this.props.data.user.avatar)}></img>
                        <div className="card-body px-1 py-1">
                        <Link className="text-decoration-none text-dark" to={`/Ladter/user/${this.props.data._id}`}><h5 className="card-title mb-1">{this.props.data.user.username}</h5></Link>
                            <HistoryBar cardID={this.props.data._id} matches={this.props.data.matches} />
                            <p className="mb-1 card-text">
                                {this.props.lastTier ?
                                <span className="fa fa-calendar-times-o mr-1"/> :
                                <img className="" src={require('../../images/demotionTimer.svg')} alt="demotion timer" style={{height: '1em'}}/>}
                                {!this.props.lastTier ? 
                                <small className="text-muted">{this.state.timeString}</small> :
                                <small className="text-muted">{dtf.format((new Date(this.props.data.lastActive)))}</small>}
                            </p>
                            {this.props.addScore ? <span className="text-muted px-2 py-1 rounded bg-custom-gold" onClick={this.toggle}>Add score {this.state.expand ? <span className="fa fa-chevron-up text-muted mb-0"/> : <span className="fa fa-chevron-down text-muted mb-0"/>}</span> : <span/>}
                        </div>
                        {this.state.expand ? <ScoreFrom userName={this.props.user} cardName={this.props.data.user.username} cardID={this.props.data._id} dismissFunc={this.dismiss} update={this.props.update} /> : <span/>}
                    </div>
                    <div className={cardClasses}>
                        <div className="pos-abs px-2 py-2"><span className="fa fa-chevron-left fa-lg text-muted" onClick={this.flip}/></div>
                        <div className="card-body px-1 py-1">
                            <a className="text-decoration-none text-dark" href={`/Ladter/user/${this.props.data._id}`}><h6>{this.props.data.user.username}</h6></a>
                            <div className="d-block">
                                <small>
                                    <span className="d-inline-block mr-1">Active since:</span>
                                    <span className="d-inline-block">{dtf.format((new Date(this.props.data.createdAt)))}</span>
                                </small>
                            </div>
                            <div className="d-block">
                                <small>
                                    <span className="d-inline-block mr-1">Carreer high:</span>
                                    <span className="d-inline-block">{this.props.data.carreerHigh === null ? `no wins` : `tier ${this.props.data.tierHistory.reduce((acc, cur) => cur.tier < acc ? cur.tier : acc, Number.POSITIVE_INFINITY)}`}</span>
                                </small>
                            </div>
                            <h6 className="mt-2">Results</h6>
                            <div className="recordsScroll border-top px-1">
                                {this.props.data.matches.length > 0 ? this.props.data.matches.filter(match => match.accepted).sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1).map((match, i) => {
                                    return (
                                        <div className="d-flex justify-content-between align-items-center" key={i}>
                                            <small className="">{opponentName(match, this.props.data._id)}</small>
                                            {win(match, this.props.data._id) ? 
                                            <span className="badge badge-success my-1">{selfScore(match, this.props.data._id)}-{opponentScore(match, this.props.data._id)}</span> :
                                            <span className="badge badge-danger my-1">{selfScore(match, this.props.data._id)}-{opponentScore(match, this.props.data._id)}</span>}
                                        </div>
                                    )
                                }) : <small>No results yet</small>}
                            </div>
                            <h6 className="mt-2 border-bottom d-none d-sm-block">H2H</h6>
                            <div className="row d-none d-sm-flex no-gutters">
                                <H2Hscroll matches={this.props.data.matches} ownID={this.props.data._id} rivalType={true}/>
                                <H2Hscroll matches={this.props.data.matches} ownID={this.props.data._id} rivalType={false}/>
                            </div>
                            <a className="d-block d-sm-none text-decoration-none text-muted" href={`Ladter/user/${this.props.data.user.username}`} style={{paddingTop: '12%', paddingBottom: '12%'}}><small>Show me graphs</small></a>
                        </div>
                    </div>
                </ReactCardFlip>
            </div>
        )};

};

export default LadterCard;