import React, { Component } from "react";
import Cookies from "universal-cookie";

import "./adtvent.css";

const cookies = new Cookies();

function allow_open() {
  var card_date = new Date("2021-12-01");
  var system_date = new Date();
  return card_date < system_date;
}

class FrontImage extends Component {
  render() {
    return (
      <div
        className=""
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          transitionDuration: "1s",
          transformOrigin: this.props.bonus === 1 ? "left" : "right",
          transform: this.props.isOpen ? "rotateY(92deg)" : "rotateY(0deg)",
        }}
        onClick={(e) => this.props.open(e)}
      >
        <img
          className={"w-100 hover-shadow"}
          alt={"Icon Bonus " + this.props.bonus}
          src={require("../../images/adtvent/bonus" +
            ("0" + this.props.bonus).slice(-2) +
            ".png")}
        />
      </div>
    );
  }
}

class Inside extends Component {
  state = {
    url: null,
    infoSelected: false,
    info: "Something went wrong, no info available.",
  };

  get_image = (bonus) => {
    fetch("/adtvent/bonus/" + bonus, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.body;
      })
      .then((body) => {
        const reader = body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => this.setState({ url: url }))
      .catch((err) => console.error(err));
  };

  toggleInfoText = (e) => {
    e.preventDefault();
    this.setState({
      infoSelected: !this.state.infoSelected,
      jokerSelected: false,
    });
  };

  componentDidMount() {
    if (allow_open()) {
      this.get_image(this.props.bonus);
      this.setState({
        info:
          this.props.bonus === 1
            ? "You are rewarded 5 points if you play 'half adtje kratje' sometime during this calendar"
            : "You get an additional 5 points once if you participate on 7 consecutive days",
      });
    }
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          className="position-absolute w-100 h-100 d-flex flex-row justify-content-around text-light pr-1"
          style={{ zIndex: 25 }}
        >
          <div className="d-inline" onClick={this.toggleInfoText}>
            <div className="position-relative csstooltip">
              <span className="fa fa-info-circle" />
              <span
                className={`csstooltiptext-left px-1 py-1 ${
                  this.state.infoSelected ? "visible" : ""
                }`}
              >
                {this.state.info}
              </span>
            </div>
          </div>
          <div className="d-inline" onClick={this.props.close}>
            <div className="position-relative csstooltip">
              <span className="fa fa-times" />
              <span className="csstooltiptext-left px-1 py-1">Close</span>
            </div>
          </div>
        </div>
        <img
          className="w-100"
          alt={"bonus task" + this.props.bonus}
          src={this.state.url}
        />
      </div>
    );
  }
}

class BonusCard extends Component {
  state = {
    isOpen: false,
  };

  open = (e) => {
    e.preventDefault();
    if (allow_open()) {
      let cookie = cookies.get("adtvent");
      if (!cookie) {
        cookie = [];
      }
      cookie.push(-1 * this.props.bonus);
      let daySet = [...new Set(cookie)];
      cookies.set("adtvent", daySet, { maxAge: 60 }); // for testing
      // cookies.set("adtvent", daySet, {maxAge: 3600 * 24 * 31})  // cookie expires in one month
      this.setState({ isOpen: true });
    }
  };

  close = (e) => {
    e.preventDefault();
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  componentDidMount() {
    let cookie = cookies.get("adtvent");
    if (!cookie) {
      cookie = [];
    }
    if (cookie.includes(-1 * this.props.bonus)) {
      this.setState({ isOpen: true });
    }
  }

  render() {
    return (
      <div className="" style={{ width: "50%" }}>
        <div
          className="w-100"
          style={{ position: "relative", paddingBottom: "100%" }}
        >
          {this.state.isOpen ? (
            <Inside bonus={this.props.bonus} close={this.close} />
          ) : (
            <div />
          )}
          <FrontImage
            isOpen={this.state.isOpen}
            open={this.open}
            bonus={this.props.bonus}
          />
        </div>
      </div>
    );
  }
}

export default BonusCard;
