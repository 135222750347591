import React, { Component } from "react";
import DayCard from "./DayCard";
import BonusCard from "./BonusCard";

function getDays(i) {
  // defines what days are in which row
  const order = [
    [7, 21, 16, 11, 13],
    [0, 3, 6, 20, 2],
    [9, 12, 8, 23, 18],
    [10, 14, 17, 1, 22],
    [19, 15, 5, 24, 4]
  ]
  return order[i]
}

class CalendarRow extends Component {
  render() {
    let place = Array.from(Array(5).keys()).map((i) => i + this.props.row * 5);
    let days = getDays(this.props.row);
    return (
      <div className="d-flex">
        {days.map((day, i) => {
          return <DayCard day={day} place={place[i]} key={i} />;
        })}
      </div>
    );
  }
}

class Calendar extends Component {
  render() {
    let rows = Array.from(Array(5).keys());
    return (
      <div className="col-12 col-lg-6 px-0 pb-3">
        <div className="position-relative">
          <img
            className="w-100"
            alt="Adtvent Calendar Frame"
            src={require("../../images/adtvent/AdtventCalendarBackClean.png")}
          />
          <div className="d-flex bonus-row">
            <BonusCard bonus={1} />
            <BonusCard bonus={2} />
          </div>
          <div className="adtvent-frame">
            {rows.map((row, i) => (
              <CalendarRow row={row} key={i} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Calendar;
