import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bindAvatar } from "./bindAvatar";

class AchievementBadge extends Component {
  render() {
    return (
      <div className="col-6 col-sm-4 col-md-3 col-lg-2">
        <img
          className="w-100 px-1 py-1 rounded-circle"
          src={bindAvatar(this.props.data.srcBind)}
          alt={`${this.props.data.title} badge`}
          id={`achievement-${this.props.id}`}
        />
        <small className="text-muted">{this.props.data.title}</small>
      </div>
    );
  }
}

class MemberPage extends Component {
  state = {
    loading: true,
    data: null,
  };

  componentDidMount() {
    fetch("/user/member/" + this.props.match.params.user, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return this.setState({
            loading: false,
            errMsg: {
              msg: data.error.message,
              type: "danger",
            },
          });
        }
        return this.setState({
          loading: false,
          data: data,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errMsg: {
            msg: err.msg,
            type: "danger",
          },
        });
      });
  }

  componentDidUpdate() {
    console.log(this.state)
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="">
          <div>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>
              <small>Fetching member achievements</small>
            </p>
          </div>
        </div>
      );
    }
    if (this.state.errMsg) {
      return (
        <div className={`alert alert-${this.state.errMsg.type}`}>
          {this.state.errMsg.msg}
        </div>
      );
    }

    const user = this.state.data.user;
    const dtf = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return (
      <div className="container">
        <h1>{user.username}</h1>
        <div className="row justify-content-center">
          <div className="col-8 col-sm-6">
            <img
              className="rounded-circle w-75 pb-2 d-block mx-auto"
              src={bindAvatar(user.avatar)}
              alt="UserAvatar"
              style={{ maxWidth: "350px" }}
            />
          </div>
          <div className="col-12 col-sm-6 pb-3">
            <div className="mx-auto" style={{ maxWidth: "500px" }}>
              <div className="d-flex justify-content-between">
                <span className="">Member since</span>
                <span>{dtf.format(new Date(user.createdAt))}</span>
              </div>
            </div>
            <div className="mx-auto" style={{ maxWidth: "500px" }}>
              <div className="d-flex justify-content-between">
                {this.state.data.spies ? (
                  <Link to={"/spiesranking/player/" + this.state.data.spies._id} className="text-dark">Spies record</Link>
                ) : (
                  <span className="">Spies record</span>
                )}
                <span>
                  {this.state.data.spies ? this.state.data.spies.best.toFixed(2) + " sec": "-"}
                </span>
              </div>
            </div>
            <div className="mx-auto" style={{ maxWidth: "500px" }}>
              <div className="d-flex justify-content-between">
                {this.state.data.ladter ? (
                  <Link to={"/Ladter/user/" + this.state.data.ladter._id} className="text-dark">
                    Ladter tier
                  </Link>
                ) : (
                  <span className="">Ladter tier</span>
                )}
                <span>
                  {this.state.data.ladter ? this.state.data.ladter.tier : "-"}
                </span>
              </div>
            </div>
            <div className="mx-auto" style={{ maxWidth: "500px" }}>
              <div className="d-flex justify-content-between">
                {this.state.data.adtvent ? (
                  <Link to={"/adtvent/player/" + this.state.data.adtvent._id} className="text-dark">
                    Adtvent player
                  </Link>
                ) : (
                  <span className="">Adtvent player</span>
                )}
                <span>{this.state.data.adtvent ? "yes" : "no"}</span>
              </div>
            </div>
          </div>
        </div>
        <h5 className="border-top">Achievements</h5>
        <div className="row pb-5">
          {this.state.data.achievements.length > 0 ? (
            this.state.data.achievements.map((item, i) => {
              return <AchievementBadge key={i} data={item} id={i} />;
            })
          ) : (
            <span className="invisible d-none d-sm-block">No achievements</span>
          )}
        </div>
      </div>
    );
  }
}

export default MemberPage;
