import React, { Component } from "react";
import { Chart } from "chart.js";
import { colorPalette } from "./colorPalette";

function hasChange(prev, cur) {
  return (
    prev.data.records.length !== cur.data.records.length ||
    prev.data.players.length !== cur.data.players.length
  );
}

function mostBeers(players) {
  // returns number of beers of leading player
  return players.reduce(
    (acc, cur) => (cur.records.length > acc ? cur.records.length : acc),
    0
  );
}

function timeBetween(time0, time1) {
  // time between 2 datetime strings in milisec
  return Math.abs(new Date(time0).valueOf() - new Date(time1).valueOf());
}

function playerDataset(player, datasets) {
  return datasets.filter((dataset) => dataset.id === player._id)[0];
}

function extendAxis(linechart, data) {
  linechart.data.labels = Array.from(
    {
      length: mostBeers(data.players) + 1,
    },
    (x, i) => i + 1
  );
}

export class BarChart extends Component {
  state = {
    chart: null,
  };

  componentDidMount() {
    let ctx = document.getElementById("adtjekratjeBarChart").getContext("2d");
    let sorted = this.props.data.players.sort((a, b) =>
      a.records.length > b.records.length ? -1 : 1
    );
    let barchart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: sorted.map((player) => player.name),
        datasets: [
          {
            backgroundColor: "#e8b923",
            data: sorted.map((player) => player.records.length),
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Current Standing",
          fontColor: "#3d4f64",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#3d4f64",
                stepSize: 1,
                min: 0,
                suggestedMax: 24,
              },
              scaleLabel: {
                display: true,
                labelString: "Beers drunk",
                fontColor: "#3d4f64",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "#3d4f64",
              },
            },
          ],
        },
      },
    });
    this.setState({ chart: barchart });
  }

  componentDidUpdate(prev) {
    if (hasChange(prev, this.props)) {
      let sorted = this.props.data.players.sort((a, b) =>
        a.records.length > b.records.length ? -1 : 1
      );
      let barchart = this.state.chart;
      barchart.data.labels = sorted.map((player) => player.name);
      barchart.data.datasets[0].data = sorted.map(
        (player) => player.records.length
      );
      barchart.update();
      this.setState({ chart: barchart });
    }
  }

  render() {
    return <canvas className="my-2" id="adtjekratjeBarChart" aria-label="Current standings Chart" role="img" />;
  }
}

export class TimePerBeerChart extends Component {
  state = {
    chart: null,
  };

  timePerBeerDataset = (player) => {
    // function that return time per beer dataset
    return player.records.map((record, i) => {
      if (i === 0) {
        return {
          x: i + 1,
          y: timeBetween(record.time, player.startTime) / 60000,
          id: record._id,
        };
      } else {
        return {
          x: i + 1,
          y: timeBetween(record.time, player.records[i - 1].time) / 60000,
          id: record._id,
        };
      }
    });
  };

  componentDidMount() {
    let ctx = document
      .getElementById("adtjekratjeTimePerBeerChart")
      .getContext("2d");
    let chartData = [];
    this.props.data.players.forEach((player, j) => {
      let playerColor = colorPalette(j);
      chartData.push({
        id: player._id,
        label: player.name,
        fill: false,
        borderColor: playerColor,
        // backgroundColor: "#e755ba",
        pointBackgroundColor: playerColor,
        pointBorderColor: playerColor,
        pointHoverBackgroundColor: playerColor,
        pointHoverBorderColor: playerColor,
        lineTension: 0,
        // steppedLine: true,
        data: this.timePerBeerDataset(player),
      });
    });
    let linechart = new Chart(ctx, {
      type: "line",
      data: {
        labels: Array.from(
          {
            length: mostBeers(this.props.data.players) + 1,
          },
          (x, i) => i + 1
        ),
        datasets: chartData,
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
        },
        title: {
          display: true,
          text: "Time per Beer",
          fontColor: "#3d4f64",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#3d4f64",
                suggestedMin: 0,
              },
              scaleLabel: {
                display: true,
                labelString: "Minutes",
                fontColor: "#3d4f64",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "#3d4f64",
              },
              scaleLabel: {
                display: true,
                labelString: "Beer number",
                fontColor: "#3d4f64",
              },
            },
          ],
        },
        tooltips: {
          footerFontStyle: "normal",
          displayColors: true,
          callbacks: {
            footer: function (tooltipItem, data) {
              return data.datasets[tooltipItem[0].datasetIndex].label;
            },
            title: function (tooltipItem, _) {
              return `Beer ${tooltipItem[0].xLabel}`;
            },
            label: function (tooltipItem, _) {
              let s = tooltipItem.value * 60;
              let sec = Math.round(s % 60);
              let secString = ("00" + sec).slice(-2);
              let min = Math.floor(s / 60);
              return ` ${min}:${secString}`;
            },
          },
        },
      },
    });
    this.setState({ chart: linechart });
  }

  componentDidUpdate(prev) {
    if (hasChange(prev, this.props)) {
      // update x axis length
      let linechart = this.state.chart;
      extendAxis(linechart, this.props.data);

      // update datasets !! only works for 1 update at a time!!
      this.props.data.players.forEach((player, j) => {
        let ds = playerDataset(player, linechart.data.datasets);
        if (!ds) {
          // add player
          let playerColor = colorPalette(j);
          linechart.data.datasets.push({
            id: player._id,
            label: player.name,
            fill: false,
            borderColor: playerColor,
            pointBackgroundColor: playerColor,
            pointBorderColor: playerColor,
            pointHoverBackgroundColor: playerColor,
            pointHoverBorderColor: playerColor,
            lineTension: 0,
            data: [],
          });
          return;
        }

        if (player.records.length > ds.data.length) {
          // add record
          let i = player.records.length - 1;
          if (i === 0) {
            ds.data.push({
              x: player.records.length,
              y: timeBetween(player.records[i].time, player.startTime) / 60000,
              id: player.records[i]._id,
            });
          } else {
            ds.data.push({
              x: player.records.length,
              y:
                timeBetween(
                  player.records[i].time,
                  player.records[i - 1].time
                ) / 60000,
              id: player.records[i]._id,
            });
          }
        } else if (player.records.length < ds.data.length) {
          // remove record
          ds.data = this.timePerBeerDataset(player);
        }
      });
      linechart.update();
      this.setState({ chart: linechart });
    }
  }

  render() {
    return <canvas className="my-2" id="adtjekratjeTimePerBeerChart" aria-label="Time per Beer Chart" role="img"/>;
  }
}

export class RankPerBeerChart extends Component {
  state = {
    chart: null,
  };

  rankPerBeer = (player, data) => {
    player.rankPerBeer = [];
    player.records.forEach((record, beerNum) => {
      player.rankPerBeer.push(
        data.players
          .map((p) => p.records[beerNum])
          .filter((r) => !!r)
          .filter(
            (r) => new Date(r.time).valueOf() < new Date(record.time).valueOf()
          ).length
      );
    });
  };

  componentDidMount() {
    let chartData = [];
    this.props.data.players.forEach((player, j) => {
      this.rankPerBeer(player, this.props.data);
      let playerColor = colorPalette(j);
      chartData.push({
        id: player._id,
        label: player.name,
        fill: false,
        borderColor: playerColor,
        // backgroundColor: "#e755ba",
        pointBackgroundColor: playerColor,
        pointBorderColor: playerColor,
        pointHoverBackgroundColor: playerColor,
        pointHoverBorderColor: playerColor,
        lineTension: 0,
        // steppedLine: true,
        data: player.rankPerBeer.map((rank, k) => {
          return { y: rank + 1, x: k + 1 };
        }),
      });
    });
    let ctx = document
      .getElementById("adtjekratjeRankPerBeerChart")
      .getContext("2d");
    let linechart = new Chart(ctx, {
      type: "line",
      data: {
        labels: Array.from(
          {
            length: mostBeers(this.props.data.players) + 1,
          },
          (x, i) => i + 1
        ),
        datasets: chartData,
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
        },
        title: {
          display: true,
          text: "Ranking per Beer",
          fontColor: "#3d4f64",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#3d4f64",
                stepSize: 1,
                reverse: true,
              },
              scaleLabel: {
                display: true,
                labelString: "Rank",
                fontColor: "#3d4f64",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "#3d4f64",
              },
              scaleLabel: {
                display: true,
                labelString: "Beer number",
                fontColor: "#3d4f64",
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, _) {
              return `Beer ${tooltipItem[0].xLabel}`;
            },
          },
        },
      },
    });
    this.setState({ chart: linechart });
  }

  componentDidUpdate(prev) {
    if (hasChange(prev, this.props)) {
      let linechart = this.state.chart;
      extendAxis(linechart, this.props.data);
      this.props.data.players.forEach((player, j) => {
        let ds = playerDataset(player, linechart.data.datasets);
        if (!ds) {
          // add player
          let playerColor = colorPalette(j);
          linechart.data.datasets.push({
            id: player._id,
            label: player.name,
            fill: false,
            borderColor: playerColor,
            // backgroundColor: "#e755ba",
            pointBackgroundColor: playerColor,
            pointBorderColor: playerColor,
            pointHoverBackgroundColor: playerColor,
            pointHoverBorderColor: playerColor,
            lineTension: 0,
            // steppedLine: true,
            data: null,
          });
          return;
        }

        this.rankPerBeer(player, this.props.data);
        if (player.records.length > ds.data.length) {
          // add record
          let k = player.rankPerBeer.length;
          ds.data.push({ y: player.rankPerBeer[k - 1] + 1, x: k });
        } else if (player.records.length < ds.data.length) {
          // remove record
          ds.data = player.rankPerBeer.map((rank, k) => {
            return { y: rank + 1, x: k + 1 };
          });
        }
      });
      linechart.update();
      this.setState({ chart: linechart });
    }
  }

  render() {
    return <canvas className="my-2" id="adtjekratjeRankPerBeerChart" aria-label="Rank per Beer Chart" role="img"/>;
  }
}

export class TimeDifPerBeerChart extends Component {
  state = {
    chart: null,
  };

  timeDifPerBeer = (player, data) => {
    player.timeDifPerBeer = [
      timeBetween(player.startTime, this.props.data.createdAt),
    ];
    player.records.forEach((record, beerNum) => {
      player.timeDifPerBeer.push(
        new Date(record.time).valueOf() -
          data.players
            .map((p) => p.records[beerNum])
            .filter((r) => !!r)
            .reduce(
              (acc, cur) =>
                new Date(cur.time).valueOf() < acc
                  ? new Date(cur.time).valueOf()
                  : acc,
              Number.POSITIVE_INFINITY
            )
      );
    });
  };

  componentDidMount() {
    let chartData = [];
    this.props.data.players.forEach((player, j) => {
      this.timeDifPerBeer(player, this.props.data);
      let playerColor = colorPalette(j);
      chartData.push({
        id: player._id,
        label: player.name,
        fill: false,
        borderColor: playerColor,
        // backgroundColor: "#e755ba",
        pointBackgroundColor: playerColor,
        pointBorderColor: playerColor,
        pointHoverBackgroundColor: playerColor,
        pointHoverBorderColor: playerColor,
        lineTension: 0,
        // steppedLine: true,
        data: player.timeDifPerBeer.map((timeDif, k) => {
          return { y: timeDif / 60000, x: k };
        }),
      });
    });
    let ctx = document
      .getElementById("adtjekratjeTimeDifPerBeerChart")
      .getContext("2d");
    let linechart = new Chart(ctx, {
      type: "line",
      data: {
        labels: Array.from(
          {
            length: mostBeers(this.props.data.players) + 2,
          },
          (x, i) => i
        ),
        datasets: chartData,
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
        },
        title: {
          display: true,
          text: "Time Difference per Beer",
          fontColor: "#3d4f64",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#3d4f64",
                reverse: true,
              },
              scaleLabel: {
                display: true,
                labelString: "Minutes behind first",
                fontColor: "#3d4f64",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "#3d4f64",
              },
              scaleLabel: {
                display: true,
                labelString: "Beer number",
                fontColor: "#3d4f64",
              },
            },
          ],
        },
        tooltips: {
          footerFontStyle: "normal",
          callbacks: {
            footer: function (tooltipItem, data) {
              return data.datasets[tooltipItem[0].datasetIndex].label;
            },
            title: function (tooltipItem, _) {
              return `Beer ${tooltipItem[0].xLabel}`;
            },
            label: function (tooltipItem, _) {
              let s = tooltipItem.value * 60;
              let sec = Math.round(s % 60);
              let secString = ("00" + sec).slice(-2);
              let min = Math.floor(s / 60);
              return ` ${min}:${secString}`;
            },
          },
        },
      },
    });
    this.setState({ chart: linechart });
  }

  componentDidUpdate(prev) {
    if (hasChange(prev, this.props)) {
      let linechart = this.state.chart;
      // extend axis
      linechart.data.labels = Array.from(
        {
          length: mostBeers(this.props.data.players) + 2,
        },
        (x, i) => i
      );

      this.props.data.players.forEach((player, j) => {
        let ds = playerDataset(player, linechart.data.datasets);

        if (!ds) {
          // add player
          let playerColor = colorPalette(j);
          linechart.data.datasets.push({
            id: player._id,
            label: player.name,
            fill: false,
            borderColor: playerColor,
            // backgroundColor: "#e755ba",
            pointBackgroundColor: playerColor,
            pointBorderColor: playerColor,
            pointHoverBackgroundColor: playerColor,
            pointHoverBorderColor: playerColor,
            lineTension: 0,
            // steppedLine: true,
            data: [
              timeBetween(player.startTime, this.props.data.createdAt) / 60000,
            ],
          });
          return;
        }

        this.timeDifPerBeer(player, this.props.data);
        if (player.records.length + 1 > ds.data.length) {
          // add record
          let k = player.timeDifPerBeer.length - 1;
          ds.data.push({ y: player.timeDifPerBeer[k] / 60000, x: k });
        } else if (player.records.length < ds.data.length) {
          // remove record
          ds.data = player.timeDifPerBeer.map((timeDif, k) => {
            return { y: timeDif / 60000, x: k };
          });
        }
      });
      linechart.update();
      this.setState({ chart: linechart });
    }
  }

  render() {
    return <canvas className="my-2" id="adtjekratjeTimeDifPerBeerChart" aria-label="Time Difference per Beer Chart" role="img"/>;
  }
}
