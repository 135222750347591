import React, { Component } from 'react';
import {
    Navbar,
    NavbarBrand,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container
} from 'reactstrap';
import { Link } from 'react-router-dom'

import NavBarUser from './NavBarUser';

class AppNavBar extends Component {

    render() {
        return (
            <div style={{width: '100%'}}>
            <Navbar color="light" light expand = "md" className="fixed-top px-0">
                <Container fluid="md" className="justify-content-between">
                    <div className="col-4 d-flex px-0">
                        <UncontrolledDropdown navbar="true" inNavbar>
                            <DropdownToggle nav caret className='navbar-text'>
                                Browse
                            </DropdownToggle>
                            <DropdownMenu left="true">
                                <Link className="text-decoration-none" to="/">
                                    <DropdownItem>
                                        Home
                                    </DropdownItem>
                                </Link>
                                <DropdownItem divider />
                                {this.props.events.sort((a,b) => a.order > b.order ? 1 : -1).map((event, i) => {
                                    return (
                                        <Link key={i} className="text-decoration-none" to={event.routerLink}>
                                            <DropdownItem>
                                                {event.title}
                                            </DropdownItem>
                                        </Link>
                                    )
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <div className="col-4">
                        <NavbarBrand href="/" nav="true" navbar="true" className="d-none d-sm-inline-block">
                            Spa-Goud.nl
                        </NavbarBrand>
                        <NavbarBrand href="/" nav="true" navbar="true" className="d-sm-none">
                            <img src={require('../../images/spaGoudIconGold.svg')} alt="Spa Goud icon" style={{height:'30px'}}></img>
                        </NavbarBrand>
                    </div>
                    <NavBarUser user={this.props.user} setuser= {this.props.setuser} history={this.props.history}/>
                </Container>
            </Navbar>
        </div>
        );
    }

};

export default AppNavBar;