import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Chart } from 'chart.js';
import { bindAvatar } from '../User/bindAvatar';
import { win, selfScore, opponentScore, opponentName, rivals } from './ladterHelpFunctions';

class H2H extends Component {
    render() {
        if (this.props.matches.length > 0) {
            let rivalObjArray = rivals(this.props.matches, this.props.ownID);

            return (
                <div className="row justify-content-center">
                    <div className="col-6 col-md-4">
                        <h6 className="text-custom-gold border-bottom">Most defeated</h6>
                        {rivalObjArray.filter(entry => entry.score > 0).sort((a, b) => (a.score > b.score) ? -1 : 1).map((entry, i) => {
                            return (
                                <div className="d-flex justify-content-between align-items-center" key={i}>
                                    <Link className="text-dark text-decoration-none" to={`/Ladter/user/${entry.rival._id}`}><small className="">{entry.rival.user.username}</small></Link>
                                    <div>
                                        <small className='text-muted mr-1'>{entry.result}</small>
                                        <span className="badge badge-success my-1">+{entry.score}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-6 col-md-4">
                        <h6 className="text-custom-gold border-bottom">Most feared</h6>
                        {rivalObjArray.filter(entry => entry.score < 0).sort((a, b) => (a.score > b.score) ? 1 : -1).map((entry, i) => {
                            return (
                                <div className="d-flex justify-content-between align-items-center" key={i}>
                                    <Link className="text-dark text-decoration-none" to={`/Ladter/user/${entry.rival._id}`}><small className="">{entry.rival.user.username}</small></Link>
                                    <div>
                                        <small className='text-muted mr-1'>{entry.result}</small>
                                        <span className="badge badge-danger my-1">{entry.score}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="row justify-content-center">
                    <small>No matches played, so no rivals yet...</small>
                </div>
            )
        }
    }
}

class LadterAccount extends Component {
    state = {
        loading: true,
        data: null,
        errMsg: null,
    }

    matchTierHistory = (userStats) => {
        function timeBetween(datetime1, datetime2) {
            const t1 = new Date(datetime1).valueOf();
            const t2 = new Date(datetime2).valueOf();
            return Math.abs(t1 - t2)
        }
        let tierHistory = userStats.tierHistory.sort((a, b) => ((new Date(a.date)) > (new Date(b.date))) ? 1 : -1)
        userStats.matches.forEach(match => {
            tierHistory.sort((a, b) => timeBetween(match.createdAt, a.date) > timeBetween(match.createdAt, b.date) ? 1 : -1)
            if (timeBetween(match.createdAt, tierHistory[0].date) < 200) {
                tierHistory[0].match = match
            } else {
                console.error(`no tierHistory entry that matches:`)
                console.log(match)
            }
        })
        return tierHistory
    }

    renderChart = () => {
        var ctx = document.getElementById('rankingChart').getContext('2d');
        const detailedTierHistory = this.matchTierHistory(this.state.data);
        var chartData = [];
        detailedTierHistory.sort((a, b) => ((new Date(a.date)) > (new Date(b.date))) ? 1 : -1).forEach((item, i) => {
            let eventLabel = item.match ? { label: `${selfScore(item.match, this.state.data._id)}-${opponentScore(item.match, this.state.data._id)}`, footer: opponentName(item.match, this.state.data._id), win: win(item.match, this.state.data._id) } : { label: null, footer: "time based" };
            chartData.push(
                {
                    t: new Date(item.date),
                    y: item.tier,
                    // add label displaying opponent and score?
                    lab: eventLabel
                }
            )
        })

        // push current tier to data
        chartData.push(
            {
                t: new Date(Date.now()),
                y: chartData[chartData.length - 1].y,
                lab: { label: null, footer: "now" }
            }
        )
        // var minDate = new Date("2020-04-01"); // could use start of ladter as begin date
        var minDate = chartData[0].t;

        new Chart(ctx, {
            type: 'line',
            data: {
                labels: ['Rank'],
                datasets: [{
                    label: 'Tier',
                    fill: false,
                    borderColor: "#3d4f64",
                    backgroundColor: "#e755ba",
                    pointBackgroundColor: "#e8b923",
                    pointBorderColor: "#e8b923",
                    pointHoverBackgroundColor: "#e8b923",
                    pointHoverBorderColor: "#e8b923",
                    steppedLine: true,
                    data: chartData,
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            reverse: true,
                            stepSize: 1,
                            min: 1
                        }
                    }],
                    xAxes: [{
                        type: 'time',
                        distribution: 'linear',
                        ticks: {
                            min: minDate
                        },
                        time: {
                            tooltipFormat: 'D MMM HH:MM',
                            unit: 'day',
                        }
                    }]
                },
                tooltips: {
                    footerFontStyle: 'normal',
                    displayColors: true,
                    callbacks: {
                        footer: function (tooltipItem, data) {
                            return data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].lab.footer
                        },
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].lab.label;
                        },
                        labelColor: function (tooltipItem, chart) {
                            if (chart.data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].lab.win === undefined) {
                                return {
                                    borderColor: 'rgb(0, 0, 0)',
                                    backgroundColor: 'rgb(0, 0, 0)'
                                }
                            } else if (chart.data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].lab.win) {
                                return {
                                    borderColor: 'rgb(48, 166, 74)',
                                    backgroundColor: 'rgb(48, 166, 74)'
                                }
                            } else {
                                return {
                                    borderColor: 'rgb(218, 56, 73)',
                                    backgroundColor: 'rgb(218, 56, 73)'
                                }
                            }
                        }
                    }
                }
            }
        });
    }

    fetchUserStats = () => {
        fetch('/ladter/userStats', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                cardID: this.props.match.params.id,
            })
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error(res.statusText)
                }
                return res.json()
            })
            .then(data => {
                this.setState({
                    data: data,
                    loading: false
                })
                rivals(data.matches, data._id)
                this.renderChart()
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    errMsg: {
                        msg: err.message,
                        type: "danger"
                    },
                    loading: false,
                })
            })
    }

    componentDidMount() {
        this.fetchUserStats();
    }

    componentDidUpdate(prev) {
        if (prev.match.params.id !== this.props.match.params.id) {
            this.setState({ loading: true })
            this.fetchUserStats();
        }
    }

    render() {
        const dtf = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'short', day: '2-digit' });

        if (this.state.loading) {
            return (
                <div className="">
                    <div>
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p><small>Fetching ladter statistics</small></p>
                    </div>
                </div>
            )
        }

        if (!this.state.data) {
            return <div>
                <div>{this.state.errMsg ? this.state.errMsg.msg : "Unknown Error"}: Failed to load ladter player statistics</div>
            </div>
        }

        return (
            <div className="container pb-3">
                <h1>Ladter statistics of <Link to={"/member/" + this.state.data.user.username} className="text-dark">{this.state.data.user.username}</Link></h1>
                <div className="row justify-content-around align-items-center border-bottom pb-1">
                    <div className="col-12 col-md-6 col-lg-4">
                        <img className="w-50 rounded-circle" src={bindAvatar(this.state.data.user.avatar)} alt="User avatar" />
                    </div>
                    <div className="col-8 col-md-4 col-lg-3">
                        <h3 className="text-custom-gold">{`Tier ${this.state.data.tier}`}</h3>
                        <div className="d-flex justify-content-between">
                            <small>Active since:</small>
                            <small>{dtf.format((new Date(this.state.data.createdAt)))}</small>
                        </div>
                        <div className="d-flex justify-content-between">
                            <small>Last activity:</small>
                            <small>{dtf.format((new Date(this.state.data.lastActive)))}</small>
                        </div>
                        <div className="d-flex justify-content-between">
                            <small>Carreer high:</small>
                            <small>{this.state.data.carreerHigh === null ? `no wins` : this.state.data.tierHistory.reduce((acc, cur) => cur.tier < acc ? cur.tier : acc, Number.POSITIVE_INFINITY)}</small>
                        </div>
                        <div className="d-flex justify-content-between">
                            <small>Carreer win / loss:</small>
                            <small>{`${this.state.data.matches.filter(match => win(match, this.state.data._id) && match.accepted).length} / ${this.state.data.matches.filter(match => (!win(match, this.state.data._id)) && match.accepted).length} (${this.state.data.matches.filter(match => match.accepted).length})`}</small>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center border-bottom">
                    <canvas className="col-12 col-md-6 col-lg-7 pt-1" id="rankingChart" />
                    <div className="col-12 col-sm-8 col-md-6 col-lg-4 py-1">
                        {this.state.data.matches.length > 0 ?
                            this.state.data.matches.map((match, i) => {
                                return (
                                    <div className="row justify-content-between pt-1 align-items-center" key={i}>
                                        <div className="col-5 text-left">
                                            <Link className="text-dark text-decoration-none" to={`/Ladter/user/${match.player1._id === this.state.data._id ? match.player2._id : match.player1._id}`}><small>{opponentName(match, this.state.data._id)}</small></Link>
                                        </div>
                                        <div className="col-2">
                                            <small className={`badge badge-pill badge-${win(match, this.state.data._id) ? 'success' : 'danger'}`}>{`${selfScore(match, this.state.data._id)} - ${opponentScore(match, this.state.data._id)}`}</small>
                                        </div>
                                        <div className="col-5 text-right">
                                            <small>{dtf.format((new Date(match.createdAt)))}</small>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className="d-flex justify-content-between">
                                <small>Active since:</small>
                                <small>{(new Date(this.state.data.createdAt)).toLocaleDateString('en-GB')}</small>
                            </div>}
                    </div>
                </div>
                <h3 className="mt-1">Head to Head</h3>
                <H2H matches={this.state.data.matches} ownID={this.state.data._id} />
                <div className="pt-2">
                    <Link to="/Ladter">Back to ladter</Link>
                </div>
            </div>
        )
    }
}

export default LadterAccount;