function win(match, idSelf){
    return (match.player1._id === idSelf) ^ (match.player1Score < match.player2Score)
}

function opponentName(match, idSelf){
    return match.player1._id === idSelf ? match.player2.user.username : match.player1.user.username
}

function opponentScore(match, idSelf){
    return match.player1._id === idSelf ? match.player2Score : match.player1Score
}

function selfScore(match, idSelf){
    return match.player1._id === idSelf ? match.player1Score : match.player2Score
}

function rivals(matches, idSelf){
    matches = matches.filter(match => match.accepted)
    let opponentArray = matches.map(match => match.player1._id === idSelf ? match.player2 : match.player1)
    let uniqueOpponentIDs = [...new Set(opponentArray.map(opponent => opponent._id))];
    let rivalObjArray = [];
    uniqueOpponentIDs.forEach(opponentID => {
        let rivalScore = 0;
        let wins = 0;
        let losses = 0;
        matches
            .filter(match => match.player1._id === opponentID || match.player2._id === opponentID)
            .forEach(match => {
                if(win(match, idSelf)){
                    rivalScore += 1;
                    wins += 1;
                } else {
                    rivalScore -= 1;
                    losses += 1;
                }
            })
        rivalObjArray.push({
            rival: opponentArray.find(({_id}) => _id === opponentID),
            score: rivalScore,
            result: `${wins} - ${losses}`
        })
    })
    return rivalObjArray;
}

export {win, opponentName, opponentScore, selfScore, rivals}