import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { bindAvatar } from "./bindAvatar";
import AvatarModal from "./AvatarModal";
import AchievementBadge from "./AchievementBadge";

class AccountPage extends Component {
  state = {
    loading: true,
    avatarModal: false,
    selectedAvatar: null,
    errMsg: null,
    formErrMsg: null,
    avatarMsg: null,
    user: null,
    newUsername: null,
    newEmail: null,
    newPassword: null,
    verifyPassword: null,
    newAvatar: null,
    achievements: null,
  };

  toggleModal = (e) => {
    e.preventDefault();
    this.setState({ avatarModal: !this.state.avatarModal });
  };

  setAvatar = (newAvatar) => {
    this.setState({ newAvatar: newAvatar });
  };

  changedData = () => {
    if (
      (!this.state.newUsername) &&
      (!this.state.newPassword) &&
      (!this.state.newAvatar)
    ) {
      return false;
    }
    if (!(this.state.user.username === this.state.newUsername)) {
      return true;
    }
    if (!(this.state.user.email === this.state.newEmail)) {
        return true;
      }
    if (this.state.newPassword) {
      return true;
    }
    return false;
  };

  updateAvatar = (e) => {
    e.preventDefault();
    fetch("/user/avatar", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        avatar: this.state.newAvatar,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          this.setState({
            avatarMsg: {
              msg: data.error.message,
              type: "danger",
            },
          });
        }
        this.setState({
          user: data.user,
          avatarMsg: {
            msg: "Avatar updated",
            type: "success",
          },
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          avatarMsg: {
            msg: "Error while updating avatar",
            type: "danger",
          },
        });
      });
    this.toggleModal(e);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.changedData()) {
      return;
    }
    if (this.state.newPassword) {
      if (this.state.newPassword.length < 6) {
        return this.setState({
          formErrMsg: {
            msg: "Password has minimum length 6",
            type: "danger",
          },
        });
      }
    }
    if (!(this.state.newPassword === this.state.verifyPassword)) {
      return this.setState({
        formErrMsg: {
          msg: "Passwords do not match",
          type: "danger",
        },
      });
    }
    if (this.state.newUsername) {
      if (this.state.newUsername.length < 4) {
        return this.setState({
          formErrMsg: {
            msg: "Username has minimum length 4",
            type: "danger",
          },
        });
      }
    }
    fetch("/user/account", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newUsername: this.state.newUsername,
        newEmail: this.state.newEmail,
        newPassword: this.state.newPassword,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          if (data.error.status === 401) {
            return this.setState({
              loading: false,
              errMsg: {
                msg: "Login to update account",
                type: "warning",
              },
            });
          }
          return this.setState({
            loading: false,
            formErrMsg: {
              msg: data.error.message,
              type: "danger",
            },
          });
        }
        this.setState({
          loading: false,
          user: data.user,
          newUsername: data.user.username,
          newEmail: data.user.email,
          formErrMsg: {
            msg: "Account updated",
            type: "success",
          },
        });
        this.props.setuser(data.user.username);
        return;
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errMsg: {
            msg: err.msg,
            type: "danger",
          },
        });
      });
  };

  fetchAccountDetails = () => {
    fetch("/user/accountDetails", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          if (data.error.status === 401) {
            return this.setState({
              loading: false,
              errMsg: {
                msg: "Login to view account",
                type: "warning",
              },
            });
          }
          return this.setState({
            loading: false,
            errMsg: {
              msg: "Failed to load account data, please reload",
              type: "danger",
            },
          });
        }
        return this.setState({
          loading: false,
          user: data.user,
          newUsername: data.user.username,
          newEmail: data.user.email,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errMsg: {
            msg: err.msg,
            type: "danger",
          },
        });
      });
  };

  fetchAchievements = () => {
    fetch("/user/achievements", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return this.setState({
            loading: false,
            errMsg: {
              msg: "Failed to load achievemtens, please reload",
              type: "danger",
            },
          });
        }
        return this.setState({
          loading: false,
          achievements: data.achievements,
        });
      })
      .catch((err) => {
        this.setState({
          errMsg: {
            loading: false,
            msg: err.msg,
            type: "danger",
          },
        });
      });
  };

  componentDidMount() {
    this.fetchAccountDetails();
    this.fetchAchievements();
  }

  componentDidUpdate(prev) {
    if (this.props.user !== prev.user) {
      this.fetchAccountDetails();
      this.setState({ errMsg: null });
    }
  }

  render() {
    if (this.state.loading || (!this.state.user && !this.state.errMsg)) {
      return (
        <div className="">
          <div>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>
              <small>Fetching your account</small>
            </p>
          </div>
        </div>
      );
    }

    if (this.state.errMsg) {
      return (
        <div className={`alert alert-${this.state.errMsg.type}`}>
          {this.state.errMsg.msg}
        </div>
      );
    }

    return (
      <div className="container">
        <h3>Account of {this.state.user.username}</h3>
        <form onSubmit={this.handleSubmit}>
          <div className="row justify-content-center border-top pt-3">
            <div className="d-block col-12 col-sm-4 col-lg-3 px-3 justify-content-center">
              <img
                className="rounded-circle w-75 pb-2 d-block mx-auto"
                src={bindAvatar(this.state.user.avatar)}
                alt="UserAvatar"
              />
              <button
                type="button"
                className="btn btn-link text-decoration-none d-block mx-auto"
                onClick={this.toggleModal}
              >
                change
              </button>
              {this.state.avatarMsg ? (
                <div className={`alert alert-${this.state.avatarMsg.type}`}>
                  {this.state.avatarMsg.msg}
                </div>
              ) : (
                <span className="invisible d-none d-sm-block">
                  No error message
                </span>
              )}
              <div className="w-100 border-bottom d-sm-none" />
            </div>
            <div className="col-12 col-sm-8 col-lg-6">
              <div className="pb-2">
                <div className="form-group row align-items-center justify-content-between">
                  <label
                    className="col-form-label col-12 col-md-4"
                    htmlFor="password"
                  >
                    Set new password
                  </label>
                  <input
                    className="form-control col-12 col-md-7"
                    type="password"
                    id="password"
                    onChange={(e) =>
                      this.setState({ newPassword: e.target.value })
                    }
                    placeholder="adtKoning(in)#1"
                  ></input>
                </div>
                <div className="form-group row align-items-center justify-content-between">
                  <label
                    className="col-form-label col-12 col-md-4"
                    htmlFor="password2"
                  >
                    Confirm new password
                  </label>
                  <input
                    className="form-control col-12 col-md-7"
                    type="password"
                    id="password2"
                    onChange={(e) =>
                      this.setState({ verifyPassword: e.target.value })
                    }
                    placeholder="adtKoning(in)#1"
                  ></input>
                </div>
                <div className="form-group row align-items-center justify-content-between">
                  <label
                    className="col-form-label col-12 col-md-4"
                    htmlFor="username"
                  >
                    Update username
                  </label>
                  <input
                    className="form-control col-12 col-md-7"
                    type="text"
                    id="username"
                    onChange={(e) =>
                      this.setState({ newUsername: e.target.value })
                    }
                    value={this.state.newUsername}
                  ></input>
                </div>
                <div className="form-group row align-items-center justify-content-between">
                  <label
                    className="col-form-label col-12 col-md-4"
                    htmlFor="email"
                  >
                    Change email
                  </label>
                  <input
                    className="form-control col-12 col-md-7"
                    type="email"
                    id="email"
                    onChange={(e) =>
                      this.setState({ newEmail: e.target.value })
                    }
                    value={this.state.newEmail}
                  ></input>
                </div>
                {this.state.formErrMsg ? (
                  <div
                    className={`alert alert-${this.state.formErrMsg.type} d-sm-none`}
                  >
                    {this.state.formErrMsg.msg}
                  </div>
                ) : (
                  <span className="invisible">No error message</span>
                )}
                <div className="form-group row align-items-center justify-content-center justify-content-sm-between">
                  {this.state.formErrMsg ? (
                    <span
                      className={`alert alert-${this.state.formErrMsg.type} col-8 mb-0 d-none d-sm-block`}
                    >
                      {this.state.formErrMsg.msg}
                    </span>
                  ) : (
                    <span className="invisible d-none d-sm-block">
                      No error message
                    </span>
                  )}
                  <button
                    className={`btn btn-primary ${
                      this.changedData() ? "" : "disabled"
                    }`}
                    type="submit"
                  >
                    save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="border-top">
          <h5>Achievements</h5>
          <div className="row pb-5">
            {this.state.achievements ? (
              this.state.achievements.map((item, i) => {
                return <AchievementBadge key={i} data={item} id={i} />;
              })
            ) : (
              <span className="invisible d-none d-sm-block">
                No achievements
              </span>
            )}
          </div>
        </div>
        <Modal
          isOpen={this.state.avatarModal}
          toggle={this.toggleModal}
          className="bg-light mt-3"
        >
          <ModalHeader toggle={this.toggleModal}>
            Select your avatar
          </ModalHeader>
          <ModalBody>
            <AvatarModal
              currentAvatar={this.state.user ? this.state.user.avatar : null}
              setAvatar={this.setAvatar}
              unlocks={this.state.achievements}
            />
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <button className="btn btn-primary" onClick={this.updateAvatar}>
              save
            </button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default AccountPage;
