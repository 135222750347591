import React, { Component } from "react";
import { Link } from "react-router-dom";

class AdtventInfo extends Component {
  render() {
    return (
      <div className="row pb-5 justify-content-center">
        <div className="col-12 col-sm-11 col-md-10 col-lg-8">
          <h3 className="text-center display-4">Spies Ranking</h3>
          <big>
            <p>The official spies ranking of the Spa-Goud society.</p>
          </big>
          <small className="px-3">
            <h3 className="text-center">How to join</h3>
            <p>
              In order to get your best spies time on this ranking, you can
              either do a spies in person with a Spa-Goud adtmin present. Or
              join{" "}
              <a
                className=""
                href="https://chat.whatsapp.com/DrqWtmAT8gDGh3DXGWbPjy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                this
              </a>{" "}
              whatsapp group and post video evidence of your spies. This will
              then be reviewed by the Spa-Goud officials and added to the site.
            </p>
            <h3 className="text-center">How to change my avatar</h3>
            <p>
              In order the change your avatar, you will have to create a
              Spa-Goud account. Any existing results do however need to be
              linked to the new account, so inform any adtmins if you want this
              to happen.
            </p>
          </small>
          <Link to="/spiesranking">Back to ranking</Link>
        </div>
      </div>
    );
  }
}

export default AdtventInfo;
