import React, { Component } from 'react';
import FameCard from './FameCard';

class FameCarousel extends Component {
    render() {
        let fluidClassName = "row mx-auto d-flex flex-nowrap"
        if (this.props.items.length < 3) {
            fluidClassName += " justify-content-sm-center"
        } else if (this.props.items.length < 4) {
            fluidClassName += " justify-content-lg-center"
        }
        
        return (
            <div id="FameCarousel" className="carousel slide carousel-multi-item xscroll" data-ride="carousel">
                <div className={fluidClassName} role="listbox">
                    {this.props.items.map((item, i) => {
                        return <FameCard item={item} i={i} key={i} setAnimating={this.setAnimating} />
                    })}
                </div>
            </div>
        )};
};

export default FameCarousel;