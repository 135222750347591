import React, { Component } from 'react';

class ScoreForm extends Component {
    state = {
        cardScore: 0,
        userScore: 0,
        showScores: true,
        msg: null,
    }

    plusCard = () => {
        if (this.state.cardScore < 9) {
            this.setState({cardScore: this.state.cardScore + 1})
        }
        return
    }

    plusUser = () => {
        if (this.state.userScore < 9) {
            this.setState({userScore: this.state.userScore + 1})
        }
        return
    }

    minCard = () => {
        if (this.state.cardScore > 0) {
            this.setState({cardScore: this.state.cardScore - 1})
        }
        return
    }

    minUser = () => {
        if (this.state.userScore > 0) {
            this.setState({userScore: this.state.userScore - 1})
        }
        return
    }

    submit = (e) => {
        e.preventDefault();
        try {
            fetch('/ladter/match', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    player2LadterID: this.props.cardID,
                    player1Score: this.state.userScore,
                    player2Score: this.state.cardScore,
                })
            })
                .then((res) => {
                    if (res.status === 200) {
                        res.json()
                            .then(data => {
                                this.setState({msg: {
                                    text: data.msg,
                                    type: "success"
                                }})
                                this.setState({showScores: false})
                                window.setTimeout(() => {
                                    this.props.dismissFunc(e);
                                    this.props.update()
                                }, 1000)
                            })
                    } else {
                        res.json()
                            .then(data => {
                                this.setState({msg: {
                                    text: data.errorMsg,
                                    type: "danger"
                                }})
                            })
                    }
                })
        } catch (err) {
            this.setState({msg: {
                text: err.msg,
                type: "danger"
            }})
        }
    }

    dismissMsg = (e) => {
        e.preventDefault();
        this.setState({msg: null})
    }
    

    render () {
        return (
            <div className="border-dashed-top px-1 pb-1">
                <div className="row no-gutters align-items-center mb-2">
                    <div className="col-5">
                        <p className="mb-1 mx-1 text-muted text-center text-truncate">{this.props.cardName}</p>
                        <div className="d-flex justify-content-around align-items-center">
                            <h6>{this.state.cardScore}</h6>
                            <div>
                                <span className="d-block fa fa-plus-circle text-success" onClick={this.plusCard}/>
                                <span className="d-block fa fa-minus-circle text-danger" onClick={this.minCard}/>
                            </div>
                        </div>
                    </div>
                    <p className="col-2">-</p>
                    <div className="col-5">
                        <p className="mb-1 mx-1 text-muted text-center text-truncate">Me</p>
                        <div className="d-flex justify-content-around align-items-center">
                            <h6>{this.state.userScore}</h6>
                            <div>
                                <span className="d-block fa fa-plus-circle text-success" onClick={this.plusUser}/>
                                <span className="d-block fa fa-minus-circle text-danger" onClick={this.minUser}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <span className="btn btn-sm btn-secondary mr-2" onClick={this.props.dismissFunc}>cancel</span>
                    <span className="btn btn-sm btn-primary" onClick={this.submit}>submit</span>
                </div>
                { this.state.msg ? 
                <div className={`alert alert-${this.state.msg.type} mt-1 d-flex justify-content-between align-items-center`}>
                    <span>{this.state.msg.text}</span>
                    <span className="fa fa-times" onClick={this.dismissMsg}/>
                </div> : <span className="hidden"/>}
            </div>
        )
    }

};

export default ScoreForm;