const eventsArray = [
  {
    title: "Hall of Fame",
    date: "Timeless",
    order: 1,
    src: require("./images/HallOfFameEvent.png"),
    alt: "Placeholder",
    routerLink: "/HallOfFame",
  },

  {
    title: "Ladter Competition",
    date: "Always active",
    order: 3,
    src: require("./images/LADTer.svg"),
    alt: "Placeholder",
    routerLink: "/Ladter",
  },

  {
    title: "Adtje Kratje",
    date: "Whenever",
    order: 3,
    src: require("./images/adtjekratje/Adtjekratje_Evenement.png"),
    alt: "Adtje Kratje",
    routerLink: "/adtjekratje",
  },

  {
    title: "Spies Ranking",
    date: "Permanent",
    order: 3,
    src: require("./images/SpiesRanking.svg"),
    alt: "Spies Ranking",
    routerLink: "/spiesranking",
  },

  // {
  //   title: "Tour de Biere",
  //   //  subtitle: "Multiday quarantine drinking game",
  //   date: "No stage planned",
  //   order: 4,
  //   src: require("./images/tourDeBiere.svg"),
  //   alt: "Tour de biere",
  //   routerLink: "/tourdebiere",
  // },

  {
    title: "Adtvent Calender",
    date: "December",
    order: 2,
    src: require("./images/adtventCalendarEvent.png"),
    alt: "Placeholder",
    routerLink: "/Adtvent",
  },

  // {
  //   title: "Corona Roadmap",
  //   date: "November 2020",
  //   order: 4,
  //   src: require("./images/roadmapEvent.png"),
  //   alt: "Placeholder",
  //   routerLink: "/roadmap",
  // },

  // {
  //   title: "Boek der Bepalingen",
  //   date: "In development",
  //   order: 5,
  //   src: require("./images/boekDerBepalingen.png"),
  //   alt: "Placeholder",
  //   routerLink: "/",
  // },
];

// some change to force rebuild

export const events = eventsArray;
