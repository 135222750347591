import React, { Component } from "react";
import { bindAvatar, getStandartAvatar } from "../User/bindAvatar";

import Task from "./Task";
import tasks from "./tasks";
import "./roadmap.css";

const dtf = new Intl.DateTimeFormat("en", { month: "long", day: "numeric" });

class Phase extends Component {
  render() {
    return (
      <div className="phase">
        <div
          className="phase-wrapper border"
          style={{
            backgroundColor:
              this.props.data.deadline < new Date() ? "#e1ecdb" : "#f3f3f3",
          }}
        >
          <div className="phase-side">
            <h6 className="phase-number text-vertical ">
              {"Phase " + (this.props.number + 1)}
            </h6>
            <h6 className="phase-date text-vertical">
              {dtf.format(new Date(this.props.data.deadline))}
            </h6>
          </div>
          <div className="mx-1 w-100 overflow-hidden">
            {this.props.data.tasks.map((task) => (
              <Task
                key={task.id}
                data={task}
                admin={this.props.admin}
                update={this.props.update}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const combineData = (dbData) => {
  let addedTasks = tasks.tasks;
  addedTasks.forEach((task) => {
    dbData.tasks.forEach((dbTask) => {
      if (task.id === dbTask.id) {
        task._id = dbTask._id;
        task.completedBy = dbTask.completedBy.map((player) => {
          if (player.userID) {
            player.avatar = bindAvatar(player.userID.avatar);
          } else {
            player.avatar = getStandartAvatar(player.name);
          }
          return player;
        });
      }
    });
  });
  let phases = addedTasks
    .sort((a, b) => (a.deadline < b.deadline ? -1 : 1))
    .reduce((acc, cur) => {
      if (acc.length === 0) {
        return [{ deadline: cur.deadline, tasks: [cur] }];
      } else if (
        cur.deadline.valueOf() === acc[acc.length - 1].deadline.valueOf()
      ) {
        acc[acc.length - 1].tasks.push(cur);
        return acc;
      } else {
        acc.push({ deadline: cur.deadline, tasks: [cur] });
        return acc;
      }
    }, []);
  return { isAdmin: dbData.isAdmin, phases: phases };
};

class Timeline extends Component {
  state = {
    isAdmin: false,
    phases: [],
  };

  fetchTasks = () => {
    fetch("/roadmap/data", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ ...combineData(data) });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.fetchTasks();
  }

  render() {
    return (
      <div className="">
        <h2 className="pink">What can you do when</h2>
        <div className="timeline">
          {this.state.phases
            .sort((a, b) => (a.deadline < b.deadline ? -1 : 1))
            .map((phase, i) => (
              <Phase
                key={i}
                data={phase}
                number={i}
                admin={this.state.isAdmin}
                update={this.fetchTasks}
              />
            ))}
          <div className="phase">
            <div
              className="phase-wrapper border"
              style={{
                backgroundColor:
                  new Date("2020-12-01") < new Date() ? "#e1ecdb" : "#f3f3f3",
              }}
            >
              <div className="phase-side">
                <h6 className="phase-number text-vertical">
                  {"Phase " + (this.state.phases.length + 1)}
                </h6>
                <h6 className="phase-date text-vertical">
                  {dtf.format(new Date("2020-12-01"))}
                </h6>
              </div>
              <div className="phase-content d-flex pl-3">
                <h3 className="display-4 align-self-center">
                  Adtvent Calendar
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Timeline;
