import React, { Component } from "react";
import { Tooltip } from "reactstrap";
import SearchUser from "../User/SearchUser";

class UserAvatar extends Component {
  state = {
    tooltipOpen: false,
  };

  toggle = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };

  render() {
    return (
      <div className="pl-1 py-1 ">
        <img
          className="rounded-circle"
          alt="player icon"
          style={{ maxWidth: "30px", height: "auto" }}
          src={this.props.data.avatar}
          id={`player-${this.props.id}`}
        />
        <Tooltip
          placement="bottom"
          isOpen={this.state.tooltipOpen}
          target={`player-${this.props.id}`}
          toggle={this.toggle}
        >
          {this.props.data.name}
        </Tooltip>
      </div>
    );
  }
}

class Task extends Component {
  state = {
    expanded: false,
    addPlayer: { name: "" },
  };

  toggleExpand = (e) => {
    e.preventDefault();
    this.setState({ expanded: !this.state.expanded });
  };

  updateState = (obj) => {
    this.setState({ addPlayer: obj });
  };

  addPlayer = (e) => {
    e.preventDefault();
    fetch("/roadmap/completedBy", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        player: this.state.addPlayer,
        task: this.props.data,
      }),
    })
      .then((res) => {
        if (res.status === 204) {
          this.setState({ addPlayer: { name: "" } });
          this.props.update();
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  removePlayer = (playerID) => (e) => {
    e.preventDefault();
    fetch("/roadmap/completedBy", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        player: { _id: playerID },
        task: this.props.data,
      }),
    })
      .then((res) => {
        if (res.status === 204) {
          this.props.update();
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (this.state.expanded) {
      return (
        <div className="border rounded w-100 my-1 py-1 px-1 bg-light">
          <div className="container-fluid" onClick={this.toggleExpand}>
            <div className="row justify-content-center align-items-center">
              <div className="col-3 col-sm-2 col-lg-1 px-0">
                <object
                  className="w-100"
                  type="image/svg+xml"
                  data={this.props.data.icon}
                >
                  Task Icon
                </object>
              </div>
              <div className="col-9 col-sm-10 col-lg-11 pl-1">
                <h2 className="text-left">{this.props.data.title}</h2>
                <div className="d-none d-md-block">
                  {this.props.data.text.map((text, i) => (
                    <p className="text-left my-1" key={i}>
                      {text}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="d-md-none">
            {this.props.data.text.map((text, i) => (
              <p className="text-left px-2 my-1" key={i}>
                {text}
              </p>
            ))}
          </div>
          <div className="row px-3 border-top">
            <div className="col-12">
              <small>
                <small className="text-muted">
                  {"Completed by " + this.props.data.completedBy.length}
                </small>
              </small>
            </div>
            {this.props.admin ? (
              <div className="col-12">
                <div className="input-group justify-content-center">
                  <label className="sr-only" htmlFor="inlineAddPlayerForm">
                    Update Player
                  </label>
                  <SearchUser
                    className="rounded-left"
                    id="updatePlayer"
                    placeholder="New name"
                    setState={this.updateState}
                    submit={this.addPlayer}
                    state={this.state.addPlayer}
                  />
                  <div className="input-group-append">
                    <button
                      className="input-group-text btn btn-outline-success"
                      onClick={this.addPlayer}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <span />
            )}
            {this.props.data.completedBy.map((user, i) => {
              return (
                <div
                  className="col border rounded mx-1 my-1 px-1 py-1 text-truncate"
                  style={{ maxWidth: "100px", minWidth: "75px" }}
                  key={i}
                >
                  {this.props.admin ? (
                    <span
                      className="position-absolute btn px-0 py-0 text left"
                      style={{ right: 0, top: 0 }}
                      onClick={this.removePlayer(user._id)}
                    >
                      &times;
                    </span>
                  ) : (
                    <span />
                  )}
                  <UserAvatar id={user._id} data={user} />
                  <span className="text-muted text-truncate">{user.name}</span>
                </div>
              );
            })}
          </div>
          <span
            className=""
            style={{ marginTop: "-1rem", marginBottom: "-1rem" }}
            onClick={this.toggleExpand}
          >
            <span className="fa fa-chevron-up text-muted mb-0" />
          </span>
        </div>
      );
    }
    return (
      <div className="border rounded w-100 my-1 py-1 px-1 bg-light">
        <div className="container-fluid ">
          <div className="row justify-content-center align-items-center">
            <div className="col-3 col-sm-2 col-lg-1 px-0">
              <object
                className="w-100"
                type="image/svg+xml"
                data={this.props.data.icon}
              >
                Task Icon
              </object>
            </div>
            <div className="col-9 col-sm-10 col-lg-11 pl-1">
              <h2 className="text-left">{this.props.data.title}</h2>
              <div className="d-none d-md-block">
                {this.props.data.text.map((text, i) => (
                  <p className="text-left my-1" key={i}>
                    {text}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <span
          className=""
          style={{ marginTop: "-1rem", marginBottom: "-1rem" }}
          onClick={this.toggleExpand}
        >
          <small>
            <small className="text-muted mr-1">Details</small>
          </small>
          <span className="fa fa-chevron-down text-muted mb-0" />
        </span>
        <div className="d-flex">
          {this.props.data.completedBy.map((user, i) => (
            <UserAvatar key={i} id={user._id} data={user} />
          ))}
        </div>
      </div>
    );
  }
}

export default Task;
