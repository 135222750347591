import React, { Component } from "react";
import { Link } from "react-router-dom";

class AdtventInfo extends Component {
  render() {
    return (
      <div className="row pb-5 justify-content-center">
        <div className="col-12 col-sm-11 col-md-10 col-lg-8">
          <h3 className="text-center display-4">Adtvent Calendar</h3>
          <big>
            <p>
              2021 marks the 4th installement of the Spa-Goud Adtvent Calendar.
              In this event participants are given daily drinking related tasks,
              which if completed can earn them points.
            </p>
          </big>
          <small className="px-3">
            <h3 className="text-center">Joining</h3>
            <p>
              In order to participate, you only need to join{" "}
              <a
                className=""
                href="https://chat.whatsapp.com/DrqWtmAT8gDGh3DXGWbPjy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                this
              </a>{" "}
              whatsapp group.
            </p>
            <h3 className="text-center">Open a task</h3>
            <p>
              Visit the calendar page on this site and open the correct day to
              view todays task. Every task can be expanded if clicked a second
              time. Here, one can select the info symbol to get a description of
              the assignment and possible joker rules.
            </p>
            <h3 className="text-center">Post a task</h3>
            <p>
              Although we work with "gentlemens rules", we would like to see
              some evidence of any completed tasks. Therefore, you are required
              to film yourself completing the assignment and post this in the
              whatsapp group.
            </p>
            <p>
              Usually, any tasks that mentions drinking a beer involve downing
              this beer of at least 0.25 liters quickly, unless otherwise
              specified. Likewise, every task can only completed once for
              points, unless otherwise specified.
            </p>
            <h3 className="text-center">Points</h3>
            <p>
              Once your evidence in posted, the Spa-Goud adtmins will evaluate
              if the task is completed correctly and will award the specified
              number of points.
            </p>
            <p>
              Normal tasks will have the amount of points in the description,
              but there are a few tasks that are based on a ranking system. Here
              the amount of points that are rewarded are based on the following
              formula.
            </p>
            <object
              type="image/svg+xml"
              data={require("../../images/adtvent/equation.svg")}
              className="pb-2"
            >
              equation
            </object>
            <p>
              <i>n</i> is the number of submissions for that day, <i>x</i> the
              rank and <i>p</i> the number of resulting points. The rank of
              every submission is objevtively determined by Sven Keukenboer and
              can not be argued...
            </p>
            <h3 className="text-center">Streak freezes</h3>
            <p>
              This year’s adtvent introduces streak freezes. Everyone is allowed
              3 days where you can request a non-drinking challenge if you are
              really unable to handle alcohol that day. You will have to
              announce your streak freeze in the app, upon which an adtmin will
              contact you for your alternative challenge. These are in the form
              of beer themed riddles which you must solve before the end of the
              day. If you are successful, the adtmin will grant you a certain
              number of points and you can keep your streak alive.
            </p>
            <h3 className="text-center">Bonuses</h3>
            <p>
              Bonuses are again present this year. These do not have to be
              completed on a specific day, but can earn extra points once
              sometime during the calendar. Maybe, this could provide the extra
              push to surpass someone in the rankings.
            </p>
            <h3 className="text-center">Rewards</h3>
            <p>
              Besides the satisfaction attained by beating others, you can also
              earn your place in the hall of fame by winning the adtvent
              calendar.
            </p>
            <h6 className="text-center">Small print</h6>
            <p className="text-muted">
              If at any point the task rules are unclear, Sven Keukenboers
              interpretation stands.
            </p>
          </small>
          <Link to="/adtvent">Back to calendar</Link>
        </div>
      </div>
    );
  }
}

export default AdtventInfo;
