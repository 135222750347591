import React, { Component } from 'react';
import Login from './Login';
import Register from './Register';
import { ModalBody } from 'reactstrap';

class LogRes extends Component {
    state= {
        isRegister: false
    }

    toggle = () => {
        this.setState({
            isRegister: !this.state.isRegister
        })
    }

    changeState() {
        if (this.state.isRegister) {
            this.Toggler.classList.remove('loginTogglerRight')
            this.Toggler.classList.add('loginTogglerLeft')
        } else {
            this.Toggler.classList.remove('loginTogglerLeft')
            this.Toggler.classList.add('loginTogglerRight')
        }

        this.toggle()
    }

    render () {
        return (
            <ModalBody className="px-0">
                <Toggler containerRef={ref => this.Toggler = ref} onClick={this.changeState.bind(this)}/>
                <div className="justify-content-center rounded">
                        {this.state.isRegister && <Register className="LogRes" exitModal={this.props.exitModal} setuser={this.props.setuser} containerRef={(ref) => this.current = ref}/>}
                        {!this.state.isRegister && <Login className="LogRes" exitModal={this.props.exitModal} setuser={this.props.setuser} toggler={this.changeState.bind(this)} containerRef={(ref) => this.current = ref}/>}
                </div>
            </ModalBody>
        );
    }
};

const Toggler = (props) => {
    return (
        <div className="loginToggler loginTogglerLeft" ref={props.containerRef} onClick={props.onClick}>
            <div className="d-flex justify-content-between text-light">
                <div style={{transform: 'rotate(-90deg)', margin: '-2%'}}>
                    <h3>Login</h3>
                </div>
                <div style={{transform: 'rotate(90deg)', margin: '-7%'}}>
                    <h3>Register</h3>
                </div>
            </div>
        </div>
    )
}

 export default LogRes;