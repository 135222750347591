import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { events } from './events';
import AppNavBar from './components/Nav/AppNavBar';
import Home from './components/Home/Home';
import AccountPage from './components/User/AccountPage';
import PasswordReset from './components/User/PasswordReset';
import MemberPage from './components/User/MemberPage';
import HallOfFame from './components/HallOfFame/HallOfFame';
import Ladter from './components/Ladter/Ladter';
import LadterInfo from './components/Ladter/LadterInfo';
import LadterAccount from './components/Ladter/LadterAccount';
import TourDeBiere from './components/TourDeBiere/TourDeBiere';
import TourDeBiereInfo from './components/TourDeBiere/TourDeBiereInfo';
import ArchivedTour from './components/TourDeBiere/ArchivedTour';
import AdtjeKratjeLobby from './components/AdtjeKratje/Lobby';
import AdtjeKratjeSession from './components/AdtjeKratje/SessionPage';
import Adtvent from './components/Adtvent/Adtvent';
import AdtventInfo from './components/Adtvent/AdtventInfo';
import AdtventPlayer from './components/Adtvent/PlayerPage';
import AdtventAdtmin from './components/Adtvent/Adtmin';
import SpiesRanking from './components/SpiesRanking/SpiesRanking';
import SpiesPlayer from './components/SpiesRanking/PlayerPage';
import SpiesPlayerAdtmin from './components/SpiesRanking/AdtminPlayerPage';
import SpiesAdtmin from './components/SpiesRanking/Adtmin';
import SpiesInfo from './components/SpiesRanking/SpiesInfo';
import Roadmap from './components/Roadmap/Roadmap';
import About from './components/About/About';
import Alerts from './components/Alerts/Alerts';

class App extends Component {
  state = {
    user: null,
    alerts: [
      // {msg: "test", type: "danger"},
      // {
      //   autoClose: 5000,
      //   msg: "autoclosing alert", 
      //   type: "warning"
      // }
    ],
  }

  addAlert = (newAlert) => {
    this.setState(state => {
        const alerts = [...state.alerts, newAlert];
        return { alerts };
    });
  }

  clearAlerts = () => {
    this.setState({ alerts: [] });
  };

  setUser = (name) => {
    this.setState({user: name})
  }

  render() {
    const history = createBrowserHistory();
    return (
      <BrowserRouter>
        <div className="App">
          <header className="App-header">
            <AppNavBar events={events} addCentralAlert={this.addAlert} user={this.state.user} setuser={this.setUser} history={history}/>
          </header>
          <div className="container-md bg-light justify-content-around min-vh-100" style={{paddingTop:'65px'}}>
            <Alerts alerts={this.state.alerts} />
            <Switch>
              <Route path="/" exact render={() => <Home events={events} addCentralAlert={this.addAlert} />} />
              <Route path="/user/account" render={() => <AccountPage user={this.state.user} setuser={this.setUser}/>} />
              <Route exact path="/member/:user" component={MemberPage} />
              <Route path="/HallOfFame" component={HallOfFame} />
              <Route path="/Ladter" exact render={() => <Ladter addCentralAlert={this.addAlert} user={this.state.user} />} />
              <Route path="/Ladter/info" component={LadterInfo} />
              <Route exact path="/spiesranking" render={() => <SpiesRanking user={this.state.user} />} />
              <Route path="/spiesranking/info" component={SpiesInfo} />
              <Route exact path="/spiesranking/player/:id" component={SpiesPlayer} />
              <Route exact path="/spiesranking/adtmin/player/:id" component={SpiesPlayerAdtmin} />
              <Route path="/spiesranking/adtmin" component={SpiesAdtmin} />
              <Route path="/tourdebiere" exact component={TourDeBiere} />
              <Route path="/tourdebiere/info" component={TourDeBiereInfo} />
              <Route exact path="/tourdebiere/archive/:tour" render={(props) => <ArchivedTour tour={props.match.params.tour}/> } />
              <Route exact path='/adtjekratje/session/:id' render={(props) => <AdtjeKratjeSession sessionID={props.match.params.id} user={this.state.user}/>} />
              <Route path="/adtjekratje" render={(props) => <AdtjeKratjeLobby user={this.state.user} history={props.history}/>} />
              <Route exact path="/Ladter/user/:id" component={LadterAccount} />
              <Route exact path='/user/passreset/:token' render={(props) => <PasswordReset token={props.match.params.token} history={props.history} setuser={this.setUser}/>} />
              <Route exact path="/adtvent/info" component={AdtventInfo} />
              <Route exact path="/adtvent/player/:id" component={AdtventPlayer} />
              <Route exact path="/adtvent" render={() => <Adtvent user={this.state.user} />} />
              <Route path="/adtvent/adtmin" component={AdtventAdtmin} />
              <Route path='/roadmap' component={Roadmap} />
              <Route path="/about" component={About} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  };
}

export default App;
