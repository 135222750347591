import React, { Component } from "react";

class PasswordReset extends Component {
  state = {
    newPassword: null,
    verifyPassword: null,
    formErrMsg: null,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.newPassword) {
      if (this.state.newPassword.length < 6) {
        this.setState({
          formErrMsg: {
            msg: "Password has minimum length 6",
            type: "danger",
          },
        });
        return;
      }
    }
    if (!(this.state.newPassword === this.state.verifyPassword)) {
      this.setState({
        formErrMsg: {
          msg: "Passwords do not match",
          type: "danger",
        },
      });
      return;
    }
    fetch("/user/passreset", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: this.props.token,
        newPassword: this.state.newPassword,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return this.setState({
            formErrMsg: {
              msg: data.error.message,
              type: "danger",
            },
          });
        }
        this.setState({
          formErrMsg: {
            msg: "Account updated",
            type: "success",
          },
        });
        this.props.setuser(data.user);
        const myInterval = setInterval(() => {
          clearInterval(myInterval);
          this.props.history.push("/");
        }, 1000);
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          formErrMsg: {
            msg: err.message,
            type: "danger",
          },
        });
      });
  };

  render() {
    return (
      <div className="container">
        <h2 className="">Password reset</h2>
        <form
          className="row justify-content-center"
          onSubmit={this.handleSubmit}
        >
          <div className="col-12 col-sm-8 col-lg-6 border-top pt-3">
            <div className="form-group row align-items-center justify-content-between px-3">
              <label
                className="col-form-label col-12 col-md-4"
                htmlFor="password"
              >
                Set new password
              </label>
              <input
                className="form-control col-12 col-md-7"
                type="password"
                id="password"
                onChange={(e) => this.setState({ newPassword: e.target.value })}
                placeholder="adtKoning(in)#1"
              ></input>
            </div>
            <div className="form-group row align-items-center justify-content-between px-3">
              <label
                className="col-form-label col-12 col-md-4"
                htmlFor="password2"
              >
                Confirm new password
              </label>
              <input
                className="form-control col-12 col-md-7"
                type="password"
                id="password2"
                onChange={(e) =>
                  this.setState({ verifyPassword: e.target.value })
                }
                placeholder="adtKoning(in)#1"
              ></input>
            </div>
            <div className="form-group row align-items-center justify-content-center justify-content-sm-between px-3">
              {this.state.formErrMsg ? (
                <span
                  className={`alert alert-${this.state.formErrMsg.type} col-8 mb-0 d-none d-sm-block`}
                >
                  {this.state.formErrMsg.msg}
                </span>
              ) : (
                <span className="invisible d-none d-sm-block">
                  No error message
                </span>
              )}
              <button
                className={`btn btn-primary ${
                  this.state.newPassword ? "" : "disabled"
                }`}
                type="submit"
              >
                save
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default PasswordReset;
